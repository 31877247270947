import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Divider, Tag, Row, Col, Typography } from 'antd'
import { ConvertcustomerGender } from '../../../utils/helpers';
import { isMobile } from 'react-device-detect';
import CustomerServicesList from './CustomerServicesList';

const { Paragraph } = Typography;

class CustomerDetail extends Component {
    constructor() {
        super();
        this.state = {
            isOpenEditForm: false,
            currentServiceProvided: null,
            isOpenServiceProvidedForm: false,
            isOpenMediaEditForm: false
        };
        this.scheduleFormRef = React.createRef();
        this.servicesInterestedFormRef = React.createRef();
        this.servicesSelectedFormRef = React.createRef();
        this.profilesFormRef = React.createRef();
        this.medicalFormRef = React.createRef();
        this.voucherFormRef = React.createRef();
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenEditForm(isOpenEditForm) {
        this.setState({ isOpenEditForm: isOpenEditForm });
    }

    handleOpenScheduleForm = () => {
        if (this.scheduleFormRef.current) {
            this.scheduleFormRef.current.openForm();
        }
    };

    handleOpenServicesInterestedForm = () => {
        if (this.servicesInterestedFormRef.current) {
            this.servicesInterestedFormRef.current.toggleOpenForm(true);
        }
    };

    handleOpenServicesSelectedForm = () => {
        if (this.servicesSelectedFormRef.current) {
            this.servicesSelectedFormRef.current.toggleOpenForm(true);
        }
    };

    handleOpenProfilesForm = () => {
        if (this.profilesFormRef.current) {
            this.profilesFormRef.current.openForm();
        }
    };

    handleOpenMedicalForm = () => {
        if (this.medicalFormRef.current) {
            this.medicalFormRef.current.openForm();
        }
    };

    handleOpenVoucherForm = () => {
        if (this.voucherFormRef.current) {
            this.voucherFormRef.current.openForm();
        }
    };

    render() {
        var { isOpenEditForm, isOpenMediaEditForm } = this.state;
        var { visible, currentItem, loadingDetail } = this.props;

        return (
            <>
                <Drawer
                    visible={visible}
                    title="Hồ sơ khách hàng"
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '65%'}
                    bodyStyle={{ paddingTop: 0 }}
                >
                    {visible ? (
                        <div className='wrap-customer-detail'>
                            <Divider orientation="left" orientationMargin="0" style={{ marginTop: 0 }}>
                                <Tag color='#0b2b33'>1</Tag> Thông tin khách hàng
                            </Divider>
                            <Row gutter={16}>
                                <Col xs={24} md={12}>
                                    <Row gutter={16}>
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Tên khách hàng">
                                                    {currentItem ? currentItem.full_name : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Mã khách hàng">
                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                        {
                                                            text: currentItem ? currentItem.code : null,
                                                            tooltips: false
                                                        }
                                                    }>
                                                        <Tag color='#1890ff'>{currentItem ? currentItem.code : null}</Tag>
                                                    </Paragraph>
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={24} md={12}>
                                    <Row gutter={16}>
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Mã số sổ khám">
                                                    {
                                                        currentItem?.medical_book_number ? (
                                                            <Paragraph style={{ margin: 0 }} copyable={
                                                                {
                                                                    text: currentItem ? currentItem.medical_book_number : null,
                                                                    tooltips: false
                                                                }
                                                            }>
                                                                {currentItem ? currentItem.medical_book_number : null}
                                                            </Paragraph>
                                                        ) : null
                                                    }

                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Descriptions size="small">
                                                <Descriptions.Item label="Giới tính">
                                                    {currentItem ? ConvertcustomerGender(currentItem.gender) : null}
                                                </Descriptions.Item>
                                            </Descriptions>
                                            <Divider className="no-margin" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <CustomerServicesList ref={this.servicesSelectedFormRef} currentItem={currentItem} reloadData={() => this.props.reloadData()} />
                        </div>
                    ) : null}
                </Drawer>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser,
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerDetail' })(CustomerDetail));
