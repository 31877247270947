import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import ModuleCard from './ModuleCard';

class Home extends Component {

    onClickModuleCard(redirectPath = null) {
        if (redirectPath) {
            this.props.history.push(redirectPath);
        }
    }

    render() {

        var parentModules = [
            {
                className: 'card-green',
                title: 'QUẢN LÝ KHÁCH HÀNG',
                icon: <UserOutlined className='home-widget-icon' />,
                onClick: () => this.onClickModuleCard('/customer-data?tabs=lookup')
            }
        ];

        return (
            <div>
                <Row align="middle" justify="center">
                    <Col md={24} xs={24}>
                        <Row gutter={16}>
                            {
                                parentModules.map((item, index) => (
                                    <Col lg={6} md={12} xs={24} key={index} className="w-100 mb-2">
                                        <ModuleCard
                                            className={`${item.className} align-items-center`}
                                            title={item.title}
                                            icon={item.icon}
                                            onClick={item.onClick}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

export default connect(mapStateToProps)(Home);