import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Popconfirm, Card, Row, Col, Form } from "antd";
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import BaseSelect from '../../components/Elements/BaseSelect';
import { getListCustomerActive } from '../../redux/actions/CustomerActions';
import { getRelationshipTypeActive } from '../../redux/actions/RelationshipTypeActions';

const FamilyRelationships = (props) => {
    const [items, setItems] = useState([{
        key: 0,
        related_id: "",
        relationship_type: "",
        relationship_type_inverse: "",
    }]);
    const [count, setCount] = useState(0);
    const [customerQuery, setCustomerQuery] = useState('');

    const relatedIds = items.map(item => item.related_id);

    useEffect(() => {
        props.getRelationshipTypeActive();
        props.getListCustomerActive({ is_selected: relatedIds });
    }, [items]);

    const { relationshipTypeActiveList, getItems, customerActiveList } = props;

    useEffect(() => {
        getItems(items);
    }, [items]);

    const onAdd = () => {
        const newItem = {
            key: count + 1,
            related_id: "",
            relationship_type: "",
            relationship_type_inverse: "",
        };
        setItems([...items, newItem]);
        setCount(count + 1);
    };

    const onDelete = (key) => {
        const updatedItems = items.filter(item => item.key !== key);
        setItems(updatedItems);
    };

    const onChangeItem = (name, value, itemKey) => {
        const updatedItems = items.map(item =>
            item.key === itemKey ? { ...item, [name]: value } : item
        );
        setItems(updatedItems);
    };

    const onSearchCustomer = (val) => {
        setCustomerQuery(val);
        props.getListCustomerActive({ keyword: val });
    };

    const handleCustomerScroll = (e) => {
        props.getListCustomerActive({ keyword: customerQuery, page: props.pagination_active.currentPage + 1 });
    }

    return (
        <div>
            <div className='chingo-normal-title'>Quan hệ gia đình</div>
            <div className="mb-3 mt-3">
                {items.map(item => (
                    <Card key={item.key} size='small' className="mb-3" bordered={true} style={{
                        borderRadius: '8px',
                        position: 'relative',
                    }}
                    >
                        <Row gutter={16}>
                            <Col xs={24} md={8}>
                                <Form.Item>
                                    <BaseSelect
                                        options={customerActiveList || []}
                                        optionValue="id"
                                        optionLabel="full_name"
                                        additionalLabel="phone"
                                        additionalLabel2="code"
                                        isCustomerList={true}
                                        showSearch={true}
                                        defaultValue={item.related_id ? item.related_id : null}
                                        placeholder="Nhập mã, tên, 3 số cuối sđt của khách hàng"
                                        onSearch={(val) => onSearchCustomer(val)}
                                        onScrollEnd={(e) => handleCustomerScroll(e)}
                                        onChange={(e) => onChangeItem('related_id', e, item.key)}
                                        allowClear={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item>
                                    <BaseSelect
                                        options={relationshipTypeActiveList || []}
                                        optionValue="id"
                                        optionLabel="name"
                                        placeholder="Quan hệ"
                                        defaultValue={item.relationship_type}
                                        style={{ width: '100%' }}
                                        showSearch={true}
                                        onChange={(e) => onChangeItem('relationship_type', e, item.key)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={8}>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <BaseSelect
                                        options={relationshipTypeActiveList || []}
                                        optionValue="id"
                                        optionLabel="name"
                                        placeholder="Quan hệ ngược"
                                        defaultValue={item.relationship_type_inverse}
                                        style={{ width: '100%' }}
                                        showSearch={true}
                                        onChange={(e) => onChangeItem('relationship_type_inverse', e, item.key)}
                                    />
                                </Form.Item>
                            </Col>
                            <div className='customer-family-delete-icon'>
                                <Popconfirm
                                    title="Xóa dòng này?"
                                    onConfirm={() => onDelete(item.key)}
                                    placement='topRight'
                                >
                                    <CloseOutlined />
                                </Popconfirm>
                            </div>
                        </Row>
                    </Card>
                ))}
            </div>
            <Button type="default" size='small' onClick={onAdd} icon={<PlusOutlined />}>Thêm thành viên trong gia đình</Button>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        relationshipTypeActiveList: state.RelationshipTypeReducer.relationshipTypeActiveList,
        customerActiveList: state.CustomerReducer.customerActiveList,
        pagination_active: state.CustomerReducer.pagination_active,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRelationshipTypeActive: (filter) => dispatch(getRelationshipTypeActive(filter)),
        getListCustomerActive: (filter) => dispatch(getListCustomerActive(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FamilyRelationships);
