import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    EditOutlined,
    EyeOutlined,
    ReloadOutlined,
    UserOutlined,
    UserSwitchOutlined
} from '@ant-design/icons';
import { Modal, Tooltip, Tag, Avatar, Button, Typography, DatePicker, Row } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import TextTruncate from 'react-text-truncate';
import CustomerDetail from '../customer/CustomerDetail';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, getFirstLetterOfWords, repeatPhoneNumber, isValidDate, renderTelesaleTime, renderTelesaleDeadline } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import CustomerTelesaleDetail from './CustomerTelesaleDetail';
import CustomerTelesaleResultForm from './CustomerTelesaleResultForm';
import BaseSelect from '../../components/Elements/BaseSelect';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';
import { getListCustomerTelesale, getSpecificCustomerTelesale, removeCustomerTelesale } from '../../redux/actions/CustomerTelesaleActions';
import { getSpecificCustomer } from '../../redux/actions/CustomerActions';
import moment from 'moment';
import TelesaleAssignedToStaffForm from '../../components/TelesaleAssignedToStaffForm';
import DynamicTable from '../../components/DynamicTable';

const { RangePicker } = DatePicker;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/customer-telesale',
        breadcrumbName: 'Lịch telesale',
    },
]
const { Paragraph } = Typography;

class CustomerTelesaleList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            openUpdateResult: false,
            openCustomerDetail: false,
            currentItem: null,
            loadingDetail: false,
            selectedRowKeys: [],
            selectedCustomerIds: [],
            openDetail: false,
            remainingTime: 0,
            visibleAssignedToStaff: false,
        }
    }

    componentDidMount() {
        this.loadData();

        this.intervalId = setInterval(() => {
            this.setState((prevState) => ({
                remainingTime: prevState.remainingTime + 1,
            }));
        }, 30000); // chạy mỗi 30 giây
    }

    componentWillUnmount() {
        // Hủy interval khi component unmount
        clearInterval(this.intervalId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListCustomerTelesale(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }
        delete query.created_at;
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    toggleCustomerDetail(openCustomerDetail, data = null) {
        this.setState({ openCustomerDetail: openCustomerDetail, currentItem: data });
    }

    onCustomerDetail(id) {
        this.setState({ current_id: id, loadingDetail: true })
        this.props.getSpecificCustomer(id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleCustomerDetail(true, res);
        })
    }

    toggleDetail(openDetail, data = null) {
        this.setState({ openDetail: openDetail, currentItem: data });
    }

    onDetail(id) {
        this.setState({ current_id: id, loadingDetail: true })
        this.props.getSpecificCustomerTelesale(id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleDetail(true, res);
        })
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListCustomerTelesale(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    openUpdateResult(id) {
        this.props.getSpecificCustomerTelesale(id).then(res => {
            this.setState({ currentItem: res, openUpdateResult: true, require_field: res.status })
        })
    }

    onCancel() {
        this.loadData()
        this.setState({
            openUpdateResult: false,
            currentItem: null,
            isLoading: false,
            selectedRowKeys: [],
            selectedCustomerIds: [],
            visibleAssignedToStaff: false
        })
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {
        const selectedCustomerIds = selectedRows.map(item => item.id);
        this.setState({ selectedRowKeys, selectedCustomerIds });
    };


    onRemove() {
        this.props.removeCustomerTelesale(this.state.selectedRowKeys).then(() => {
            this.setState({ selectedRowKeys: [] });
            this.loadData();
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }

    render() {
        var { customerTelesaleList, pagination, users, customerStatusActiveList } = this.props;
        var {
            isLoading,
            openCustomerDetail,
            currentItem, current_id,
            loadingDetail,
            openUpdateResult,
            selectedRowKeys,
            openDetail,
            visibleAssignedToStaff,
            selectedCustomerIds
        } = this.state;

        var query = qs.parse(this.props.location.search.slice(1));

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        const columns = [
            {
                title: "STT",
                dataIndex: "stt",
                width: "40px",
                align: 'center',
                key: "stt",
                render: (text, record, index) => index + 1
            },
            {
                title: '#',
                align: 'center',
                key: 'action',
                render: (text, record) => {
                    if (checkPermission('customer_telesale', 'detail')) {
                        return (
                            <Tooltip title="Xem chi tiết lịch telesale" placement="right">
                                <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.customer_telesale_id)}>
                                    <EyeOutlined />
                                </div>
                            </Tooltip>
                        )
                    } else {
                        return (
                            <>
                                <EyeOutlined style={{ color: '#dedede' }} />
                            </>
                        )
                    }
                }
            },
            {
                title: 'Họ và tên',
                dataIndex: 'full_name',
                key: 'full_name',
                width: '200px',
                render: (text, record) => {
                    if (record.full_name) {
                        if (checkPermission('customer', 'detail')) {
                            return (
                                <Tooltip title="Xem hồ sơ khách hàng" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onCustomerDetail(record.id)}>
                                        {
                                            record.avatar ? (
                                                <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        backgroundColor: '#31cdf5',
                                                        verticalAlign: 'middle'
                                                    }}
                                                    size="small">
                                                    {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                                </Avatar>
                                            )
                                        }
                                        &nbsp;{record.full_name}
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    {
                                        record.avatar ? (
                                            <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                        ) : (
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#31cdf5',
                                                    verticalAlign: 'middle'
                                                }}
                                                size="small">
                                                {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                            </Avatar>
                                        )
                                    }
                                    &nbsp;{record.full_name}
                                </>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Mã KH',
                dataIndex: 'code',
                key: 'code',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.code : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Số sổ khám',
                dataIndex: 'medical_book_number',
                key: 'medical_book_number',
                width: '110px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.medical_book_number : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Điện thoại',
                dataIndex: 'phone',
                width: '120px',
                key: 'phone',
                render: (text, record) => {
                    return (
                        <>
                            <HandlePhoneNumber
                                permission={checkPermission('customer', 'phonenumber')}
                                inputPinCode={checkPermission('customer', 'input_pin_code')}
                                customer_id={record?.id}
                                hiddenPhoneNumber={repeatPhoneNumber(text)}
                                fullPhoneNumber={text}
                            />
                        </>
                    )
                }
            },
            {
                title: 'Trạng thái',
                width: '150px',
                dataIndex: 'customer_telesale_status',
                key: 'customer_telesale_status',
                render: (text, record) => {
                    var bgcolor = "#f5b22d";
                    var text2 = "Chưa trả kết quả"
                    if (text == 1) {
                        bgcolor = "green";
                        text2 = "Đã trả kết quả"
                    }
                    if (checkPermission('customer_telesale', 'update_result')) {
                        return (
                            <div>
                                <Tooltip title="Click để cập nhật" placement="right">
                                    <Button icon={<EditOutlined />} style={{ background: bgcolor, color: '#fff', border: 'none' }} onClick={() => this.openUpdateResult(record.customer_telesale_id)} size='small'>
                                        {text2}
                                    </Button>
                                </Tooltip>
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                <Button style={{ background: bgcolor, color: '#fff', border: 'none' }} size='small'>
                                    {text2}
                                </Button>
                            </div>
                        )
                    }
                }
            },
            {
                title: 'Deadline',
                dataIndex: 'deadline',
                key: 'deadline',
                sorter: true,
                width: '170px',
                render: (text, record) => renderTelesaleDeadline(record.deadline, record.customer_telesale_status)
            },
            {
                title: 'Thời điểm tư vấn',
                dataIndex: 'time_telesale',
                key: 'time_telesale',
                sorter: true,
                width: '180px',
                render: (text, record) => renderTelesaleTime(record.deadline, record.time_telesale)
            },
            {
                title: 'Kết quả tư vấn',
                dataIndex: 'result_content',
                key: 'result_content',
                width: '250px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Trạng thái KH',
                dataIndex: 'customer_status',
                key: 'customer_status',
                width: '200px',
                render: (text, record) => {
                    if (record.customer_status_color) {
                        return (
                            <Tag color={record.customer_status_color}>{text}</Tag>
                        )
                    } else {
                        return (<Tag>{text}</Tag>)
                    }
                }
            },
            {
                title: 'Ghi chú KH',
                dataIndex: 'note',
                key: 'note',
                width: '250px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Nhân viên telesale',
                dataIndex: 'telesale',
                key: 'telesale',
                width: '200px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Cơ sở',
                dataIndex: 'branch_name',
                key: 'branch_name',
                width: '130px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Nguồn',
                dataIndex: 'customer_source',
                key: 'customer_source',
                width: '100px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'customer_telesale_created_at',
                key: 'customer_telesale_created_at',
                width: '150px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{isValidDate(text, true)}</div>
                        )
                    } else {
                        return (<Tag>N/A</Tag>)
                    }
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Lịch telesale" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={checkPermission('customer_telesale', 'remove')}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        activeFilter={query.customer_status_id || query.created_date_start || query.created_date_end}
                        placeholderSearch="Mã, tên, 3 số cuối sđt của khách hàng"
                        filters={[
                            <BaseSelect
                                options={customerStatusActiveList || []}
                                optionValue="id"
                                optionLabel="name"
                                showSearch
                                placeholder="Trạng thái khách hàng"
                                onChange={(value) => this.onChangeFilter('customer_status_id', value)}
                                className="w-100"
                                defaultValue={query.customer_status_id ? parseInt(query.customer_status_id) : null}
                            />,
                            <RangePicker
                                format="DD/MM/YYYY"
                                placeholder={['Ngày tạo', 'Đến ngày']}
                                className="w-100"
                                onChange={(value) => this.onChangeFilter('created_at', value)}
                                defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                            />
                        ]}
                    >
                        {
                            checkPermission('customer_telesale', 'telesale_again') ? (
                                <Button icon={<UserSwitchOutlined />} className="table-button" type='primary' disabled={!selectedRowKeys.length ? true : false} onClick={() => this.setState({ visibleAssignedToStaff: true })}>Telesale lại</Button>
                            ) : null
                        }
                        <BaseSelect
                            options={users || []}
                            optionValue="user_id"
                            optionLabel="full_name"
                            showSearch
                            placeholder="Nhận viên telesale"
                            style={{ width: '180px' }}
                            className="table-button"
                            onChange={(value) => this.onChangeFilter('telesale_user_id', value)}
                            defaultValue={query.telesale_user_id ? parseInt(query.telesale_user_id) : null}
                        />
                        <BaseSelect
                            options={[
                                { value: 1, label: 'Đã trả kết quả' },
                                { value: 0, label: 'Chưa trả kết quả' }
                            ]}
                            placeholder="Trạng thái"
                            style={{ width: '180px' }}
                            className="table-button"
                            onChange={(value) => this.onChangeFilter('telesale_status', value)}
                            defaultValue={query.telesale_status ? parseInt(query.telesale_status) : null}
                        />
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                    </TableActionBar>
                    {
                        hasSelected ? <Row className="mb-2" align="middle">Đang chọn {selectedRowKeys.length} bản ghi </Row> : ''
                    }
                </div>

                <div className='has-custom-column-table-btn'>
                    <DynamicTable
                        rowKey="customer_telesale_id"
                        dataSource={customerTelesaleList || []}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        rowSelection={rowSelection}
                        moduleKey="Customer_telesale"
                        onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
                    />
                </div>

                <CustomerDetail
                    visible={openCustomerDetail}
                    currentItem={currentItem}
                    reloadData={() => this.onCustomerDetail(current_id)}
                    onCancel={() => this.toggleCustomerDetail(false)}
                    loadingDetail={loadingDetail}
                />
                <CustomerTelesaleDetail
                    visible={openDetail}
                    currentItem={currentItem}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleDetail(false)}
                    reloadList={() => this.loadData()}
                    loadingDetail={loadingDetail}
                />
                <CustomerTelesaleResultForm
                    visible={openUpdateResult}
                    currentItem={currentItem}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.onCancel()}
                />
                <TelesaleAssignedToStaffForm
                    visible={visibleAssignedToStaff}
                    customer_ids={selectedCustomerIds}
                    onCancel={() => this.onCancel()}
                />
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {
        customerTelesaleList: state.CustomerTelesaleReducer.customerTelesaleList,
        pagination: state.CustomerTelesaleReducer.pagination,
        users: state.config.users,
        authUser: state.auth.authUser,
        customerStatusActiveList: state.CustomerStatusReducer.customerStatusActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCustomerTelesale: (filter) => dispatch(getListCustomerTelesale(filter)),
        getSpecificCustomerTelesale: (id) => dispatch(getSpecificCustomerTelesale(id)),
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id)),
        removeCustomerTelesale: (id) => dispatch(removeCustomerTelesale(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerTelesaleList);

