import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Button, Input, Space, Row, Col, DatePicker, Card, Tag, InputNumber } from 'antd';
import { } from "@ant-design/icons";
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import UploadImage from '../../components/UploadImage';
import FamilyRelationships from './FamilyRelationships';
import { NotificationManager } from 'react-notifications';
import ListCurrentImage from '../../components/ListCurrentImage';
import moment from 'moment';
import BaseRadioList from '../../components/Elements/BaseRadios';
import { updateCustomer } from '../../redux/actions/CustomerActions';
import { _newLine, customDataImageUpload, customerGender, CustomerCardStatus } from '../../utils/helpers';
import { getListProvinces, getListDistricts, getListWards } from '../../redux/actions/RegionsActions';
import { getCustomerSourceActive } from '../../redux/actions/CustomerSourceActions';
import { getBranchActive } from '../../redux/actions/BranchActions';
import { checkPermission } from '../../utils/permission';


class CustomerEditForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            districts: [],
            wards: [],
            avatar: [],
            banner_ads: [],
            isResetUpload: true,
            customer_family: [],
            currentAvatar: [],
            currentCustomerFamily: [],
            currentBannerAds: []
        };
    }
    componentDidMount() {
        this.props.getListProvinces();
        this.props.getCustomerSourceActive();
        this.props.getBranchActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.avatar && Array.isArray(nextProps?.currentItem?.avatar)) {
                this.setState({ currentAvatar: nextProps?.currentItem?.avatar })
            }
            if (nextProps?.currentItem?.customer_family) {
                this.setState({ currentCustomerFamily: nextProps?.currentItem?.customer_family })
            }
            if (nextProps?.currentItem?.banner_ads && Array.isArray(nextProps?.currentItem?.banner_ads)) {
                this.setState({ currentBannerAds: nextProps?.currentItem?.banner_ads })
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentItem != this.props.currentItem) {
            if (this.props.currentItem) {
                this.props.getListDistricts({ province_code: this.props.currentItem.province_code }).then(res => {
                    this.setState({ districts: res.data });
                })
                this.props.getListWards({ district_code: this.props.currentItem.district_code }).then(res2 => {
                    this.setState({ wards: res2.data })
                })
            }
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                delete values.banner_ads
                try {
                    if (this.props.currentItem) {
                        const {
                            avatar,
                            banner_ads,
                            currentAvatar,
                            customer_family,
                            currentBannerAds
                        } = this.state;

                        values.avatar = customDataImageUpload(avatar, currentAvatar);
                        values.banner_ads = customDataImageUpload(banner_ads, currentBannerAds);

                        values.birthday = values.birthday ? values.birthday.format("YYYY-MM-DD") : null;
                        values.customer_family = customer_family;

                        console.log(values)
                        await this.props.updateCustomer(this.props.currentItem.id, values);
                        this.onClose();
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.reloadData();
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({
            isLoading: false,
            avatar: [],
            banner_ads: [],
            isResetUpload: true,
        });
    }

    loadDistricts(province_code) {
        if (province_code) {
            this.props.getListDistricts({ province_code: province_code }).then(res => {
                this.props.form.setFieldsValue({ district_code: null, wards_code: null });
                this.setState({ districts: res.data, wards: [] })
            })
        }
    }

    loadWards(district_code) {
        if (district_code) {
            this.props.getListWards({ district_code: district_code }).then(res => {
                this.props.form.setFieldsValue({ wards_code: null });
                this.setState({ wards: res.data })
            })
        }
    }

    onChangeUpload = (stateKey, data, message) => {
        this.setState({ isResetUpload: false, [stateKey]: data });
        NotificationManager.success(message);
    }

    // Sử dụng hàm onChangeUpload cho từng trạng thái cụ thể
    onChangeAvatar = (data) => this.onChangeUpload('avatar', data, 'Avatar complete.');
    onChangeBannerAds = (data) => this.onChangeUpload('banner_ads', data, 'Banner ads complete.');

    getItems = data => {
        this.setState({ customer_family: data })
    }

    getImages(stateKey, data) {
        this.setState({ [stateKey]: data })
    }

    render() {
        var {
            districts,
            wards,
            isResetUpload,
            isLoading,
            currentAvatar,
            currentCustomerFamily,
            currentBannerAds
        } = this.state;
        var {
            visible,
            currentItem,
            customerClassActiveList,
            customerStatusActiveList,
            customerInteractiveTypeActiveList,
            customerInteractiveStatusActiveList,
            list_provinces,
            customer_source_active_list,
            branchActiveList
        } = this.props;
        const { getFieldDecorator } = this.props.form;

        //console.log('currentItem', currentItem)

        return (
            <Drawer
                visible={visible}
                title={`${currentItem ? currentItem?.full_name : ""} - ${currentItem?.code}`}
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '60%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                Cập nhật
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Card title={<><Tag color='#0b2b33'>1</Tag> Thông tin khách hàng</>} size='small'>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <Row gutter={16}>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Tên khách hàng">
                                                {getFieldDecorator('full_name', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.full_name : null
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Cơ sở">
                                                {getFieldDecorator('branch_id', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền',
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.branch_id : null,
                                                    normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                                })(
                                                    <BaseSelect
                                                        options={branchActiveList || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        defaultText="Chọn cơ sở"
                                                        placeholder="Chọn cơ sở"
                                                        className="w-100"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Giới tính">
                                                {getFieldDecorator("gender", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.gender : null,
                                                    normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                                })(
                                                    <BaseSelect
                                                        options={customerGender || []}
                                                        placeholder="Chọn một bản ghi"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {currentItem.phone ?
                                            checkPermission('customer', 'phonenumber_update') &&
                                            <Col xs={24} md={8}>
                                                <Form.Item label="Số điện thoại">
                                                    {getFieldDecorator("phone", {
                                                        rules: [
                                                            {
                                                                required: false,
                                                                message: "Vui lòng điền!",
                                                            },
                                                        ],
                                                        initialValue: currentItem ? currentItem.phone : null
                                                    })(
                                                        <Input placeholder='0978015988' />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            : (
                                                <Col xs={24} md={8}>
                                                    <Form.Item label="Số điện thoại">
                                                        {getFieldDecorator("phone", {
                                                            rules: [
                                                                {
                                                                    required: false,
                                                                    message: "Vui lòng điền!",
                                                                },
                                                            ],
                                                            initialValue: currentItem ? currentItem.phone : null
                                                        })(
                                                            <Input placeholder='0978015988' />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            )
                                        }
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Nguồn">
                                                {getFieldDecorator("customer_source_id", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.customer_source_id : null,
                                                    normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                                })(
                                                    <BaseSelect
                                                        options={customer_source_active_list || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        showSearch
                                                        placeholder="Chọn một bản ghi"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Năm sinh">
                                                {getFieldDecorator('birthday', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentItem && moment(currentItem.birthday).isValid() ? moment(currentItem.birthday, 'YYYY') : null,
                                                    normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                                })(
                                                    <DatePicker
                                                        picker="year"
                                                        style={{ width: '100%' }}
                                                        placeholder="Chọn"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label='Hạng khách hàng'>
                                                {getFieldDecorator("customer_class_id", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.customer_class_id : null,
                                                    normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                                })(
                                                    <BaseSelect
                                                        options={customerClassActiveList || []}
                                                        optionValue="id"
                                                        optionLabel="name"
                                                        showSearch
                                                        placeholder="Chọn một bản ghi"
                                                        style={{ width: '100%' }}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Mã số thẻ cũ">
                                                {getFieldDecorator("card_number", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.card_number : null
                                                })(
                                                    <Input />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Mã số thẻ mới">
                                                {getFieldDecorator("card_number_new", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.card_number_new : null
                                                })(
                                                    <Input />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Mã số sổ khám">
                                                {getFieldDecorator("medical_book_number", {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.medical_book_number : null
                                                })(
                                                    <Input />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {/* <Col xs={24} md={8}>
                                            <Form.Item label="Doanh số">
                                                {getFieldDecorator("revenue", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.revenue ?? 0 : 0
                                                })(
                                                    <InputNumber
                                                        className='w-100'
                                                        addonAfter="vnđ"
                                                        step={100000}
                                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col> */}
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Trạng thái thẻ">
                                                {getFieldDecorator('card_status', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng chọn!",
                                                        },
                                                    ],
                                                    initialValue: currentItem ? currentItem.card_status : 'none',
                                                })(
                                                    <BaseRadioList
                                                        options={CustomerCardStatus}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                {/* <Col xs={24} md={6} style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Form.Item label="&nbsp;" className='flex-upload'>
                                        <ListCurrentImage currentImages={currentAvatar} getData={(data) => this.getImages('currentAvatar', data)} />

                                        {getFieldDecorator('avatar', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <UploadImage
                                                uploadText="Avatar"
                                                multiple={false}
                                                maxCount={1}
                                                onChangeData={this.onChangeAvatar}
                                                isResetUpload={isResetUpload}
                                            />
                                        )}
                                    </Form.Item>
                                </Col> */}
                            </Row>
                        </Card>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? _newLine(currentItem.note) : null
                                    })(
                                        <Input.TextArea rows={4} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Banner quảng cáo" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                    <ListCurrentImage currentImages={currentBannerAds} getData={(data) => this.getImages('currentBannerAds', data)} />
                                    {getFieldDecorator('banner_ads', {
                                        rules: [
                                            {
                                                required: false,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: null
                                    })(
                                        <UploadImage
                                            multiple={true}
                                            maxCount={10}
                                            onChangeData={this.onChangeBannerAds}
                                            isResetUpload={isResetUpload}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Card title={<><Tag color='#0b2b33'>2</Tag> Tương tác</>} size='small' className='mt-4'>
                            <Row gutter={16}>
                                <Col xs={24} md={8}>
                                    <Form.Item label='Trạng thái'>
                                        {getFieldDecorator("customer_status_id", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.customer_status_id : null,
                                            normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                        })(
                                            <BaseSelect
                                                options={customerStatusActiveList || []}
                                                optionValue="id"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label='Loại tương tác'>
                                        {getFieldDecorator("fb_Interactive_type_id", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.fb_Interactive_type_id : null,
                                            normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                        })(
                                            <BaseSelect
                                                options={customerInteractiveTypeActiveList || []}
                                                optionValue="id"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label='Trạng thái tương tác'>
                                        {getFieldDecorator("fb_Interactive_status_id", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.fb_Interactive_status_id : null,
                                            normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                        })(
                                            <BaseSelect
                                                options={customerInteractiveStatusActiveList || []}
                                                optionValue="id"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Tên facebook">
                                        {getFieldDecorator('fb_name', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: 'Vui lòng điền!',
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.fb_name : null
                                        })(<Input disabled={currentItem.mode == 'from_message' ? true : false} />)}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Link facebook">
                                        {getFieldDecorator("fb_link", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.fb_link : null
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Link fanpage">
                                        {getFieldDecorator("fb_source", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.fb_source : null
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Card>
                        <Card title={<><Tag color='#0b2b33'>3</Tag> Thông tin nhân khẩu</>} size='small' className='mt-4'>
                            <Row gutter={16}>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Tỉnh/Thành phố">
                                        {getFieldDecorator("province_code", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.province_code : null,
                                            normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                        })(
                                            <BaseSelect
                                                options={list_provinces || []}
                                                optionValue="code"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                                onChange={(code) => this.loadDistricts(code)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Quận/Huyện">
                                        {getFieldDecorator("district_code", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.district_code : null,
                                            normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                        })(
                                            <BaseSelect
                                                options={districts || []}
                                                optionValue="code"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                                onChange={(code) => this.loadWards(code)}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={8}>
                                    <Form.Item label="Phường/Xa">
                                        {getFieldDecorator("wards_code", {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng chọn!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.wards_code : null,
                                            normalize: value => value !== undefined ? value : null // Trường hợp value = undefined thì tên field vẫn được truyền lên server
                                        })(
                                            <BaseSelect
                                                options={wards || []}
                                                optionValue="code"
                                                optionLabel="name"
                                                showSearch
                                                placeholder="Chọn một bản ghi"
                                                style={{ width: '100%' }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Địa chỉ">
                                        {getFieldDecorator('address', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: currentItem ? currentItem.address : null
                                        })(
                                            <Input />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16} className='mb-5'>
                                <Col xs={24} md={24}>
                                    <FamilyRelationships currentData={currentCustomerFamily} getItems={(data) => this.getItems(data)} />
                                </Col>
                            </Row>
                        </Card>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        customerClassActiveList: state.CustomerClassReducer.customerClassActiveList,
        customerStatusActiveList: state.CustomerStatusReducer.customerStatusActiveList,
        customerInteractiveTypeActiveList: state.CustomerInteractiveTypeReducer.customerInteractiveTypeActiveList,
        customerInteractiveStatusActiveList: state.CustomerInteractiveStatusReducer.customerInteractiveStatusActiveList,
        list_provinces: state.RegionsReducer.list_provinces,
        customer_source_active_list: state.CustomerSourceReducer.customer_source_active_list,
        branchActiveList: state.BranchReducer.branchActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateCustomer: (id, data) => dispatch(updateCustomer(id, data)),
        getListProvinces: () => dispatch(getListProvinces()),
        getListDistricts: (filter) => dispatch(getListDistricts(filter)),
        getListWards: (filter) => dispatch(getListWards(filter)),
        getCustomerSourceActive: () => dispatch(getCustomerSourceActive()),
        getBranchActive: () => dispatch(getBranchActive())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerEditForm' })(CustomerEditForm));
