import {
    ASSISTANT_LIST_CUSTOMER_HAS_PERFORMED,
    ASSISTANT_LIST_SCHEDULE_UPCOMING
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    customer_performed: {
        customers: [],
        pagination: {
            currentPage: 1,
            total: 0,
            perPage: 50
        }
    },
    schedule_upcoming: {
        schedules: [],
        pagination: {
            currentPage: 1,
            total: 0,
            perPage: 50
        }
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ASSISTANT_LIST_CUSTOMER_HAS_PERFORMED: {
            return {
                ...state,
                customer_performed: {
                    ...state.customer_performed,
                    customers: action.payload.data,
                    pagination: {
                        currentPage: parseInt(action.payload.current_page),
                        total: parseInt(action.payload.total),
                        perPage: parseInt(action.payload.per_page),
                    }
                }
            }
        }
        case ASSISTANT_LIST_SCHEDULE_UPCOMING: {
            return {
                ...state,
                schedule_upcoming: {
                    ...state.schedule_upcoming,
                    customers: action.payload.data,
                    pagination: {
                        currentPage: parseInt(action.payload.current_page),
                        total: parseInt(action.payload.total),
                        perPage: parseInt(action.payload.per_page),
                    }
                }
            }
        }
        default: return { ...state };
    }
}
