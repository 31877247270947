import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Space, Row, Col, Modal } from 'antd';
import { UserOutlined } from "@ant-design/icons";
import { isMobile } from 'react-device-detect';
import { updateCashReceiptStatus } from '../../../redux/actions/CashReceiptActions';
import { CashReceiptStatus, PaymentType } from '../../../utils/helpers';
import BaseRadioList from '../../../components/Elements/BaseRadios';

class UpdateStatusForm extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    onCancel() {
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ isLoading: false });
    }

    async onSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    await this.props.updateCashReceiptStatus(this.props.currentData.id, values).then(res => {
                        this.props.reloadData();
                        this.onCancel();
                    }).catch(err => {
                        this.setState({ isLoading: false });
                    });
                    console.log('values', values);
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    render() {
        var { isLoading } = this.state;
        var { visible, currentData } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                visible={visible}
                title="Cập nhật trạng thái phiếu"
                onOk={(e) => this.onSubmit(e)}
                onCancel={() => this.onCancel()}
                maskClosable={false}
                width={isMobile ? '100%' : '35%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onCancel()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.onSubmit(e)}
                            >
                                Cập nhật
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <h6><UserOutlined /> <span className='color-primary'>{currentData?.customer_name} - {currentData?.customer_code}</span></h6>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Trạng thái">
                                    {getFieldDecorator("status", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData?.status : null
                                    })(
                                        <BaseRadioList
                                            options={CashReceiptStatus || []}
                                            showSearch
                                            placeholder="Chọn một bản ghi"
                                            className="w-100"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Loại thanh toán">
                                    {getFieldDecorator('payment_type', {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentData ? currentData.payment_type : null
                                    })(
                                        <BaseRadioList
                                            options={PaymentType ?? []}
                                            showSearch
                                            placeholder="Loại thanh toán"
                                            className="w-100"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateCashReceiptStatus: (id, data) => dispatch(updateCashReceiptStatus(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'UpdateStatusForm' })(UpdateStatusForm));
