import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Row, Col, InputNumber, Drawer, Space, Divider, Table, Popconfirm, DatePicker } from 'antd';
import { isMobile } from 'react-device-detect';
import { ProductReceiptExportType, customDataImageUpload, isValidDateForm } from '../../utils/helpers';
import BaseSelectCustom from '../../components/Elements/BaseSelect/custom';
import BaseSelect from '../../components/Elements/BaseSelect';
import { DeleteTwoTone } from '@ant-design/icons';
import NumberFormat from 'react-number-format';
import UploadImage from '../../components/UploadImage';
import { NotificationManager } from 'react-notifications'
import { createNewProductReceiptExport } from '../../redux/actions/ProductReceiptExportActions';
import { getProductWarehouseActive } from '../../redux/actions/ProductWarehouseAction';
import { getActiveProduct, getSpecificProduct } from '../../redux/actions/ProductActions';
import { getListCustomerActive } from '../../redux/actions/CustomerActions';
import { getBranchActive } from '../../redux/actions/BranchActions';
import moment from 'moment';

class ProductReceiptExportAddForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            items: [],
            productQuery: '',
            customerQuery: '',
            currentType: '',
            images: [],
            isResetUploadImage: true
        };
    }
    componentDidMount() {
        this.props.getProductWarehouseActive();
        this.props.getActiveProduct();
        this.props.getListCustomerActive();
        this.props.getBranchActive();
    }

    renderNewItem(items = []) {
        let new_items = items.map((item, index) => {
            let quantity_export = item.quantity
            return {
                id: item.id,
                name: item.name,
                code: item.code,
                regular_price: item.regular_price,
                sale_price: item.sale_price,
                quantity: quantity_export,
                quantity_in_stock: item.quantity_in_stock,
                current_quantity_in_stock: item.current_quantity_in_stock,
                amount: item.sale_price * quantity_export,
                warehouse_name: item.warehouse_name,
                warehouse_id: item.warehouse_id,
                expiry_date: item.expiry_date,
                note: item.note,
                unit: item.unit,
                mode: item.mode
            }
        })
        this.setState({
            items: new_items.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i)
        });
    }

    onDelete(id) {
        let items = this.state.items.filter(item => {
            return item.id != id;
        });
        this.renderNewItem(items)
    }

    // async onChangeItem(name, value, itemId) {
    //     let items = [...this.state.items];
    //     let index = items.findIndex(item => item.id == itemId);
    //     let currentItem = items[index];
    //     currentItem[name] = value;
    //     items[index] = currentItem;
    //     this.renderNewItem(items)
    // }

    async onChangeItem(name, value, itemId) {
        let items = [...this.state.items];
        let index = items.findIndex(item => item.id == itemId);
        let currentItem = items[index];
        if (name === 'quantity') {
            if (value == '' || value == null) {
                value = 1
            }
            const difference = value - currentItem.quantity;
            currentItem.quantity_in_stock -= difference;
        }
        if (name === 'sale_price') {
            if (value == '' || value == null) {
                value = 0
            }
        }
        currentItem[name] = value;
        items[index] = currentItem;
        this.renderNewItem(items)
    }

    onChangeProduct(id) {
        this.setState({ isloading: true })
        this.props.getSpecificProduct(id).then((item) => {
            let quantity_export = 1
            let new_items = {
                id: item.id,
                name: item.name,
                code: item.code,
                regular_price: item.regular_price,
                sale_price: item.sale_price,
                quantity: quantity_export,
                quantity_in_stock: item.quantity_in_stock > 0 ? item.quantity_in_stock - quantity_export : 0,
                current_quantity_in_stock: item.quantity_in_stock,
                amount: item.sale_price * quantity_export,
                warehouse_name: item.warehouse_name,
                warehouse_id: item.warehouse_id,
                expiry_date: item.expiry_date,
                note: item.note,
                unit: item.unit,
                product_status_name: item.product_status_name,
                mode: null
            }
            this.setState({
                items: [...this.state.items, new_items].filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i),
                isloading: false
            })
        })
    }

    onSearchProduct(val) {
        this.setState({ productQuery: val }, () => {
            this.props.getActiveProduct({ keyword: val });
        })
    }

    handleScroll(e) {
        this.props.getActiveProduct({ keyword: this.state.productQuery, page: this.props.pagination.currentPage + 1 }, true);
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true })
                try {
                    const { items, images } = this.state;
                    values.images = customDataImageUpload(images, []);
                    values.export_date = values.export_date ? values.export_date.format("YYYY-MM-DD") : null;
                    values.items = items;
                    var total_amount = 0;
                    items.forEach(item => {
                        total_amount += item.amount;
                    });
                    values.total_amount = total_amount;
                    //console.log('values: ', values)
                    await this.props.createNewProductReceiptExport(values).then(res => {
                        this.props.reloadData();
                        this.onClose();
                    }).catch(err => {
                        this.setState({ isLoading: false });
                    })
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.getActiveProduct();
        this.props.onCancel();
        this.props.form.resetFields();
        this.setState({ isLoading: false, items: [], images: [] })
    }

    onSearchCustomer(val) {
        this.setState({ customerQuery: val }, () => {
            this.props.getListCustomerActive({ keyword: val });
        })
    }

    handleScrollCustomer(e) {
        this.props.getListCustomerActive({ keyword: this.state.customerQuery, page: this.props.pagination_active.currentPage + 1 });
    }

    onChangeType(value) {
        this.setState({ currentType: value })
    }

    onChangeImage = (data) => {
        this.setState({
            isResetUploadImage: false,
            images: data
        });
        NotificationManager.success("Image complete.");
    }

    render() {
        var { isLoading, items, currentType, isResetUploadImage } = this.state;
        var { visible,
            branchActiveList,
            product_warehouse_active_list,
            product_list,
            customerActiveList
        } = this.props;
        const { getFieldDecorator } = this.props.form;

        var columns = [
            {
                title: "STT",
                dataIndex: "stt",
                width: "40px",
                align: 'center',
                render: (text, record, index) => index + 1
            },
            {
                title: "Tên sản phẩm",
                dataIndex: "name",
                //width: "180px",
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: "Kho",
                dataIndex: "warehouse_name",
                width: "120px"
            },
            {
                title: "Giá bán",
                dataIndex: "sale_price",
                width: "100px",
                render: (text, record) => {
                    return (
                        <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            value={text}
                            name="sale_price"
                            step={1000}
                            min={0}
                            className='w-100 fix-height-input'
                            onChange={(value) => this.onChangeItem('sale_price', value, record.id)}
                        />
                    )
                }
            },
            {
                title: "SL xuất",
                dataIndex: "quantity",
                width: "80px",
                render: (text, record) => {
                    return (
                        <>
                            <InputNumber
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                value={text}
                                name="quantity"
                                max={record.current_quantity_in_stock}
                                min={1}
                                className='w-100 fix-height-input'
                                //onChange={(value) => this.onChangeItem('quantity', value, record.id)}
                                onChange={(value) => {
                                    // Kiểm tra giá trị và thay thế nếu cần thiết
                                    const validValue = value == null || value <= 0 ? 1 : value;
                                    this.onChangeItem('quantity', validValue, record.id);
                                }}
                            />
                        </>
                    )
                }
            },
            {
                title: 'SL tồn',
                dataIndex: "quantity_in_stock",
                width: "80px",
                render: (text, record) => {
                    return (
                        <>
                            {record.quantity_in_stock}
                        </>
                    )
                }
            },
            {
                title: "Thành tiền",
                dataIndex: "amount",
                width: "100px",
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                    )
                }
            },
            {
                title: "Đơn vị tính",
                dataIndex: "unit",
                width: "100px"
            },
            {
                title: "Hạn sử dụng",
                dataIndex: "expiry_date",
                width: "120px"
            },
            {
                title: "#",
                align: "center",
                width: '30px',
                render: (text, record) => {
                    return (
                        <Popconfirm
                            title="Xóa sản phẩm này?"
                            onConfirm={() => this.onDelete(record.id)}
                        >
                            <DeleteTwoTone twoToneColor="#fe0000" />
                        </Popconfirm>
                    )
                }
            }
        ];

        var total_amount = 0;
        items.forEach(item => {
            total_amount += item.amount;
        });

        return (
            <Drawer
                visible={visible}
                title="Tạo phiếu xuất kho"
                onOk={(e) => this.submit(e)}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '75%'}
                footer={
                    <Row justify="end">
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button loading={isLoading} key="submit" type="primary" onClick={(e) => this.submit(e)}>
                                Tạo phiếu
                            </Button>
                        </Space>
                    </Row>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Divider orientation="left" orientationMargin="0">
                                    Thông tin sản phẩm
                                </Divider>
                                <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                        <BaseSelectCustom
                                            options={product_list}
                                            optionValue="id"
                                            optionLabel="name"
                                            additionalLabel1="quantity"
                                            additionalLabel2="expiry_date_format"
                                            additionalLabel3="warehouse_name"
                                            showSearch
                                            allowClear={false}
                                            defaultValue="Chọn sản phẩm có sẵn trong kho"
                                            placeholder="Chọn sản phẩm có sẵn trong kho"
                                            onSearch={(val) => this.onSearchProduct(val)}
                                            onScrollEnd={(e) => this.handleScroll(e)}
                                            onChange={(id) => this.onChangeProduct(id)}
                                            className="w-100 mb-2"
                                        />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Table
                                            className='mt-2'
                                            size='small'
                                            rowKey='key'
                                            tableLayout="auto"
                                            columns={columns}
                                            dataSource={items || []}
                                            pagination={false}
                                            loading={isLoading}
                                            locale={{ emptyText: "Chưa có dữ liệu" }}
                                            scroll={{
                                                x: 'max-content'
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row justify="end" gutter={16} className='mt-4'>
                                    <Col xs={24} md={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <h5>Tổng tiền: <NumberFormat value={total_amount} displayType={'text'} suffix="đ" thousandSeparator={true} /></h5>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} md={24}>
                                <Divider orientation="left" orientationMargin="0">
                                    Thông tin phiếu
                                </Divider>
                                <div className='box-info'>
                                    <Row gutter={16}>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Tên phiếu">
                                                {getFieldDecorator('name', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: null
                                                })(<Input />)}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Loại phiếu">
                                                {getFieldDecorator('type', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: null
                                                })(
                                                    <BaseSelect
                                                        options={ProductReceiptExportType}
                                                        placeholder="Loại phiếu"
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => this.onChangeType(value)}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {
                                            currentType == 'ban' || currentType == 'xuatchokhach' ? (
                                                <Col xs={24} md={6}>
                                                    <Form.Item label={<>Khách hàng: </>}>
                                                        {getFieldDecorator("customer_id", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Vui lòng chọn!",
                                                                },
                                                            ],
                                                            initialValue: null
                                                        })(
                                                            <BaseSelect
                                                                options={customerActiveList || []}
                                                                optionValue="id"
                                                                optionLabel="full_name"
                                                                additionalLabel="code"
                                                                allowClear
                                                                showSearch
                                                                placeholder="Mã, tên khách hàng"
                                                                onSearch={(val) => this.onSearchCustomer(val)}
                                                                onScrollEnd={(e) => this.handleScrollCustomer(e)}
                                                                style={{ width: '100%' }}
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            ) : null
                                        }
                                        {
                                            currentType == 'chuyenkho' ? (
                                                <Col xs={24} md={6}>
                                                    <Form.Item label="Chuyển đến kho">
                                                        {getFieldDecorator('warehouse_to_id', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: 'Vui lòng điền!',
                                                                },
                                                            ],
                                                            initialValue: null
                                                        })(<BaseSelect
                                                            options={product_warehouse_active_list || []}
                                                            optionValue="id"
                                                            optionLabel="name"
                                                            showSearch
                                                            placeholder="Chọn kho sản phẩm"
                                                            style={{ width: '100%' }}
                                                        />)}
                                                    </Form.Item>
                                                </Col>
                                            ) : null
                                        }
                                        {
                                            currentType == 'cososudung' ? (
                                                <Col xs={24} md={6}>
                                                    <Form.Item label="Cơ sở">
                                                        {getFieldDecorator('branch_id', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: 'Vui lòng điền',
                                                                },
                                                            ],
                                                            initialValue: null
                                                        })(
                                                            <BaseSelect
                                                                options={branchActiveList || []}
                                                                optionValue="id"
                                                                optionLabel="name"
                                                                placeholder="Chọn cơ sở"
                                                                className="w-100"
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                            ) : null
                                        }
                                        <Col xs={24} md={6}>
                                            <Form.Item label="Ngày xuất kho">
                                                {getFieldDecorator('export_date', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: isValidDateForm(moment(), true)
                                                })(
                                                    <DatePicker
                                                        format="DD/MM/YYYY"
                                                        placeholder="Chọn ngày"
                                                        className='w-100'
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Ảnh phiếu" className='flex-upload'>
                                                {getFieldDecorator('images', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: null
                                                })(
                                                    <UploadImage
                                                        multiple={true}
                                                        maxCount={30}
                                                        onChangeData={this.onChangeImage}
                                                        isResetUpload={isResetUploadImage}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item label="Ghi chú">
                                        {getFieldDecorator('note', {
                                            rules: [
                                                {
                                                    required: false,
                                                    message: "Vui lòng điền!",
                                                },
                                            ],
                                            initialValue: null
                                        })(
                                            <Input.TextArea />
                                        )}
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        product_supplier_active_list: state.ProductSupplierReducer.product_supplier_active_list,
        product_warehouse_active_list: state.ProductWarehouseReducer.product_warehouse_active_list,
        product_list: state.ProductReducer.product_list,
        pagination: state.ProductReducer.pagination,
        customerActiveList: state.CustomerReducer.customerActiveList,
        pagination_active: state.CustomerReducer.pagination_active,
        branchActiveList: state.BranchReducer.branchActiveList,
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createNewProductReceiptExport: (data) => dispatch(createNewProductReceiptExport(data)),
        getProductWarehouseActive: () => dispatch(getProductWarehouseActive()),
        getActiveProduct: (filter, concatenate) => dispatch(getActiveProduct(filter, concatenate)),
        getSpecificProduct: (id) => dispatch(getSpecificProduct(id)),
        getBranchActive: () => dispatch(getBranchActive()),
        getListCustomerActive: (filter) => dispatch(getListCustomerActive(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ProductReceiptExportAddForm' })(ProductReceiptExportAddForm));
