import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    DeleteOutlined,
    DownOutlined,
    MenuOutlined,
    ReloadOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Table, Tooltip, Tag, Avatar, Space, Dropdown, Popover, Typography, Button } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import TextTruncate from 'react-text-truncate';
import CustomerDetail from '../customer/CustomerDetail';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, getFirstLetterOfWords } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';

import moment from 'moment';

import { getSpecificCustomer } from '../../redux/actions/CustomerActions';
import { getLisCustomer } from '../../redux/actions/CustDocAssistActions';

const { Paragraph } = Typography;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/customer-doctor-assistant',
        breadcrumbName: 'Khách hàng',
    },
]

class CustDocAssistList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            currentItem: null,
            isOpenDetail: false,
            currentData: null,
        }
    }

    componentDidMount() {
        this.loadData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getLisCustomer(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query = cleanObject(query);
        //console.log("query: ", query)
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };


    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.setState({ current_id: id, loadingDetail: true })
        this.props.getSpecificCustomer(id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleOpenDetail(true, res);
        })
    }

    loadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getLisCustomer(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    render() {
        var { pagination, customers } = this.props;
        var { isLoading, selectedRowKeys,
            isOpenDetail,
            currentData, current_id,
            loadingDetail,
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        var query = qs.parse(this.props.location.search.slice(1));

        const renderItems = (record) => {
            const itemsAction = [];

            if (checkPermission('customer', 'remove')) {
                itemsAction.push(
                    {
                        key: '9',
                        label: (
                            <div onClick={() => this.openAlert(record.id)}>Xóa khách hàng</div>
                        ),
                        icon: <DeleteOutlined />,
                        danger: true
                    }
                )
            }
            Object.keys(itemsAction).sort();
            //console.log(Object.keys(itemsAction).sort());
            return itemsAction;
        }

        const columns = [
            {
                title: '#',
                align: 'center',
                width: '50px',
                render: (text, record) => {
                    const items = renderItems(record);
                    return (
                        <Space size="middle">
                            {items && items.length > 0 ? (
                                <Dropdown trigger="click" menu={{ items }}>
                                    <div className='item-action-btn'>
                                        <MenuOutlined /> <DownOutlined />
                                    </div>
                                </Dropdown>
                            ) : (
                                <MenuOutlined style={{ color: '#dedede' }} />
                            )}
                        </Space>
                    );
                }
            },
            {
                title: 'Họ và tên',
                dataIndex: 'full_name',
                key: 'full_name',
                width: '250px',
                render: (text, record) => {
                    if (record.full_name) {
                        if (checkPermission('customer', 'detail')) {
                            return (
                                <Tooltip title="Xem hồ sơ" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                        {
                                            record.avatar ? (
                                                <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        backgroundColor: '#31cdf5',
                                                        verticalAlign: 'middle'
                                                    }}
                                                    size="small">
                                                    {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                                </Avatar>
                                            )
                                        }
                                        &nbsp;{record.full_name}
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    {
                                        record.avatar ? (
                                            <Avatar size='small' icon={<UserOutlined />} src={record.avatar} />
                                        ) : (
                                            <Avatar
                                                style={{
                                                    backgroundColor: '#31cdf5',
                                                    verticalAlign: 'middle'
                                                }}
                                                size="small">
                                                {record.full_name ? getFirstLetterOfWords(record.full_name) : ''}
                                            </Avatar>
                                        )
                                    }
                                    &nbsp;{record.full_name}
                                </>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Mã KH',
                dataIndex: 'code',
                key: 'code',
                width: '140px',
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.code : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Lịch hẹn',
                dataIndex: 'appointment_schedule',
                key: 'appointment_schedule',
                width: '180px',
                render: (text, record) => {
                    if (text && Array.isArray(text) && text.length) {
                        let content = text.map(item => {
                            return (
                                <div style={{ padding: '5px 0' }}>
                                    {moment(item.time).format('DD/MM/YYYY HH:mm')}
                                </div>
                            )
                        })
                        let first_item = text[0]?.time;
                        return (
                            <div className='item-action-btn'>
                                <Popover placement="right" content={content}>
                                    {moment(first_item).format('DD/MM/YYYY HH:mm')}&nbsp;
                                    <DownOutlined />
                                </Popover>
                            </div>
                        )
                    } else {
                        return (<></>)
                    }
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'customer_status',
                key: 'customer_status',
                width: '200px',
                render: (text, record) => {
                    if (record.customer_status_color) {
                        return (
                            <Tag color={record.customer_status_color}>{text}</Tag>
                        )
                    } else {
                        return (<Tag>{text}</Tag>)
                    }
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '250px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Cơ sở',
                dataIndex: 'branch_name',
                key: 'branch_name',
                width: '130px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Nguồn',
                dataIndex: 'customer_source',
                key: 'customer_source',
                width: '100px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '100px',
                render: (text, record) => {
                    if (text) {
                        return (
                            <div>{moment(text).format('DD/MM/YYYY')}</div>
                        )
                    } else {
                        return (<Tag>N/A</Tag>)
                    }
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Khách hàng" />
                <div>
                    <TableActionBar
                        isShowAddButton={false}
                        isShowDeleteButton={false}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        placeholderSearch="Mã, tên, 3 số cuối sđt của khách hàng"
                    >
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>

                <Table
                    rowKey="id"
                    size="small"
                    tableLayout='auto'
                    dataSource={customers || []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`,
                        defaultPageSize: 30, showSizeChanger: true, pageSizeOptions: ['10', '20', '30', '40', '50', '100']
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />

                <CustomerDetail
                    visible={isOpenDetail}
                    currentItem={currentData}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleOpenDetail(false)}
                    loadingDetail={loadingDetail}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        customers: state.CustDocAssistReducer.customers,
        pagination: state.CustDocAssistReducer.pagination
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getLisCustomer: (filter) => dispatch(getLisCustomer(filter)),
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustDocAssistList' })(CustDocAssistList));

