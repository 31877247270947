import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _, { } from 'lodash';
import qs from 'qs';
import { Row, Card, Col, Tabs, Spin } from 'antd';
import { cleanObject } from '../../utils/helpers';
import StatisticNumber from '../../components/StatisticNumber';
import BaseSelect from '../../components/Elements/BaseSelect';
import { getSummaryStatistics } from '../../redux/actions/StatisticActions';

const Statistic = (props) => {

    const { statistic, users, authUser } = props;

    const [type, setType] = React.useState('1');
    const [isLoading, setIsLoading] = React.useState(false);

    var query = qs.parse(props.location.search.slice(1));

    useEffect(() => {
        loadData();
    }, [query.type, query.staff]);

    const loadData = () => {
        setIsLoading(true);
        props.getSummaryStatistics(query).then(() => {
            setIsLoading(false);
        }).catch(() => {
            setIsLoading(false);
        });
    }

    const onChangeTab = (name, value) => {
        setType(value);
        var query = qs.parse(props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        query = cleanObject(query);
        props.history.push({
            pathname: props.location.pathname,
            search: qs.stringify(query)
        });
    }

    return (
        <div>
            <Row gutter={16}>
                <Col xs={24} md={24} className='box-item-mobile box-item-mobile-header'>
                    <Card size="small"
                        bordered={false}
                        headStyle={{ border: 0 }}
                    >
                        <Row gutter={16} align="middle">
                            {authUser.role_code === 'ADMIN' ? (
                                <Col xs={24} md={24} lg={6} xl={6}>
                                    <BaseSelect
                                        options={users || []}
                                        optionValue="user_id"
                                        optionLabel="full_name"
                                        showSearch
                                        placeholder="Chọn nhân viên"
                                        className="w-100"
                                        onChange={(value) => onChangeTab('staff', value)}
                                        defaultValue={query.staff ? parseInt(query.staff) : null}
                                    />
                                </Col>
                            ) : null}
                            <Col xs={24} md={24} lg={18} xl={18}>
                                <Tabs
                                    activeKey={query?.type || 'day' || type}
                                    onChange={(type) => onChangeTab('type', type)}
                                    size='small'
                                    className='tabs-custom-children'
                                    centered
                                >
                                    <Tabs.TabPane key="day" tab={<>Theo ngày</>}></Tabs.TabPane>
                                    <Tabs.TabPane key="month" tab={<>Theo tháng</>}></Tabs.TabPane>
                                    <Tabs.TabPane key="year" tab={<>Theo năm</>}></Tabs.TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Spin tip="Loading..." spinning={isLoading}>
                <Row gutter={16} className="mb-2 mt-2 customer-data-statistic-row" justify="flex-start">
                    <Card
                        className="customer-data-statistic">
                        <StatisticNumber
                            title="Ca tư vấn"
                            value={statistic?.catuvan}
                            precision={0}
                        />
                    </Card>
                    <Card className="customer-data-statistic">
                        <StatisticNumber
                            title="Ca phụ tá"
                            value={statistic?.caphuta}
                            precision={0}
                        />
                    </Card>
                    {(authUser.role_code === 'ADMIN' || authUser.role_code === 'BACSI') && (
                        <Card className="customer-data-statistic">
                            <StatisticNumber
                                title="Tổng số bệnh nhân"
                                value={statistic?.tongsobenhnhan}
                                precision={0}
                            />
                        </Card>
                    )}
                    <Card className="customer-data-statistic">
                        <StatisticNumber
                            title="Số khách hàng đã tạo"
                            value={statistic?.khachhangdatao}
                            precision={0}
                        />
                    </Card>
                    <Card className="customer-data-statistic">
                        <StatisticNumber
                            title="Số lịch hẹn đã tạo"
                            value={statistic?.lichhendatao}
                            precision={0}
                        />
                    </Card>
                    <Card className="customer-data-statistic">
                        <StatisticNumber
                            title="Số cuộc gọi đã tele"
                            value={statistic?.cuocgoidatele}
                            precision={0}
                        />
                    </Card>
                    <Card className="customer-data-statistic">
                        <StatisticNumber
                            title="Số cuộc gọi tele chậm quá hạn"
                            value={statistic?.cuocgoidatele_quahan}
                            precision={0}
                        />
                    </Card>
                    <Card className="customer-data-statistic">
                        <StatisticNumber
                            title="Số cuộc gọi chưa trả kết quả tele"
                            value={statistic?.cuocgoidatele_chuatrakq}
                            precision={0}
                        />
                    </Card>
                </Row>
            </Spin>
        </div>
    );
}


function mapStateToProps(state) {
    return {
        users: state.config.users,
        statistic: state.StatisticReducer.statistic,
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSummaryStatistics: (filter) => dispatch(getSummaryStatistics(filter))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Statistic));