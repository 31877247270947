import api from '../../utils/api';
import {
    ASSISTANT_LIST_CUSTOMER_HAS_PERFORMED,
    ASSISTANT_LIST_SCHEDULE_UPCOMING
} from './types';
import { NotificationManager } from 'react-notifications';


// Danh sách bệnh nhân đã thực hiện
export const getListCustomerHasPerformed = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/assistant/customer-has-performed', { params: filter }).then(res => {
            dispatch({
                type: ASSISTANT_LIST_CUSTOMER_HAS_PERFORMED,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}


export const getListScheduleUpcoming = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/assistant/schedule/upcoming', { params: filter }).then(res => {
            dispatch({
                type: ASSISTANT_LIST_SCHEDULE_UPCOMING,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
