import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import { Col, Row } from 'antd';
import { } from "@ant-design/icons";
import UploadImage from './UploadImage';
import { NotificationManager } from 'react-notifications';
import CurrentImage from './CurrentImage';

const ProfileForm = (props) => {

    const { getImageProfile, profileData } = props;

    const [isResetUpload, setIsResetUpload] = useState(true);

    const [state, setState] = useState({
        portraitPhoto: [],
        consultationForm: [],
        medicalRecordBook: [],
        XRayImage: [],
        receipt: [],
        document: [],
        currentConsultationForm: [],
        currentMedicalRecordBook: [],
        currentDocument: [],
        currentPortraitPhoto: [],
        currentReceipt: [],
        currentXRayImage: [],
    });

    const customDataImageUpload = (data = [], oldData = []) => {
        // Gộp cả hai mảng lại với nhau
        const mergedData = [...oldData, ...data];

        // Đặt lại giá trị cho trường id theo thứ tự tuần tự
        const updatedData = mergedData.map((item, index) => ({
            ...item,
            id: index + 1, // Đặt lại id từ 1 đến tổng số phần tử
        }));

        return updatedData;
    };

    const updateDocumentState = useCallback((data, key, stateKey) => {
        if (!data) return;
        const documents = data[key]?.images;
        setState((prevState) => ({
            ...prevState,
            [stateKey]: Array.isArray(documents) ? documents : [],
        }));
    }, []);

    const loadData = useCallback(() => {
        const data = profileData;
        updateDocumentState(data, 'consultation_form', 'currentConsultationForm');
        updateDocumentState(data, 'medical_record_book', 'currentMedicalRecordBook');
        updateDocumentState(data, 'orther_document', 'currentDocument');
        updateDocumentState(data, 'portrait_photo', 'currentPortraitPhoto');
        updateDocumentState(data, 'receipt', 'currentReceipt');
        updateDocumentState(data, 'x_ray_image', 'currentXRayImage');
    }, [profileData, updateDocumentState]);

    useEffect(() => {
        // Khi customer_id thay đổi, reset các state liên quan
        setState({
            portraitPhoto: [],
            consultationForm: [],
            medicalRecordBook: [],
            XRayImage: [],
            receipt: [],
            document: [],
            currentConsultationForm: [],
            currentMedicalRecordBook: [],
            currentDocument: [],
            currentPortraitPhoto: [],
            currentReceipt: [],
            currentXRayImage: [],
        });
        setIsResetUpload(true); // Reset trạng thái upload
    }, [profileData]);

    useEffect(() => {
        if (profileData) {
            loadData();
        }
    }, [profileData, loadData]);

    useEffect(() => {
        if (getImageProfile) {
            const values = {};
            values.profile_portrait_photo = customDataImageUpload(state.portraitPhoto, state.currentPortraitPhoto);
            values.profile_consultation_form = customDataImageUpload(state.consultationForm, state.currentConsultationForm);
            values.profile_medical_record_book = customDataImageUpload(state.medicalRecordBook, state.currentMedicalRecordBook);
            values.profile_orther_document = customDataImageUpload(state.document, state.currentDocument);
            values.profile_receipt = customDataImageUpload(state.receipt, state.currentReceipt);
            values.profile_x_ray_image = customDataImageUpload(state.XRayImage, state.currentXRayImage);
            getImageProfile(values);
        }
    }, [state]);

    const handleChangeUpload = (stateKey, data) => {
        setIsResetUpload(false);
        setState((prevState) => ({ ...prevState, [stateKey]: data }));
        NotificationManager.success('Tải ảnh thành công');
    };

    // Sử dụng hàm handleChangeUpload cho từng trạng thái cụ thể
    const onChangePortraitPhoto = (data) => handleChangeUpload('portraitPhoto', data);
    const onChangeConsultationForm = (data) => handleChangeUpload('consultationForm', data);
    const onChangeMedicalRecordBook = (data) => handleChangeUpload('medicalRecordBook', data);
    const onChangeXRayImage = (data) => handleChangeUpload('XRayImage', data);
    const onChangeReceipt = (data) => handleChangeUpload('receipt', data);
    const onChangeDocument = (data) => handleChangeUpload('document', data);

    const getImages = (stateKey, data) => {
        setState((prevState) => ({ ...prevState, [stateKey]: data }));
    }

    //console.log('profileData', profileData);

    return (
        <>
            <Row gutter={16}>
                <Col xs={24} md={12}>
                    <Form.Item label="Ảnh chân dung" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                        <CurrentImage currentImages={state.currentPortraitPhoto} getData={(data) => getImages('currentPortraitPhoto', data)} />
                        <UploadImage
                            multiple={true}
                            maxCount={10}
                            onChangeData={onChangePortraitPhoto}
                            isResetUpload={isResetUpload}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="Phiếu tư vấn" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                        <CurrentImage currentImages={state.currentConsultationForm} getData={(data) => getImages('currentConsultationForm', data)} />
                        <UploadImage
                            multiple={true}
                            maxCount={10}
                            onChangeData={onChangeConsultationForm}
                            isResetUpload={isResetUpload}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="Sổ khám" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                        <CurrentImage currentImages={state.currentMedicalRecordBook} getData={(data) => getImages('currentMedicalRecordBook', data)} />
                        <UploadImage
                            multiple={true}
                            maxCount={10}
                            onChangeData={onChangeMedicalRecordBook}
                            isResetUpload={isResetUpload}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="Phim X Quang" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                        <CurrentImage currentImages={state.currentXRayImage} getData={(data) => getImages('currentXRayImage', data)} />
                        <UploadImage
                            multiple={true}
                            maxCount={10}
                            onChangeData={onChangeXRayImage}
                            isResetUpload={isResetUpload}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="Phiếu thu" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                        <CurrentImage currentImages={state.currentReceipt} getData={(data) => getImages('currentReceipt', data)} />
                        <UploadImage
                            multiple={true}
                            maxCount={10}
                            onChangeData={onChangeReceipt}
                            isResetUpload={isResetUpload}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item label="Giấy tờ khác" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                        <CurrentImage currentImages={state.currentDocument} getData={(data) => getImages('currentDocument', data)} />
                        <UploadImage
                            multiple={true}
                            maxCount={10}
                            onChangeData={onChangeDocument}
                            isResetUpload={isResetUpload}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}


function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ProfileForm' })(ProfileForm)));
