import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import { Button, Input, Space, Row, Col, InputNumber, Collapse, Descriptions, Divider, Card, Drawer, Modal } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { NotificationManager } from 'react-notifications';
import { isValidDate } from '../../../utils/helpers';
import UploadImage from '../../../components/UploadImage';
import Consultant from './Consultant';
import ExaminationVisit from './ExaminationVisit';

const { Panel } = Collapse;
const { confirm } = Modal;

const generateUniqueId = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
};

const BulkUpdateServiceForm = (props) => {

    const { getFieldDecorator } = props.form;

    const { visible, onCancel, getBulkUpdate, config, currentDate } = props;

    const [currentConsultant, setCurrentConsultant] = useState([]);
    const [visibleConsultant, setVisibleConsultant] = useState(false);
    const [currentExaminationVisit, setCurrentExaminationVisit] = useState([]);
    const [visibleExaminationVisit, setVisibleExaminationVisit] = useState(false);
    const [resetUploadImagesBefore, setResetUploadImagesBefore] = useState(true);
    const [resetUploadImagesAfter, setResetUploadImagesAfter] = useState(true);
    const [items, setItems] = useState({
        key: 0,
        quantity: 1,
        sale_price: 0,
        amount: 0,
        note: "",
        examination_visit: [],
        consultant: [],
        images_before: [],
        images_after: [],
    });

    const onClose = () => {
        onCancel();
        setResetUploadImagesBefore(true);
        setResetUploadImagesAfter(true);
        setItems({
            key: 0,
            quantity: 1,
            sale_price: 0,
            amount: 0,
            note: "",
            examination_visit: [],
            consultant: [],
            images_before: [],
            images_after: []
        });
    }

    const onChangeItem = (name, value) => {
        setItems(prevItems => {
            const updatedItem = {
                ...prevItems,
                [name]: value,
            };
            if (name === 'quantity' || name === 'sale_price') {
                updatedItem.amount = updatedItem.quantity * updatedItem.sale_price;
            }
            return updatedItem;
        });
    };

    const addConsultant = () => {
        setVisibleConsultant(true);
        setCurrentConsultant(items.consultant);
    };

    const getConsultant = (data) => {
        const updatedData = data.map(item => {
            const user = config?.users?.find(user => user.user_id === item.user_id);
            return {
                ...item,
                full_name: user ? user.full_name : ''
            };
        });
        setItems(prevItems => {
            const updatedItems = {
                ...prevItems,
                consultant: updatedData,
            };
            return updatedItems;
        });
    }

    const addExaminationVisit = () => {
        setVisibleExaminationVisit(true);
        setCurrentExaminationVisit(items.examination_visit);
    }

    const getExaminationVisit = (data) => {
        const updatedData = data.map(item => {
            const doctors = item.doctor_charge_id.map(doctorId => {
                const user = config?.users?.find(user => user.user_id === doctorId);
                return user ? user.full_name : '';
            });

            const assistant = item.assistant_doctor_id.map(assistantId => {
                const user = config?.users?.find(user => user.user_id === assistantId);
                return user ? user.full_name : '';
            });

            return {
                ...item,
                doctor_charge_names: doctors,
                assistant_doctor_names: assistant
            };
        });

        setItems(prevItems => {
            const updatedItems = {
                ...prevItems,
                examination_visit: updatedData,
            };
            return updatedItems;
        });
    }

    const onChangeImagesBefore = (data) => {
        const newData = data.map(item => {
            return {
                id: generateUniqueId(),
                src: item,
            };
        });

        setResetUploadImagesBefore(false);

        setItems(prevItems => ({
            ...prevItems,
            images_before: newData,
        }));

        NotificationManager.success("Tải ảnh before thành công.");
    };


    const onChangeImagesAfter = (data) => {

        const newData = data.map(item => {
            return {
                id: generateUniqueId(),
                src: item,
            };
        });

        setResetUploadImagesAfter(false);
        setItems(prevItems => {
            const updatedItems = {
                ...prevItems,
                images_after: newData,
            };
            return updatedItems;
        });
        NotificationManager.success("Tải ảnh after thành công.");
    }

    const handleSave = (e) => {
        e.preventDefault();
        confirm({
            title: 'Bạn có chắc chắn muốn cập nhật?',
            content: 'Thao tác này sẽ cập nhật tất cả các dịch vụ đã chọn.',
            onOk: () => {
                getBulkUpdate(items);
                onClose();
            },
            onCancel: () => {

            },
        });
    }

    return (
        <Drawer
            visible={visible}
            title="Cập nhật nhiều dịch vụ"
            onOk={(e) => handleSave(e)}
            onClose={() => onClose()}
            maskClosable={false}
            width={isMobile ? '100%' : '40%'}
            bodyStyle={{ padding: '20px 10px' }}
            footer={
                <div div style={{ textAlign: 'right', }}>
                    <Space>
                        <Button key="back" onClick={() => onClose()}>
                            Huỷ
                        </Button>
                        <Button
                            key="submit"
                            type="primary"
                            onClick={(e) => handleSave(e)}
                        >
                            Cập nhật
                        </Button>
                    </Space>
                </div>
            }
        >
            {
                visible ?
                    <Form layout='vertical' className='customer-data-customer-service-form'>
                        < Row gutter={16} >
                            <Col xs={24} md={24} className='mb-2'>
                                {/* <Descriptions size="small">
                                    <Descriptions.Item label="Số lượng">
                                        <InputNumber
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            value={items.quantity}
                                            name="quantity"
                                            min={1}
                                            className='w-100'
                                            onChange={(value) => {
                                                // Kiểm tra giá trị và thay thế nếu cần thiết
                                                const validValue = value == null || value <= 0 ? 1 : value;
                                                onChangeItem('quantity', validValue);
                                            }}
                                        />
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Giá bán">
                                        <InputNumber
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            value={items.sale_price}
                                            name="sale_price"
                                            min={0}
                                            className='w-100'
                                            onChange={(value) => onChangeItem('sale_price', value)}
                                        />
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Thành tiền">
                                        <InputNumber
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            value={items.amount}
                                            name="amount"
                                            min={0}
                                            className='w-100'
                                            onChange={(value) => onChangeItem('amount', value)}
                                        />
                                    </Descriptions.Item>
                                </Descriptions>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Ghi chú">
                                        <Input.TextArea
                                            value={items.note}
                                            name="note"
                                            style={{ height: 20, marginBottom: 0 }}
                                            onChange={(e) => onChangeItem(e.target.name, e.target.value)}
                                        />
                                    </Descriptions.Item>
                                </Descriptions> */}
                                <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                        <Card
                                            size='small'
                                            title="Nhân viên tư vấn"
                                            extra={<Button onClick={() => addConsultant()} type='primary' icon={<UserAddOutlined />} size='small'>Cập nhật</Button>}
                                            className='mt-3'
                                            headStyle={{ padding: 0, borderBottom: '1px dashed #d9d9d9' }}
                                            bodyStyle={{ padding: '10px 0px' }}
                                            bordered={false}
                                        >
                                            {items.consultant.length > 0 ? (
                                                items.consultant.map((item, index) => {
                                                    return (
                                                        <>
                                                            <Descriptions size="small" key={index}>
                                                                <Descriptions.Item>
                                                                    {item.full_name} - {isValidDate(item.consultant_date, true)}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className="no-margin" />
                                                        </>
                                                    );
                                                })
                                            ) : (
                                                <div style={{ textAlign: 'center', padding: 0 }} className="ant-list-empty-text">
                                                    Chưa có dữ liệu.
                                                </div>
                                            )}
                                        </Card>
                                    </Col>
                                    <Col xs={24} md={24} className='examination-visit'>
                                        <Card
                                            size='small'
                                            title="Lần khám"
                                            extra={<Button onClick={() => addExaminationVisit()} type='primary' icon={<UserAddOutlined />} size='small'>Cập nhật</Button>}
                                            className='mt-3'
                                            headStyle={{ padding: 0, borderBottom: '1px dashed #d9d9d9' }}
                                            bodyStyle={{ padding: 0 }}
                                            bordered={false}
                                        >
                                            {items.examination_visit.length > 0 ? (
                                                <>
                                                    <Collapse
                                                        expandIconPosition="end"
                                                        ghost
                                                        defaultActiveKey={[]}
                                                    >
                                                        {
                                                            items.examination_visit.map((item, index) => {
                                                                return (
                                                                    <Panel header={
                                                                        <>
                                                                            <span className="chingo-normal-title">
                                                                                {isValidDate(item.time, true)}
                                                                            </span>
                                                                        </>
                                                                    } key={index}>
                                                                        <Divider className='no-margin' orientation="left" orientationMargin="0">Bác sĩ khám</Divider>
                                                                        {item.doctor_charge_names.length > 0 ? (
                                                                            <ul className='examination-visit-doctor-list'>
                                                                                {item.doctor_charge_names.map((doctor, index) => {
                                                                                    return (
                                                                                        <li key={index}>{doctor}</li>
                                                                                    );
                                                                                })}
                                                                            </ul>
                                                                        ) : null}
                                                                        <Divider className='no-margin' orientation="left" orientationMargin="0">Phụ tá</Divider>
                                                                        {item.assistant_doctor_names.length > 0 ? (
                                                                            <ul className='examination-visit-doctor-list'>
                                                                                {item.assistant_doctor_names.map((assistant, index) => {
                                                                                    return (
                                                                                        <li key={index}>{assistant}</li>
                                                                                    );
                                                                                })}
                                                                            </ul>
                                                                        ) : null}
                                                                    </Panel>
                                                                );
                                                            })
                                                        }
                                                    </Collapse>
                                                </>
                                            ) : (
                                                <div style={{ textAlign: 'center', padding: 0 }} className="ant-list-empty-text">
                                                    Chưa có dữ liệu.
                                                </div>
                                            )}
                                        </Card>
                                    </Col>
                                </Row>
                                {/* <Row gutter={16} className='mt-4'>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Ảnh before" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                            {getFieldDecorator('images_before', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng điền!',
                                                    },
                                                ],
                                                initialValue: null
                                            })(
                                                <UploadImage
                                                    uploadText="Chọn ảnh"
                                                    multiple={true}
                                                    maxCount={10}
                                                    onChangeData={(data) => onChangeImagesBefore(data)}
                                                    isResetUpload={resetUploadImagesBefore}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Ảnh after" className='flex-upload border-dashed' style={{ padding: '5px' }}>
                                            {getFieldDecorator('images_after', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng điền!',
                                                    },
                                                ],
                                                initialValue: null
                                            })(
                                                <UploadImage
                                                    uploadText="Chọn ảnh"
                                                    multiple={true}
                                                    maxCount={10}
                                                    onChangeData={(data) => onChangeImagesAfter(data)}
                                                    isResetUpload={resetUploadImagesAfter}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row> */}
                            </Col>
                        </Row >

                        <Consultant
                            visible={visibleConsultant}
                            onCancel={() => setVisibleConsultant(false)}
                            getConsultant={getConsultant}
                            currentConsultant={currentConsultant}
                            currentDate={currentDate}
                        />

                        <ExaminationVisit
                            visible={visibleExaminationVisit}
                            onCancel={() => setVisibleExaminationVisit(false)}
                            getExaminationVisit={getExaminationVisit}
                            currentExaminationVisit={currentExaminationVisit}
                            currentDate={currentDate}
                        />
                    </Form >
                    : null}
        </Drawer >
    )
}

function mapStateToProps(state) {
    return {
        config: state.config
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'BulkUpdateServiceForm' })(BulkUpdateServiceForm)));