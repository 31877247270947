import api from '../../utils/api';
import {
    SUMMARY_STATISTICS
} from './types';
import { NotificationManager } from 'react-notifications';

export const getSummaryStatistics = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/statistic', { params: filter }).then(res => {
            dispatch({ type: SUMMARY_STATISTICS, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}