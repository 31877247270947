import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import { Button, DatePicker, Row, Col, Input, Drawer, Space } from 'antd';
import { Form } from '@ant-design/compatible';
import { isValidDateForm, _newLine, CustomerScheduleType } from '../../../utils/helpers';
import { isMobile } from 'react-device-detect';
// actions
import { updateResultCustomerTelesale } from '../../../redux/actions/CustomerTelesaleActions';
import BaseRadioList from '../../../components/Elements/BaseRadios';
import BaseSelect from '../../../components/Elements/BaseSelect';

class TelesaleResultForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            require_field: false,
            require_time: true,
            scheduleOption: null
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.status) {
                this.setState({ require_field: nextProps?.currentItem?.status })
            }
        }
    }

    async handleUpdateResult(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true })
                try {
                    const { currentItem } = this.props;
                    if (currentItem) {
                        values.schedule_records = {
                            name: values.name,
                            time: values.time ? values.time.format("YYYY-MM-DD HH:mm") : null,
                            note: values.note
                        };
                        values.retele_records = {
                            deadline: values.deadline ? values.deadline.format("YYYY-MM-DD HH:mm") : null
                        }
                        delete values.name;
                        delete values.time;
                        delete values.note;
                        delete values.deadline;

                        values.time_telesale = values.time_telesale ? values.time_telesale.format("YYYY-MM-DD HH:mm") : null;
                        await this.props.updateResultCustomerTelesale(currentItem.id, values);
                        this.onCancel()
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
                //console.log(values)
            }
        });
    }

    onCancel() {
        this.props.reloadData()
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ isLoading: false, require_field: false, scheduleOption: null, require_time: true })
    }

    ongChangeStatus(value) {
        this.setState({ require_field: value, scheduleOption: null })
    }

    ongChangeSchedule(value) {
        this.setState({ scheduleOption: value })
    }

    changeName(value) {
        if (value == 'CHUADATDUOCLICH' || value == 'CHUAXACDINHLICH') {
            this.setState({ require_time: false })
            this.props.form.setFieldsValue({ time: null })
        } else {
            this.setState({ require_time: true })
        }
    }

    render() {
        var { currentItem, visible } = this.props;
        var { isLoading, require_field, require_time, scheduleOption } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <Drawer
                    title="Cập nhật kết quả telesale"
                    visible={visible}
                    header={false}
                    width={isMobile ? '100%' : '40%'}
                    onClose={() => this.onCancel()}
                    maskClosable={false}
                    footer={
                        <div style={{ textAlign: 'right', }}>
                            <Space>
                                <Button key="back" onClick={() => this.onCancel()}>
                                    Huỷ
                                </Button>
                                <Button
                                    loading={isLoading}
                                    key="submit"
                                    type="primary"
                                    onClick={(e) => this.handleUpdateResult(e)}
                                >
                                    Cập nhật
                                </Button>
                            </Space>
                        </div>
                    }
                >
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <h6><UserOutlined /> <span className='color-primary'>{`${currentItem?.customer_name} - ${currentItem?.customer_code}`}</span></h6>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Trạng thái">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng điền!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.status : null
                                    })(
                                        <BaseRadioList
                                            options={[
                                                { value: 1, label: 'Trả kết quả' },
                                                { value: 0, label: 'Chưa trả kết quả' }
                                            ]}
                                            className="w-100"
                                            onChange={(value) => this.ongChangeStatus(value)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                require_field ? (
                                    <>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Thời điểm tư vấn">
                                                {getFieldDecorator('time_telesale', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: currentItem ? isValidDateForm(currentItem.time_telesale, true) : null
                                                })(
                                                    <DatePicker
                                                        style={{ width: '100%' }}
                                                        showTime={{ format: 'HH:mm' }}
                                                        format="DD/MM/YYYY HH:mm"
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Kết quả tư vấn">
                                                {getFieldDecorator('result_content', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Vui lòng điền!",
                                                        },
                                                    ],
                                                    initialValue: currentItem ? _newLine(currentItem.result_content) : null
                                                })(
                                                    <Input.TextArea rows={8} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Lập lịch">
                                                {getFieldDecorator('scheduleOption', {
                                                    rules: [
                                                        {
                                                            required: false,
                                                            message: 'Vui lòng điền!',
                                                        },
                                                    ],
                                                    initialValue: null
                                                })(
                                                    <BaseRadioList
                                                        options={[
                                                            { value: null, label: 'Không áp dụng' },
                                                            { value: 'schedule', label: 'Lịch hẹn' },
                                                            { value: 'retele', label: 'Lịch telesale lại' }
                                                        ]}
                                                        className="w-100"
                                                        onChange={(value) => this.ongChangeSchedule(value)}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {
                                            scheduleOption == 'schedule' ? (
                                                <>
                                                    <Col xs={24} md={12}>
                                                        <Form.Item label='Kiểu lịch hẹn'>
                                                            {getFieldDecorator("name", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Vui lòng chọn!",
                                                                    },
                                                                ],
                                                                initialValue: null
                                                            })(
                                                                <BaseSelect
                                                                    options={CustomerScheduleType}
                                                                    showSearch
                                                                    placeholder="Chọn một bản ghi"
                                                                    style={{ width: '100%' }}
                                                                    onChange={(value) => this.changeName(value)}
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    {
                                                        require_time ? (
                                                            <Col xs={24} md={12}>
                                                                <Form.Item label="Thời gian">
                                                                    {getFieldDecorator('time', {
                                                                        rules: [
                                                                            {
                                                                                required: require_time,
                                                                                message: 'Vui lòng điền!',
                                                                            },
                                                                        ],
                                                                        initialValue: null
                                                                    })(
                                                                        <DatePicker
                                                                            style={{ width: '100%' }}
                                                                            showTime={{ format: 'HH:mm' }}
                                                                            format="DD/MM/YYYY HH:mm"
                                                                        />
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                        ) : null
                                                    }
                                                    <Col xs={24} md={24}>
                                                        <Form.Item label="Ghi chú">
                                                            {getFieldDecorator('note', {
                                                                rules: [
                                                                    {
                                                                        required: false,
                                                                        message: "Vui lòng điền!",
                                                                    },
                                                                ],
                                                                initialValue: null
                                                            })(
                                                                <Input.TextArea rows={4} />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            ) : null
                                        }
                                        {
                                            scheduleOption == 'retele' ? (
                                                <>
                                                    <Col xs={24} md={24}>
                                                        <Form.Item label="Thời điểm telesale lại">
                                                            {getFieldDecorator("deadline", {
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: "Vui lòng chọn!",
                                                                    },
                                                                ],
                                                                initialValue: null
                                                            })(
                                                                <DatePicker
                                                                    style={{ width: '100%' }}
                                                                    showTime={{ format: 'HH:mm' }}
                                                                    format="DD/MM/YYYY HH:mm"
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            ) : null
                                        }

                                    </>
                                ) : null
                            }
                        </Row>
                    </Form>
                </Drawer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateResultCustomerTelesale: (id, data) => dispatch(updateResultCustomerTelesale(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'TelesaleResultForm' })(TelesaleResultForm));

