import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import { DeleteOutlined, EditOutlined, EyeOutlined, FileProtectOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Row, Tag, Descriptions, Typography, Modal, Tooltip } from 'antd';
import { checkPermission } from '../../../utils/permission';
import HtmlParser from 'react-html-parser';
import { ConvertCashReceiptStatus, ConvertCashReceiptStatusInList, isValidDate } from '../../../utils/helpers';
import NumberFormat from 'react-number-format';
import PaymentForm from './PaymentForm';
import { getSpecificCashReceipt, removeCashReceipt } from '../../../redux/actions/CashReceiptActions';
import NoData from '../NoData';
import PaymentDetail from './PaymentDetail';
import UpdateStatusForm from './UpdateStatusForm';

const { Text } = Typography;
const { confirm } = Modal;

const Payment = (props) => {

    const { customer, data } = props;

    const [isOpenForm, setOpenForm] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [isOpenDetail, setOpenDetail] = useState(false);
    const [isOpenStatusForm, setOpenStatusForm] = useState(false);

    const toggleOpenForm = (isOpen) => {
        setOpenForm(isOpen);
    }

    const onEdit = (id) => {
        props.getSpecificCashReceipt(id).then(response => {
            if (response) {
                //console.log(response);
                setCurrentData(response);
                toggleOpenForm(true);
            }
        });
    }

    const onRemove = (ids) => {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => props.removeCashReceipt(ids).then(() => {
                props.reloadData();
            }),
            onCancel() { },
        });
    }

    const toggleOpenDetail = (isOpenDetail, data = null) => {
        setOpenDetail(isOpenDetail);
        setCurrentData(data);
    }

    const onDetail = (id) => {
        props.getSpecificCashReceipt(id).then(res => {
            toggleOpenDetail(true, res);
        })
    }

    const openUpdateStatusForm = (id) => {
        props.getSpecificCashReceipt(id).then(res => {
            setOpenStatusForm(true);
            setCurrentData(res);
        })
    }

    return (
        <div>
            <Row justify="end" gutter={16}>
                <Col xs={24} md={24} className='box-item-mobile box-item-mobile-header'>
                    <Card size="small"
                        title={<><Tag color='#0b2b33'>4</Tag> Phiếu thanh toán</>}
                        bordered={false}
                    >
                        <Button
                            disabled={!checkPermission('cash_receipt', 'create')}
                            type="primary"
                            className='w-100'
                            onClick={() => toggleOpenForm(true)}
                            icon={<PlusCircleOutlined />}>
                            Tạo phiếu thanh toán
                        </Button>
                    </Card>
                </Col>
            </Row>
            {
                data && data?.length ? (
                    <Row gutter={16}>
                        {
                            data?.map((item, index) => {
                                return (
                                    <Col key={index} xs={24} md={12} lg={8} className='box-item-mobile'>
                                        <Card size="small"
                                            title={<>{<FileProtectOutlined />} {item.code}</>}
                                            bordered={false}
                                            extra={
                                                <>
                                                    {checkPermission('cash_receipt', 'update') ? (
                                                        <a onClick={() => onEdit(item.id)} type="link" key="list-vertical-edit">
                                                            <EditOutlined /> Sửa
                                                        </a>
                                                    ) : null}

                                                    {checkPermission('cash_receipt', 'remove') ? (
                                                        <>
                                                            <Divider type='vertical' />
                                                            <span className='delete-icon' onClick={() => onRemove([item.id])} type="link" key="list-vertical-delete">
                                                                <DeleteOutlined /> Xóa
                                                            </span>
                                                        </>
                                                    ) : null}
                                                    <Divider type='vertical' />
                                                    <span className='item-action-btn' onClick={() => onDetail(item.id)}>
                                                        <EyeOutlined /> Xem
                                                    </span>
                                                </>
                                            }
                                        >
                                            <Row gutter={16}>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Ngày thanh toán">
                                                            {isValidDate(item.payment_at, false)}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className='no-margin' />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Tổng">
                                                            <NumberFormat value={item.total_amount} displayType={'text'} thousandSeparator={true} />
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className='no-margin' />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Ưu đãi">
                                                            <NumberFormat value={item.special_discount} displayType={'text'} thousandSeparator={true} />
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className='no-margin' />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Doanh số">
                                                            <NumberFormat value={item.adjusted_sales} displayType={'text'} thousandSeparator={true} />
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className='no-margin' />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Số tiền thanh toán">
                                                            <NumberFormat value={item.amount_paid} displayType={'text'} thousandSeparator={true} />
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className='no-margin' />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Tổng đã thanh toán">
                                                            <NumberFormat value={item.total_amount_paid} displayType={'text'} thousandSeparator={true} />
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className='no-margin' />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Công nợ">
                                                            <NumberFormat value={item.remaining_debt} displayType={'text'} thousandSeparator={true} />
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className='no-margin' />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Trạng thái">
                                                            {/* {
                                                                checkPermission('cash_receipt', 'update_status') ? (
                                                                    <Tooltip title="Click để cập nhật" placement="right">
                                                                        <div onClick={() => openUpdateStatusForm(item.id)} className='item-action-btn'>
                                                                            {ConvertCashReceiptStatusInList(item.status)}
                                                                        </div>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <>{ConvertCashReceiptStatusInList(item.status)}</>
                                                                )
                                                            } */}
                                                            {ConvertCashReceiptStatus(item.status)}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className='no-margin' />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Text strong>Ghi chú: </Text>
                                                    {HtmlParser(item.note)}
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                ) : (
                    <NoData />
                )
            }
            <PaymentForm
                visible={isOpenForm}
                customer={customer}
                currentData={currentData}
                reloadData={() => props.reloadData()}
                onCancel={() => { toggleOpenForm(false); setCurrentData(null) }}
            />
            <PaymentDetail
                visible={isOpenDetail}
                currentData={currentData}
                onCancel={() => toggleOpenDetail(false)}
            />
            <UpdateStatusForm
                visible={isOpenStatusForm}
                currentData={currentData}
                reloadData={() => props.reloadData()}
                onCancel={() => setOpenStatusForm(false)}
            />
        </div>
    );
}
function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSpecificCashReceipt: (id) => dispatch(getSpecificCashReceipt(id)),
        removeCashReceipt: (ids) => dispatch(removeCashReceipt(ids))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'Payment' })(Payment)));