import React, { useState } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from '@ant-design/compatible';
import { Button, Card, Col, Image, Row, Space, Tag, Typography } from "antd";
import { checkPermission } from "../../../utils/permission";
import { PlusCircleOutlined } from "@ant-design/icons";
import ProfileForm from "./ProfileForm";
import NoData from "../NoData";

const { Text } = Typography;

const ProfileImages = (props) => {

    const { data, customer } = props;

    const [isOpenForm, setIsOpenForm] = useState(false);

    const handleAddForm = (visible) => {
        setIsOpenForm(visible);
    }

    const onCancel = () => {
        setIsOpenForm(false);
    }

    const loadData = () => {
        props.reloadData();
    }

    const renderDocumentCard = (title, images) => (
        <Col xs={24} md={12} lg={8} className="mb-2 item-profile-image">
            <Card size="small" bordered={false}>
                <Text strong style={{ display: "block" }} className="mb-1">
                    {title}
                </Text>
                {Array.isArray(images) && images?.length > 0 ? (
                    <Image.PreviewGroup>
                        <Space wrap>
                            {images.map((item, index) => (
                                <Image
                                    key={index}
                                    height={50}
                                    src={item?.src_small}
                                    style={{
                                        objectFit: 'cover',
                                        objectPosition: 'center'
                                    }}
                                />
                            ))}
                        </Space>
                    </Image.PreviewGroup>
                ) : null}
            </Card>
        </Col>
    );

    const {
        consultation_form = {},
        medical_record_book = {},
        orther_document = {},
        portrait_photo = {},
        receipt = {},
        x_ray_image = {},
    } = data || {};

    return (
        <div>
            <Row justify="end" gutter={16}>
                <Col xs={24} md={24} className='box-item-mobile box-item-mobile-header'>
                    <Card size="small"
                        title={<><Tag color='#0b2b33'>5</Tag> Ảnh hồ sơ</>}
                        bordered={false}
                    >
                        <Button
                            disabled={!checkPermission('customer', 'update_image_profile')}
                            type="primary"
                            className='w-100'
                            onClick={() => handleAddForm(true)}
                            icon={<PlusCircleOutlined />}>
                            Thêm ảnh hồ sơ
                        </Button>
                    </Card>
                </Col>
            </Row>

            {
                data ? (
                    <Row gutter={16}>
                        {renderDocumentCard(
                            consultation_form?.name,
                            consultation_form?.images
                        )}
                        {renderDocumentCard(
                            medical_record_book?.name,
                            medical_record_book?.images
                        )}
                        {renderDocumentCard(
                            portrait_photo?.name,
                            portrait_photo?.images
                        )}
                        {renderDocumentCard(
                            receipt?.name,
                            receipt?.images
                        )}
                        {renderDocumentCard(
                            x_ray_image?.name,
                            x_ray_image?.images
                        )}
                        {renderDocumentCard(
                            orther_document?.name,
                            orther_document?.images
                        )}
                    </Row>
                ) : (
                    <NoData />
                )
            }

            <ProfileForm
                visible={isOpenForm}
                customer={customer}
                reloadData={() => loadData()}
                onCancel={() => onCancel()}
            />
        </div>
    );
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ProfileImages' })(ProfileImages)));