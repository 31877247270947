import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Tag, Row, Col, Image, Card, Space, Typography, Button } from 'antd'
import { getMediaCustomer } from '../../redux/actions/MediaAction';
import { EditOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import CustomerMediaProfileForm from './CustomerMediaProfileForm';

const { Text } = Typography;

class CustomerMediaProfileDetail extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            currentItem: [],
            isOpenForm: false,
            currentCustomer: null
        };
    }

    componentDidMount() {
        this.loadData();
    }

    openForm() {
        this.setState({ currentCustomer: this.props.currentCustomer, isOpenForm: true })
    }

    onCancel() {
        this.setState({ isOpenForm: false })
    }

    loadData() {
        this.setState({ isLoading: true })
        this.props.getMediaCustomer(this.props.currentCustomer?.id).then(res => {
            console.log(res)
            const data = res.data;
            this.setState({ currentItem: data, isLoading: false })
        }).catch(err => {
            this.setState({ isLoading: false })
        });
    }

    render() {
        var { currentItem, isOpenForm, currentCustomer } = this.state;

        const renderDocumentCard = (title, images) => (
            <Col xs={24} md={12} className="mb-2">
                <Card size="small" className="border-dashed">
                    <Col xs={24} md={24}>
                        <Text strong style={{ display: "block" }} className="mb-1">
                            {title}
                        </Text>
                        {Array.isArray(images) && images?.length > 0 ? (
                            <Image.PreviewGroup>
                                <Space wrap>
                                    {images.map((item, index) => (
                                        <Image key={index} height={50} src={item?.src_small} />
                                    ))}
                                </Space>
                            </Image.PreviewGroup>
                        ) : null}
                    </Col>
                </Card>
            </Col>
        );

        const {
            consultation_form = {},
            medical_record_book = {},
            orther_document = {},
            portrait_photo = {},
            receipt = {},
            x_ray_image = {},
        } = currentItem || {};

        return (
            <>
                <Divider orientation="left" orientationMargin="0" className='mt-4 mb-0'>
                    <Tag color='#0b2b33'>7</Tag> Hồ sơ
                </Divider>
                <Row justify="end" className='mb-1'>
                    <Button
                        disabled={!checkPermission('customer', 'update_image_profile')}
                        type="dashed"
                        className='color-blue'
                        size='small'
                        onClick={() => this.openForm()}
                        icon={<EditOutlined />}
                    >
                        Cập nhật
                    </Button>
                </Row>
                {
                    currentItem ? (
                        <Row gutter={16}>
                            {renderDocumentCard(
                                consultation_form?.name,
                                consultation_form?.images
                            )}
                            {renderDocumentCard(
                                medical_record_book?.name,
                                medical_record_book?.images
                            )}
                            {renderDocumentCard(
                                portrait_photo?.name,
                                portrait_photo?.images
                            )}
                            {renderDocumentCard(
                                receipt?.name,
                                receipt?.images
                            )}
                            {renderDocumentCard(
                                x_ray_image?.name,
                                x_ray_image?.images
                            )}
                            {renderDocumentCard(
                                orther_document?.name,
                                orther_document?.images
                            )}
                        </Row>
                    ) : <Row gutter={16} justify='center'><div className='no-data'>Chưa có dữ liệu</div></Row>
                }
                <CustomerMediaProfileForm
                    visible={isOpenForm}
                    currentCustomer={currentCustomer}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.onCancel()}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getMediaCustomer: (filter) => dispatch(getMediaCustomer(filter))
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(CustomerMediaProfileDetail);

// Sử dụng forwardRef để truyền ref xuống component
// Mục đích để cho parent component có thể gọi method của child component
const ConnectedCustomerMediaProfileDetail = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(CustomerMediaProfileDetail);

export default React.forwardRef((props, ref) => (
    <ConnectedCustomerMediaProfileDetail {...props} ref={ref} />
));
