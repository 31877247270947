import api from '../../utils/api';
import {
    GET_LIST_MEDIA,
    GET_ONE_MEDIA,
    CREATE_MEDIA,
    UPDATE_MEDIA,
    REMOVE_MEDIA
} from './types';
import { NotificationManager } from 'react-notifications'

export const getListAll = (filter) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api.get('/media', { params: filter }).then(res => {
            //console.log(res)
            dispatch({
                type: GET_LIST_MEDIA,
                payload: res.data.data
            });
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getOneMedia = id => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/media/${id}`).then(res => {
            dispatch({
                type: GET_ONE_MEDIA,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const getMediaCustomer = id => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/media/customer/${id}`).then(res => {
            dispatch({
                type: GET_ONE_MEDIA,
                payload: res.data.data
            });
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const createMedia = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/media/create', data).then(res => {
            dispatch({
                type: CREATE_MEDIA,
                payload: res.data.data
            });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const updateMedia = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/media/${id}`, data).then(res => {
            dispatch({
                type: UPDATE_MEDIA,
                payload: res.data.data
            });
            NotificationManager.success("Update bản ghi thành công!")
            resolve(res.data)
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}

export const removeMedia = ids => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete('/media', { data: { ids: ids } }).then(res => {
            dispatch({
                type: REMOVE_MEDIA,
                payload: ids
            });
            NotificationManager.success(res.data.msg);
            resolve(res.data.data);
        }).catch(err => {
            //console.log(err);
            NotificationManager.error(err.response.data.msg);
            reject(err);
        });
    });
}