import { Card, Col, Row } from 'antd';
import React from 'react';

const NoData = () => {
    return (
        <Row gutter={16}>
            <Col xs={24} md={24} className='box-item-mobile'>
                <div style={{ textAlign: 'center', width: '100%' }} className='no-data'>
                    <Card size="small" bordered={false}> Chưa có dữ liệu</Card>
                </div>
            </Col>
        </Row>
    );
}

export default NoData;