import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Descriptions, Divider, Tag, Button, Row, Col, Modal, Image, Space, Card, Collapse } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined, MedicineBoxOutlined, FileImageOutlined, FolderOutlined, UserOutlined, EyeOutlined } from '@ant-design/icons';
import { checkPermission } from '../../../utils/permission';
import { isValidDate } from '../../../utils/helpers';
import CustomerServiceAddForm from '../../customer-services/CustomerServiceAddForm';
import HtmlParser from 'react-html-parser';
import NumberFormat from 'react-number-format';
import { getSpecificService, removeService } from '../../../redux/actions/CustomerServicesActions';
import CustomerServiceEditForm from '../../customer-services/CustomerServiceEditForm';
import NoData from '../NoData';
import CustomerServicesDetail from './CustomerServicesDetail';

const { confirm } = Modal;
const { Panel } = Collapse;

class CustomerServices extends Component {
    constructor() {
        super();
        this.state = {
            isOpenEditForm: false,
            isOpenAddForm: false,
            currentData: null,
            isOpenDetail: false,
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenForm(isOpenEditForm, currentData = null) {
        this.setState({
            isOpenEditForm: isOpenEditForm,
            currentData: currentData,
            currentCustomer: this.props.customer
        });
    }

    onEdit(id) {
        this.props.getSpecificService(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    openRemove(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeService(id).then(() => {
                setTimeout(() => {
                    this.props.reloadData();
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    onDetail(id) {
        this.props.getSpecificService(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    render() {
        var { currentData, isOpenAddForm, isOpenEditForm, currentCustomer, isOpenDetail } = this.state;
        var { customer } = this.props;

        return (
            <div>
                <>
                    <Row justify="end" gutter={16}>
                        <Col xs={24} md={24} className='box-item-mobile box-item-mobile-header'>
                            <Card size="small"
                                title={<><Tag color='#0b2b33'>3</Tag> Dịch vụ đã chốt</>}
                                bordered={false}
                            >
                                <Button
                                    disabled={!checkPermission('customer_services', 'create')}
                                    type="primary"
                                    className='w-100'
                                    onClick={() => this.setState({ isOpenAddForm: true, currentCustomer: this.props.customer })}
                                    icon={<PlusOutlined />}>
                                    Thêm dịch vụ chốt
                                </Button>
                            </Card>
                        </Col>
                    </Row>

                    {
                        customer?.services_selected?.length ? (
                            <Row gutter={16}>
                                {
                                    customer?.services_selected.map((item, index) => {
                                        return (
                                            <Col key={index} xs={24} md={12} lg={8} className='box-item-mobile'>
                                                <Card size="small"
                                                    title={<>{<MedicineBoxOutlined />} {item.service_name}</>}
                                                    bordered={false}
                                                    extra={
                                                        <>
                                                            {checkPermission('customer_services', 'update') ? (
                                                                <a onClick={() => this.onEdit(item.id)} type="link" key="list-vertical-edit">
                                                                    <EditOutlined /> Sửa
                                                                </a>
                                                            ) : null}

                                                            {checkPermission('customer_services', 'remove') ? (
                                                                <>
                                                                    <Divider type='vertical' />
                                                                    <span className='delete-icon' onClick={() => this.openRemove([item.id])} type="link" key="list-vertical-delete">
                                                                        <DeleteOutlined /> Xóa
                                                                    </span>
                                                                </>
                                                            ) : null}
                                                            <Divider type='vertical' />
                                                            <span className='item-action-btn' onClick={() => this.onDetail(item.id)}>
                                                                <EyeOutlined /> Xem
                                                            </span>
                                                        </>
                                                    }
                                                >
                                                    <Row gutter={16}>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Giá bán">
                                                                    <NumberFormat value={item.sale_price} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Số lượng">
                                                                    <NumberFormat value={item.quantity} displayType={'text'} thousandSeparator={true} />
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Thành tiền">
                                                                    <NumberFormat value={item?.amount} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Ngày chốt">
                                                                    {isValidDate(item?.selected_at, false)}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Ghi chú">
                                                                    {HtmlParser(item.note)}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={16} className='customer-service-box'>
                                                        <Col xs={24} md={24}>
                                                            <Collapse
                                                                expandIconPosition="end"
                                                                ghost
                                                                defaultActiveKey={[]}
                                                            >
                                                                <Panel
                                                                    header={<><span className="font-weight-500"><FileImageOutlined /> Ảnh</span></>}
                                                                    key="1"
                                                                >
                                                                    <Row gutter={16}>
                                                                        <Col xs={24} md={24} className='mb-2'>
                                                                            <div className='font-weight-500'>Ảnh before</div>
                                                                            {
                                                                                Array.isArray(item.condition_before) && item.condition_before.length ? (
                                                                                    <Image.PreviewGroup>
                                                                                        <Space wrap>
                                                                                            {
                                                                                                item.condition_before.map((item, index) => {
                                                                                                    return (
                                                                                                        <Image
                                                                                                            key={index}
                                                                                                            height={40}
                                                                                                            width={40}
                                                                                                            style={{ objectFit: 'cover', objectPosition: 'center' }}
                                                                                                            src={item?.src}
                                                                                                        />
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Space>
                                                                                    </Image.PreviewGroup>
                                                                                ) : (
                                                                                    <div style={{ textAlign: 'center', width: '100%' }} className='no-data'>Chưa có dữ liệu</div>
                                                                                )
                                                                            }
                                                                        </Col>
                                                                        <Col xs={24} md={24}>
                                                                            <div className='font-weight-500'>Ảnh after</div>
                                                                            {
                                                                                Array.isArray(item.condition_after) && item.condition_after.length ? (
                                                                                    <Image.PreviewGroup>
                                                                                        <Space wrap>
                                                                                            {
                                                                                                item.condition_after.map((item, index) => {
                                                                                                    return (
                                                                                                        <Image
                                                                                                            key={index}
                                                                                                            height={40}
                                                                                                            width={40}
                                                                                                            style={{ objectFit: 'cover', objectPosition: 'center' }}
                                                                                                            src={item?.src}
                                                                                                        />
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Space>
                                                                                    </Image.PreviewGroup>
                                                                                ) : (
                                                                                    <div style={{ textAlign: 'center', width: '100%' }} className='no-data'>Chưa có dữ liệu</div>
                                                                                )
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </Panel>
                                                            </Collapse>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={16} className='customer-service-box'>
                                                        <Col xs={24} md={24}>
                                                            <Collapse
                                                                expandIconPosition="end"
                                                                ghost
                                                                defaultActiveKey={[]}
                                                            >
                                                                <Panel
                                                                    header={<><span className="font-weight-500"><FolderOutlined /> Lần khám</span></>}
                                                                    key="1"
                                                                >
                                                                    {
                                                                        item?.examination_visit && item?.examination_visit.length ? (
                                                                            <>
                                                                                <Row gutter={16}>
                                                                                    {item.examination_visit.map((item2, index) => {
                                                                                        return (
                                                                                            <Col key={index} xs={24} md={24} className='mb-2'>
                                                                                                <Card size="small" className='card-service-detail'>
                                                                                                    <Row gutter={16}>
                                                                                                        <Col xs={24} md={24}>
                                                                                                            <Descriptions size="small">
                                                                                                                <Descriptions.Item label="Thời điểm">
                                                                                                                    {isValidDate(item2.time)}
                                                                                                                </Descriptions.Item>
                                                                                                            </Descriptions>
                                                                                                        </Col>
                                                                                                        <Col xs={24} md={24}>
                                                                                                            <Descriptions size="small">
                                                                                                                <Descriptions.Item label="Bác sĩ">
                                                                                                                    {item2.doctor_charge_id}
                                                                                                                </Descriptions.Item>
                                                                                                            </Descriptions>
                                                                                                        </Col>
                                                                                                        <Col xs={24} md={24}>
                                                                                                            <Descriptions size="small">
                                                                                                                <Descriptions.Item label="Phụ tá">
                                                                                                                    {item2.assistant_doctor_id}
                                                                                                                </Descriptions.Item>
                                                                                                            </Descriptions>
                                                                                                        </Col>
                                                                                                        <Col xs={24} md={24}>
                                                                                                            <div className="font-weight-500">Kết quả khám:</div>
                                                                                                            {HtmlParser(item.note)}
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </Card>
                                                                                            </Col>
                                                                                        )
                                                                                    })}
                                                                                </Row>
                                                                            </>
                                                                        ) : (
                                                                            <Row gutter={16}><div style={{ textAlign: 'center', width: '100%' }} className='no-data'>Chưa có dữ liệu</div></Row>
                                                                        )
                                                                    }
                                                                </Panel>
                                                            </Collapse>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={16} className='customer-service-box no-border'>
                                                        <Col xs={24} md={24}>
                                                            <Collapse
                                                                expandIconPosition="end"
                                                                ghost
                                                                defaultActiveKey={[]}
                                                            >
                                                                <Panel
                                                                    header={<><span className="font-weight-500"><UserOutlined /> Nhân viên vấn</span></>}
                                                                    key="1"
                                                                >
                                                                    {
                                                                        item?.consultant_directly && item?.consultant_directly.length ? (
                                                                            <>
                                                                                <Row gutter={16}>
                                                                                    {
                                                                                        item?.consultant_directly.map((item2, index) => {
                                                                                            return (
                                                                                                <Col key={index} xs={24} md={24} className='mb-2'>
                                                                                                    <Card size="small" className='card-service-detail'>
                                                                                                        <Col xs={24} md={24}>
                                                                                                            <Descriptions size="small">
                                                                                                                <Descriptions.Item label="Nhân viên">
                                                                                                                    {item2.consultant_user}
                                                                                                                </Descriptions.Item>
                                                                                                            </Descriptions>
                                                                                                        </Col>
                                                                                                        <Col xs={24} md={24}>
                                                                                                            <Descriptions size="small">
                                                                                                                <Descriptions.Item label="Thời điểm">
                                                                                                                    {isValidDate(item2.consultant_date)}
                                                                                                                </Descriptions.Item>
                                                                                                            </Descriptions>
                                                                                                        </Col>
                                                                                                        <Col xs={24} md={24}>
                                                                                                            <div className="font-weight-500">Kết quả tư vấn:</div>
                                                                                                            {HtmlParser(item2.note)}
                                                                                                        </Col>
                                                                                                    </Card>
                                                                                                </Col>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Row>
                                                                            </>) : (
                                                                            <Row gutter={16}><div style={{ textAlign: 'center', width: '100%' }} className='no-data'>Chưa có dữ liệu</div></Row>
                                                                        )
                                                                    }
                                                                </Panel>
                                                            </Collapse>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        ) : (
                            <NoData />
                        )
                    }
                </>
                <CustomerServiceAddForm
                    visible={isOpenAddForm}
                    currentData={currentData}
                    currentCustomer={currentCustomer}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.setState({ isOpenAddForm: false })}
                />
                <CustomerServiceEditForm
                    visible={isOpenEditForm}
                    currentData={currentData}
                    currentCustomer={currentCustomer}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <CustomerServicesDetail
                    visible={isOpenDetail}
                    currentData={currentData}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
            </div >
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser,
        branchActiveList: state.BranchReducer.branchActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        removeService: (ids) => dispatch(removeService(ids)),
        getSpecificService: (id) => dispatch(getSpecificService(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerServices);

