import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Space, Row, Col, DatePicker, Drawer, Table, Tag, Alert } from 'antd';
import { UserOutlined } from "@ant-design/icons";
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../components/Elements/BaseSelect';
import BaseRadios from '../../components/Elements/BaseRadios';
import moment from 'moment';
import {
    _newLine, CustomerScheduleType, CustomerScheduleStatus,
    CustomerScheduleTeleStatus, isValidDateForm, ConvertCustomerScheduleStatus,
    isValidDate, ConvertCustomerScheduleType
} from '../../utils/helpers';

// actions
import { createCustomerSchedule, updateCustomerSchedule } from '../../redux/actions/CustomerScheduleActions';

class CustomerScheduleForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            customerQuery: '',
            require_time: true,
            show_doctor: false,
            switch_status: false,
            show_time_to: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.name) {
                if (nextProps?.currentItem?.name == 'CHUADATDUOCLICH' ||
                    nextProps?.currentItem?.name == 'CHUAXACDINHLICH' ||
                    nextProps?.currentItem?.name == 'KHAC') {
                    this.setState({ require_time: false })
                    this.props.form.setFieldsValue({ time: null })
                } else {
                    this.setState({ require_time: true })
                }
                if (nextProps?.currentItem?.name == 'LAMDICHVU' || nextProps?.currentItem?.name == 'TAIKHAM') {
                    this.setState({ show_doctor: true })
                } else {
                    this.setState({ show_doctor: false })
                }
                if (nextProps?.currentItem?.name == 'GOILAI') {
                    this.setState({ switch_status: true })
                } else {
                    this.setState({ switch_status: false })
                }
                if (nextProps?.currentItem?.status == 'dagoi' || nextProps?.currentItem?.status == 'daden') {
                    this.setState({ show_time_to: true })
                } else {
                    this.setState({ show_time_to: false })
                }
            }
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const { currentCustomer } = this.props;
                    values.time = values.time ? values.time.format("YYYY-MM-DD HH:mm") : null;
                    values.time_to = values.time_to ? values.time_to.format("YYYY-MM-DD HH:mm") : null;
                    console.log(values)
                    if (this.props.currentItem) {
                        await this.props.updateCustomerSchedule(this.props.currentItem.id, values);
                        this.onClose();
                    } else {
                        values.customer_id = currentCustomer.id;
                        await this.props.createCustomerSchedule(values);
                        this.onClose();
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.form.resetFields();
        this.props.reloadData();
        this.props.onCancel();
        this.setState({
            isLoading: false,
            show_doctor: false,
            require_time: true,
            switch_status: false,
            show_time_to: false
        });
    }

    changeName(value) {
        this.setState({
            require_time: value !== 'CHUADATDUOCLICH' && value !== 'CHUAXACDINHLICH',
            show_doctor: value === 'LAMDICHVU' || value === 'TAIKHAM',
            switch_status: value === 'GOILAI'
        }, () => {
            const { switch_status } = this.state;
            this.setState({ show_time_to: false })
            this.props.form.setFieldsValue({ status: switch_status ? 'chuagoi' : 'chuaden' });
        });

        if (value === 'CHUADATDUOCLICH' || value === 'CHUAXACDINHLICH') {
            this.props.form.setFieldsValue({ time: null });
        }
    }

    onChangeStatus(value) {
        this.setState({
            show_time_to: value === 'dagoi' || value === 'daden'
        })
    }

    render() {
        var { isLoading, require_time, show_doctor, switch_status, show_time_to } = this.state;
        var { visible, currentItem, doctors, currentCustomer } = this.props;
        const { getFieldDecorator } = this.props.form;
        //console.log(currentCustomer)

        const columns = [
            {
                title: 'Kiểu lịch hẹn',
                dataIndex: 'name',
                key: 'name',
                render: (text, record) => {
                    return (
                        <strong>
                            {ConvertCustomerScheduleType(record.name)}
                        </strong>
                    )
                }
            },
            {
                title: 'Thời gian',
                dataIndex: 'time',
                key: 'time',
                width: '140px',
                render: (text, record) => {
                    return (
                        <div>
                            {isValidDate(text)} &nbsp;
                        </div>
                    )
                }
            },
            {
                title: '#',
                width: '100px',
                render: (text, record) => {
                    var diffDate = record.time ? moment(record.time, "YYYY-MM-DD").diff(moment().startOf('day'), 'days') : 0;
                    return (
                        <div>
                            {
                                diffDate ? (
                                    <span>
                                        {
                                            diffDate > 0 ? <Tag color="#f50">Còn lại {diffDate} ngày</Tag> : <Tag color="red">Cách đây {-1 * diffDate} ngày</Tag>
                                        }
                                    </span>
                                ) : (
                                    <>
                                        {
                                            record.time != null ? (
                                                <Tag color="#096dd9">Hôm nay</Tag>
                                            ) : null
                                        }
                                    </>
                                )
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                width: '100px',
                render: (text, record) => {
                    return (
                        <div>{ConvertCustomerScheduleStatus(text)}</div>
                    )
                }
            }
        ];

        return (
            <Drawer
                visible={visible}
                title={`${currentItem ? "Sửa lịch hẹn" : "Tạo lịch hẹn"}`}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                Cập nhật
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <h6><UserOutlined /> Khách hàng:&nbsp;
                                    <span className='color-primary'>
                                        {(currentCustomer?.full_name || currentItem?.customer_name) + ' - ' + (currentCustomer?.code || currentItem?.customer_code)}
                                    </span>
                                </h6>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <Alert
                                    message="Chú ý chọn đúng kiểu lịch hẹn"
                                    description={<>
                                        <p>- Lịch <strong>Làm dịch vụ:</strong> Khách vẫn đang trong quá trình thực hiện, chưa hoàn thành dịch vụ tại nha khoa.</p>
                                        <p>- Lịch <strong>Tái khám:</strong> Khách đã hoàn thành quá trình làm dịch vụ, hẹn tới thăm khám lại, kiểm tra định kỳ</p>
                                    </>}
                                    type="warning"
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Table
                                    size='small'
                                    rowKey='key'
                                    tableLayout="auto"
                                    columns={columns}
                                    dataSource={currentCustomer?.appointment_schedule || currentItem?.appointment_schedule || []}
                                    pagination={false}
                                    loading={isLoading}
                                    scroll={isMobile ? { x: 'max-content' } : undefined}
                                    title={() => {
                                        return (
                                            <strong style={{ marginLeft: '-8px' }}>Lịch sử cuộc hẹn <i style={{ fontWeight: 'normal' }}>(Hiển thị lịch sử lịch hẹn của khách)</i></strong>
                                        )
                                    }}
                                    className='mb-4'
                                    locale={{ emptyText: "Chưa có dữ liệu" }}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label='Kiểu lịch hẹn'>
                                    {getFieldDecorator("name", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.name : null
                                    })(
                                        <BaseSelect
                                            options={CustomerScheduleType}
                                            showSearch
                                            placeholder="Chọn một bản ghi"
                                            style={{ width: '100%' }}
                                            onChange={(value) => this.changeName(value)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                require_time ? (
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Thời gian">
                                            {getFieldDecorator('time', {
                                                rules: [
                                                    {
                                                        required: require_time,
                                                        message: 'Vui lòng điền!',
                                                    },
                                                ],
                                                initialValue: currentItem ? isValidDateForm(currentItem.time) : ""
                                            })(
                                                <DatePicker
                                                    style={{ width: '100%' }}
                                                    showTime={{ format: 'HH:mm' }}
                                                    format="DD/MM/YYYY HH:mm"
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                ) : null
                            }
                            {
                                show_doctor ? (
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Bác sĩ thực hiện">
                                            {getFieldDecorator('doctor_ids', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng chọn!',
                                                    },
                                                ],
                                                initialValue: currentItem ? currentItem.doctor_ids : []
                                            })(
                                                <BaseSelect
                                                    options={doctors ?? []}
                                                    optionValue="user_id"
                                                    optionLabel="full_name"
                                                    placeholder="Chọn 1 hoặc nhiều bác sĩ"
                                                    defaultText="Chọn bác sĩ thực hiện"
                                                    showSearch={true}
                                                    mode="multiple"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                ) : null
                            }
                            <Col xs={24} md={24}>
                                <Form.Item label="Trạng thái">
                                    {getFieldDecorator('status', {
                                        rules: [
                                            {
                                                required: true,
                                                message: 'Vui lòng chọn trạng thái!',
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.status : (switch_status ? 'chuagoi' : 'chuaden')
                                    })(
                                        <BaseRadios
                                            onChange={(value) => this.onChangeStatus(value)}
                                            options={switch_status ? CustomerScheduleTeleStatus : CustomerScheduleStatus}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                show_time_to ? (
                                    <Col xs={24} md={24}>
                                        <Form.Item label={`Thời điểm ${switch_status ? 'gọi' : 'đến'}`}>
                                            {getFieldDecorator('time_to', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'Vui lòng điền!',
                                                    },
                                                ],
                                                initialValue: currentItem ? isValidDateForm(currentItem.time_to, false) : null
                                            })(
                                                <DatePicker
                                                    style={{ width: '100%' }}
                                                    showTime={{ format: 'HH:mm' }}
                                                    format="DD/MM/YYYY HH:mm"
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                ) : null
                            }
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? _newLine(currentItem.note) : ""
                                    })(
                                        <Input.TextArea rows={4} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        doctors: state.config.doctors
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createCustomerSchedule: (data) => dispatch(createCustomerSchedule(data)),
        updateCustomerSchedule: (id, data) => dispatch(updateCustomerSchedule(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CustomerScheduleForm' })(CustomerScheduleForm));
