import React, { Component } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Modal } from 'antd';
import { getBase64 } from '../../../utils/helpers';
import imageCompression from 'browser-image-compression';

const compressOptions = {
    maxSizeMB: 0.2,
    maxWidthOrHeight: 500,
    useWebWorker: true
}

class UploadImage extends Component {
    state = {
        previewVisible: false,
        previewImage: '',
        fileList: []
    };

    static defaultProps = {
        onChangeData: () => { },
        multiple: false,
        maxCount: 1,
        uploadText: 'Chọn ảnh',
        accept: 'image/*'
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isResetUpload) {
            this.setState({ fileList: [] })
        }
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    async setData(list) {
        let listData = [];
        if (list.length) {
            for (let i = 0; i < list.length; i++) {
                let data = await getBase64(list[i].originFileObj);
                let compressProcesses = await imageCompression(list[i].originFileObj, compressOptions);
                let src_small = await getBase64(compressProcesses);
                listData.push({
                    id: i,
                    src: data,
                    image_name: list[i].originFileObj.name.replace(/\.[^/.]+$/, ""),
                    src_small: src_small
                })
            }
        }
        return listData;
    }

    handleChange = async ({ fileList }) => {
        this.setState({ fileList }, async () => {
            let data = await this.setData(this.state.fileList)
            this.props.onChangeData(data);
        });
    }

    render() {
        const { previewVisible, previewImage, fileList } = this.state;
        const { multiple, maxCount, uploadText, accept } = this.props;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div className="ant-upload-text">{uploadText}</div>
            </div>
        );

        return (
            <div className="clearfix">
                <Upload
                    beforeUpload={() => {
                        return false;
                    }}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    onDownload={false}
                    accept={accept}
                    multiple={multiple}
                    maxCount={maxCount}
                >
                    {uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}

export default UploadImage;