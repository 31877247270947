import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Divider, Button, Row, Col, Table, Spin, Typography, Image, Space, Tooltip } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import NumberFormat from 'react-number-format';
import ReactHtmlParser from 'react-html-parser';
import { isMobile } from 'react-device-detect';
import ProductReceiptExportEditForm from './ProductReceiptExportEditForm';
import ProductDetail from './../product/ProductDetail';
import { ConvertProductReceiptExportType, isValidDate } from '../../utils/helpers';
import PrintButton from '../../components/PrintButton/PrintButton';
import PrintTable from './PrintTable';

// actions
import { getSpecificProductReceiptExport } from '../../redux/actions/ProductReceiptExportActions';
import { getSpecificProduct } from '../../redux/actions/ProductActions';

const { Text } = Typography;
class ProductReceiptExportDetail extends Component {
    constructor() {
        super();
        this.state = {
            isOpenEditForm: false,
            currentItemEdit: null,
            isOpenProductDetail: false,
            currentProduct: null,
            currentProductId: null
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenEditForm(isOpenEditForm, currentItemEdit = null) {
        this.setState({ isOpenEditForm: isOpenEditForm, currentItemEdit: currentItemEdit });
    }

    onEdit(id) {
        this.props.getSpecificProductReceiptExport(id).then(res => {
            this.toggleOpenEditForm(true, res);
        })
    }

    toggleOpenProductDetail(isOpenProductDetail, data = null) {
        this.setState({ isOpenProductDetail: isOpenProductDetail, currentProduct: data });
    }

    onProductDetail(id) {
        this.setState({ currentProductId: id })
        this.props.getSpecificProduct(id).then(res => {
            this.toggleOpenProductDetail(true, res);
        })
    }

    render() {
        var { isOpenEditForm, isLoading, currentItemEdit, isOpenProductDetail, currentProduct, currentProductId } = this.state;
        var { visible, currentItem, loadingDetail } = this.props;

        var columns = [
            {
                title: "STT",
                dataIndex: "stt",
                width: "40px",
                align: 'center',
                render: (text, record, index) => index + 1
            },
            {
                title: "ID",
                dataIndex: "product_id",
                width: "40px",
                align: 'center'
            },
            {
                title: "Tên sản phẩm",
                dataIndex: "name",
                //width: "180px",
                render: (text, record) => {
                    if (record.name) {
                        if (checkPermission('product', 'detail')) {
                            return (
                                <Tooltip title="Xem chi tiết sản phẩm" placement="right">
                                    <div className='alink item-action-btn' onClick={() => this.onProductDetail(record.product_id)}>
                                        {record.name}
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>{record.name}</>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: "Kho",
                dataIndex: "warehouse_name",
                width: "120px"
            },
            {
                title: "Giá bán",
                dataIndex: "sale_price",
                width: "100px",
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                    )
                }
            },
            {
                title: 'SL xuất',
                dataIndex: "quantity",
                width: "80px"
            },
            {
                title: 'SL tồn',
                dataIndex: "quantity_in_stock",
                width: "80px",
                render: (text, record) => {
                    return (
                        <>
                            {record.quantity_in_stock}
                        </>
                    )
                }
            },
            {
                title: "Thành tiền",
                dataIndex: "amount",
                width: "100px",
                render: (text, record) => {
                    return (
                        <NumberFormat value={text} displayType={'text'} suffix="đ" thousandSeparator={true} />
                    )
                }
            },
            {
                title: "Đơn vị tính",
                dataIndex: "unit",
                width: "90px"
            },
            {
                title: "Tình trạng",
                dataIndex: "product_status_name",
                width: "100px"
            },
            {
                title: "Hạn sử dụng",
                dataIndex: "expiry_date",
                width: "110px",
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text, false)}</div>
                    )
                }
            }
        ];

        return (
            <>
                <Drawer
                    visible={visible}
                    title={`Chi tiết phiếu: ${currentItem?.name + " - " + currentItem?.code}`}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '80%'}
                    extra={
                        <Space style={{ flexWrap: 'nowrap' }}>
                            {
                                checkPermission('product_receipt_export', 'update') ? (
                                    <Button type="primary" onClick={() => this.onEdit(currentItem?.id)} icon={<EditOutlined />}>Sửa</Button>
                                ) : null
                            }
                            <PrintButton>
                                <PrintTable tableData={currentItem ? currentItem.items : []} />
                            </PrintButton>
                        </Space>
                    }
                >
                    {visible ? (
                        <div>
                            <Spin tip="Loading..." spinning={loadingDetail}>
                                <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                        <Divider orientation="left" orientationMargin="0">
                                            Thông tin sản phẩm
                                        </Divider>
                                        <Row gutter={16}>
                                            <Col xs={24} md={24}>
                                                <Table
                                                    className='mt-2'
                                                    size='small'
                                                    rowKey='key'
                                                    tableLayout="auto"
                                                    columns={columns}
                                                    dataSource={currentItem?.items || []}
                                                    pagination={false}
                                                    loading={isLoading}
                                                    scroll={{
                                                        x: 'max-content'
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row justify="end" gutter={16} className='mt-4'>
                                            <Col xs={24} md={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <h5>Tổng tiền: <NumberFormat value={currentItem ? currentItem.total_amount : 0} displayType={'text'} suffix="đ" thousandSeparator={true} /></h5>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Divider orientation="left" orientationMargin="0">
                                            Thông tin phiếu
                                        </Divider>
                                        <div className='box-info'>
                                            <Row gutter={16}>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Tên phiếu">
                                                            {currentItem ? currentItem.name : null}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className="no-margin" />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Mã phiếu">
                                                            {currentItem ? currentItem.code : null}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className="no-margin" />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Loại phiếu">
                                                            {ConvertProductReceiptExportType(currentItem?.type)}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className="no-margin" />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Tổng tiền">
                                                            <NumberFormat value={currentItem ? currentItem.total_amount : null} displayType={'text'} suffix="đ" thousandSeparator={true} />
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className="no-margin" />
                                                </Col>
                                                {currentItem && currentItem.type == 'ban' || currentItem.type == 'xuatchokhach' ? (
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Khách hàng">
                                                                {currentItem ? currentItem.customer_name : null}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                ) : null}
                                                {
                                                    currentItem && currentItem.type == 'chuyenkho' ? (
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Chuyển kho">
                                                                    {currentItem ? currentItem.warehouse_from_name : null}
                                                                    &nbsp;đến&nbsp;
                                                                    {currentItem ? currentItem.warehouse_to_name : null}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className="no-margin" />
                                                        </Col>
                                                    ) : null
                                                }
                                                {currentItem && currentItem.type == 'cososudung' ? (
                                                    <Col xs={24} md={24}>
                                                        <Descriptions size="small">
                                                            <Descriptions.Item label="Cơ sở sử dụng">
                                                                {currentItem ? currentItem.branch_name : null}
                                                            </Descriptions.Item>
                                                        </Descriptions>
                                                        <Divider className="no-margin" />
                                                    </Col>
                                                ) : null}

                                                <Col xs={24} md={24}>
                                                    <div><strong>Ghi chú:</strong></div>
                                                    {currentItem ? ReactHtmlParser(currentItem.note) : null}
                                                    <Divider className="no-margin" />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Ngày xuất">
                                                            {isValidDate(currentItem.export_date, false)}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className="no-margin" />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Ngày lập phiếu">
                                                            {isValidDate(currentItem.created_at)}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className="no-margin" />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <Descriptions size="small">
                                                        <Descriptions.Item label="Người tạo">
                                                            {currentItem ? currentItem.created_user : null}
                                                        </Descriptions.Item>
                                                    </Descriptions>
                                                    <Divider className="no-margin" />
                                                </Col>
                                                <Col xs={24} md={24}>
                                                    <div className='mb-1 mt2'>
                                                        <Text strong>Ảnh phiếu</Text>
                                                    </div>
                                                    <Image.PreviewGroup>
                                                        <Space wrap>
                                                            {
                                                                currentItem?.images.map(item => {
                                                                    return (
                                                                        <Image
                                                                            width={50}
                                                                            height={50}
                                                                            src={item.src}
                                                                            style={{ border: '1px solid #dedede' }}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </Space>
                                                    </Image.PreviewGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </Spin>
                        </div>
                    ) : null}

                </Drawer >
                <ProductReceiptExportEditForm
                    visible={isOpenEditForm}
                    currentItem={currentItemEdit}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenEditForm(false)}
                />

                <ProductDetail
                    visible={isOpenProductDetail}
                    currentItem={currentProduct}
                    reloadData={() => this.onProductDetail(currentProductId)}
                    onCancel={() => this.toggleOpenProductDetail(false)}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSpecificProductReceiptExport: (id) => dispatch(getSpecificProductReceiptExport(id)),
        getSpecificProduct: (id) => dispatch(getSpecificProduct(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ProductReceiptExportDetail' })(ProductReceiptExportDetail));
