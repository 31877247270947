import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Descriptions, Divider, Tag, Button, Row, Col, List, Modal, Card } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined, MedicineBoxOutlined } from '@ant-design/icons';
import { checkPermission } from '../../utils/permission';
import { isValidDate } from '../../utils/helpers';
import ServicesInterestedForm from '../customer-services/ServicesInterestedForm';
import HtmlParser from 'react-html-parser';
// actions
import { getSpecificService, removeService } from '../../redux/actions/CustomerServicesActions';

const { confirm } = Modal;

class ServicesInterestedList extends Component {
    constructor() {
        super();
        this.state = {
            isOpenForm: false,
            currentData: null
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    toggleOpenForm(isOpenForm, currentData = null) {
        this.setState({
            isOpenForm: isOpenForm,
            currentData: currentData,
            currentCustomer: this.props.currentItem
        });
    }

    onEdit(id) {
        this.props.getSpecificService(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    openRemove(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeService(id).then(() => {
                setTimeout(() => {
                    this.props.reloadData();
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    render() {
        var { currentData,
            isOpenForm,
            currentCustomer } = this.state;
        var { currentItem } = this.props;

        return (
            <div>
                {
                    checkPermission('customer_services', 'list') ? (
                        <>
                            <Divider orientation="left" orientationMargin="0" className='mt-4 mb-0'>
                                <Tag color='#0b2b33'>4</Tag> Dịch vụ quan tâm
                            </Divider>
                            <Row justify="end" className='mb-1'>
                                <Button disabled={!checkPermission('customer_services', 'create')} type="dashed" className='color-blue' size='small' onClick={() => this.toggleOpenForm(true)} icon={<PlusOutlined />}>Thêm mới</Button>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={24} md={24}>
                                    <List
                                        className='list-in-detail-customer dv-quan-tam'
                                        itemLayout="vertical"
                                        rowKey='id'
                                        size="small"
                                        locale={{ emptyText: "Chưa có dữ liệu" }}
                                        dataSource={currentItem.services_interested || []}
                                        renderItem={(item, index) => {
                                            const IconAction = [];
                                            if (checkPermission('customer_services', 'update')) {
                                                IconAction.push(<a onClick={() => this.onEdit(item.id)} type='link' key="list-vertical-edit"><EditOutlined /> Sửa</a>)
                                            }
                                            if (checkPermission('customer_services', 'remove')) {
                                                IconAction.push(<span className='delete-icon' onClick={() => this.openRemove([item.id])} type='link' key="list-vertical-delete"><DeleteOutlined /> Xóa</span>)
                                            }

                                            return (
                                                <List.Item
                                                    key={index}
                                                    actions={IconAction}
                                                    className='mb-3'
                                                    locale={{ emptyText: "Chưa có dữ liệu" }}
                                                >
                                                    <List.Item.Meta
                                                        title={<><MedicineBoxOutlined /> {item.service_name}</>}
                                                        className='mb-0'
                                                    />
                                                    <Row gutter={16}>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Tình trạng hiện tại">
                                                                    {item.current_status}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Ghi chú">
                                                                    {HtmlParser(item.note)}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        item?.consultant && item?.consultant.length ? (
                                                            <>
                                                                <h6 className='h6-title'>Nhân viên tư vấn online:</h6>
                                                                <Row gutter={16}>
                                                                    {
                                                                        item?.consultant.map(item2 => {
                                                                            return (
                                                                                <Col xs={24} md={12} className='mb-2'>
                                                                                    <Card size="small" className='card-service-detail'>
                                                                                        <Col xs={24} md={24}>
                                                                                            <Descriptions size="small">
                                                                                                <Descriptions.Item label="Nhân viên">
                                                                                                    {item2.consultant_user}
                                                                                                </Descriptions.Item>
                                                                                            </Descriptions>
                                                                                        </Col>
                                                                                        <Col xs={24} md={24}>
                                                                                            <Descriptions size="small">
                                                                                                <Descriptions.Item label="Thời điểm">
                                                                                                    {isValidDate(item2.consultant_date)}
                                                                                                </Descriptions.Item>
                                                                                            </Descriptions>
                                                                                        </Col>
                                                                                        <Col xs={24} md={24}>
                                                                                            <h6 className="h6-title">Kết quả tư vấn:</h6>
                                                                                            {HtmlParser(item2.note)}
                                                                                        </Col>
                                                                                    </Card>
                                                                                </Col>
                                                                            )
                                                                        })
                                                                    }
                                                                </Row>
                                                            </>) : null
                                                    }
                                                </List.Item>
                                            )
                                        }}
                                    />
                                </Col>
                            </Row>
                        </>
                    ) : null
                }
                <ServicesInterestedForm
                    visible={isOpenForm}
                    currentItem={currentData}
                    currentCustomer={currentCustomer}
                    reloadData={() => this.props.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </div >
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser,
        branchActiveList: state.BranchReducer.branchActiveList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        removeService: (ids) => dispatch(removeService(ids)),
        getSpecificService: (id) => dispatch(getSpecificService(id))
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(ServicesInterestedList);

// Sử dụng forwardRef để truyền ref xuống component
// Mục đích để cho parent component có thể gọi method của child component
const ConnectedCustomerServicesInterestedList = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(ServicesInterestedList);

export default React.forwardRef((props, ref) => (
    <ConnectedCustomerServicesInterestedList {...props} ref={ref} />
));