import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Divider, Tag, Row, Col, Card, Typography, Button, Descriptions, Modal, Spin, Tabs, Input } from 'antd'
import { getListCustomerSchedule, getSpecificCustomerSchedule, removeCustomerSchedule } from '../../../redux/actions/CustomerScheduleActions';
import { ClockCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { checkPermission } from '../../../utils/permission';
import { ConvertCustomerScheduleStatus, ConvertCustomerScheduleType, isValidDate, cleanObject } from '../../../utils/helpers';
import moment from 'moment';
import _, { } from 'lodash';
import qs from 'qs';
import UpdateStatusForm from './UpdateStatusForm';
import ScheduleForm from './ScheduleForm';
import NoData from '../NoData';
import ScheduleDetail from './ScheduleDetail';

const { Paragraph } = Typography;
const { confirm } = Modal;
const { Search } = Input;

class Appointment extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isOpenForm: false,
            currentItem: null,
            isOpenStatusForm: false,
            isOpenDetail: false,
            activeTab: "1",
            schedule_list: [],
            pagination: {
                currentPage: 1,
                total: 0,
                perPage: 30
            }
        };
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.loadData(query);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.loadData(query);
        }
    }

    loadData(query) {
        this.setState({ isLoading: true });
        this.props.getListCustomerSchedule({ per_page: 70, ...query }).then((res) => {
            this.setState({
                schedule_list: res.data.data,
                pagination: {
                    currentPage: parseInt(res.data.current_page),
                    total: parseInt(res.data.total),
                    perPage: parseInt(res.data.per_page),
                },
                isLoading: false
            });
        })
    }
    onCancel() {
        this.setState({ isOpenForm: false, currentItem: null, isOpenStatusForm: false })
    }

    openRemove(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeCustomerSchedule(id).then(() => {
                setTimeout(() => {
                    this.loadData();
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    onEdit(id) {
        this.props.getSpecificCustomerSchedule(id).then(res => {
            this.setState({
                currentItem: res,
                isOpenForm: true
            })
        })
    }

    toggleOpenUpdateStatusForm(id, isOpenStatusForm) {
        this.props.getSpecificCustomerSchedule(id).then(res => {
            this.setState({ isOpenStatusForm: isOpenStatusForm, currentItem: res });
        })
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getSpecificCustomerSchedule(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentItem: data });
    }

    onChangeTab(name, activeTab) {
        this.setState({ activeTab });
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: activeTab
        }
        query = cleanObject(query);
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    render() {
        var { schedule_list, currentItem, isOpenForm, isOpenStatusForm, isLoading, isOpenDetail, activeTab } = this.state;
        var query = qs.parse(this.props.location.search.slice(1));

        return (
            <>
                <Row gutter={16}>
                    <Col xs={24} md={24} className='box-item-mobile box-item-mobile-header'>
                        <Card size="small"
                            bordered={false}
                            headStyle={{ border: 0 }}
                        >
                            <Tabs
                                activeKey={query?.tab || activeTab}
                                onChange={(activeTab) => this.onChangeTab('tab', activeTab)}
                                size='small'
                                className='tabs-custom-children'
                                centered
                            >
                                <Tabs.TabPane key="1" tab={<>Hôm nay</>} forceRender></Tabs.TabPane>
                                <Tabs.TabPane key="2" tab={<>Ngày mai</>} forceRender></Tabs.TabPane>
                                <Tabs.TabPane key="6" tab={<>Chưa đến</>} forceRender></Tabs.TabPane>
                            </Tabs>

                            <Search
                                name="keyword"
                                className="txtSearch"
                                placeholder='Mã, tên, 3 số cuối sđt của khách hàng'
                                onSearch={(value) => this.onChangeTab('keyword', value)}
                                enterButton={<SearchOutlined />}
                                style={{ width: '100%', padding: 8, marginTop: 10 }}
                                defaultValue={query?.keyword}
                                allowClear
                            />
                        </Card>
                    </Col>
                </Row>

                <Spin tip="Loading..." spinning={isLoading}>
                    {
                        schedule_list && schedule_list?.length ? (
                            <Row gutter={16}>
                                {
                                    schedule_list?.map((item, index) => {
                                        var diffDate = item.time ? moment(item.time, "YYYY-MM-DD").diff(moment().startOf('day'), 'days') : 0;
                                        var bgcolor = "#f5b22d";
                                        if (item.status == 'daden' || item.status == 'dagoi') bgcolor = "green";
                                        return (
                                            <Col key={index} xs={24} md={12} lg={8} className='box-item-mobile'>
                                                <Card size="small"
                                                    title={<>{<ClockCircleOutlined />} {ConvertCustomerScheduleType(item.name)}</>}
                                                    extra={
                                                        <>
                                                            {checkPermission('customer_schedule', 'update') ? (
                                                                <a onClick={() => this.onEdit(item.id)} type="link" key="list-vertical-edit">
                                                                    <EditOutlined /> Sửa
                                                                </a>
                                                            ) : null}
                                                            {checkPermission('customer_schedule', 'remove') ? (
                                                                <>
                                                                    <Divider type='vertical' />
                                                                    <span className='delete-icon' onClick={() => this.openRemove([item.id])} type="link" key="list-vertical-delete">
                                                                        <DeleteOutlined /> Xóa
                                                                    </span>
                                                                </>
                                                            ) : null}
                                                            <Divider type='vertical' />
                                                            <span className='item-action-btn' onClick={() => this.onDetail(item.id)}>
                                                                <EyeOutlined /> Xem
                                                            </span>
                                                        </>
                                                    }
                                                >
                                                    <Row gutter={16}>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Khách hàng">
                                                                    {item.customer_name}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Mã khách hàng">
                                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                                        {
                                                                            text: item ? item.customer_code : null,
                                                                            tooltips: false
                                                                        }
                                                                    }>
                                                                        {item.customer_code}
                                                                    </Paragraph>
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Thời gian">
                                                                    {isValidDate(item.time)} -&nbsp;
                                                                    {
                                                                        diffDate ? (
                                                                            <span>
                                                                                {
                                                                                    diffDate > 0 ? <Tag color="#f50">Còn lại {diffDate} ngày</Tag> : <Tag color="red">Cách đây {-1 * diffDate} ngày</Tag>
                                                                                }
                                                                            </span>
                                                                        ) : (
                                                                            <>
                                                                                {
                                                                                    item.time != null ? (
                                                                                        <Tag color="green">Hôm nay</Tag>
                                                                                    ) : null
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Trạng thái">
                                                                    <Button icon={<EditOutlined />} style={{ background: bgcolor, color: '#fff', border: 'none' }} onClick={() => this.toggleOpenUpdateStatusForm(item.id, true)} size='small'>
                                                                        {ConvertCustomerScheduleStatus(item.status, true)}
                                                                    </Button>
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        ) : (
                            <NoData />
                        )
                    }
                </Spin>

                <ScheduleForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.onCancel()}
                />

                <UpdateStatusForm
                    visible={isOpenStatusForm}
                    currentItem={currentItem}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.setState({ isOpenStatusForm: false })}
                />

                <ScheduleDetail
                    visible={isOpenDetail}
                    currentItem={currentItem}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCustomerSchedule: (filter) => dispatch(getListCustomerSchedule(filter)),
        getSpecificCustomerSchedule: (id) => dispatch(getSpecificCustomerSchedule(id)),
        removeCustomerSchedule: (id) => dispatch(removeCustomerSchedule(id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Appointment));
