import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Drawer, Descriptions, Divider, Button, Row, Col, Space, Tag, Typography, Alert, Spin } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { checkPermission } from '../../../utils/permission';
import ReactHtmlParser from 'react-html-parser';
import { isValidDate, renderTelesaleDeadline, renderTelesaleTime, repeatPhoneNumber } from '../../../utils/helpers';
import { isMobile } from 'react-device-detect';
import HandlePhoneNumber from '../../../components/HandlePhoneNumber';
import TelesaleForm from './TelesaleForm';

const { Paragraph } = Typography;

class TelesaleDetail extends Component {

    constructor() {
        super();
        this.state = {
            isOpenEditForm: false
        };
    }

    handleCancel() {
        this.props.onCancel();
    }

    loadData() {
        this.props.reloadList();
        this.props.reloadData();
    }

    render() {
        var { isOpenEditForm } = this.state;
        var { visible, currentItem, loadingDetail } = this.props;

        return (
            <>
                <Drawer
                    visible={visible}
                    title={`Chi tiết lịch telesale`}
                    onClose={() => this.handleCancel()}
                    maskClosable={false}
                    width={isMobile ? '100%' : '50%'}
                    extra={
                        <Space style={{ flexWrap: 'nowrap' }}>
                            {
                                checkPermission('customer_telesale', 'update') ? (
                                    <Button type="primary" onClick={() => this.setState({ isOpenEditForm: true })} icon={<EditOutlined />}>Sửa</Button>
                                ) : null
                            }
                        </Space>
                    }
                >
                    {visible ? (
                        <div className='wrap-customer-detail'>
                            <Spin tip="Loading..." spinning={loadingDetail}>
                                <Row gutter={16}>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Tên khách hàng">
                                                {currentItem ? currentItem.customer_name : null}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className="no-margin" />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Mã khách hàng">
                                                <Paragraph style={{ margin: 0 }} copyable={
                                                    {
                                                        text: currentItem ? currentItem.customer_code : null,
                                                        tooltips: false
                                                    }
                                                }>
                                                    <Tag color='#1890ff'>{currentItem ? currentItem.customer_code : null}</Tag>
                                                </Paragraph>
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className="no-margin" />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Số điện thoại">
                                                {currentItem ? (
                                                    <HandlePhoneNumber
                                                        permission={checkPermission('customer', 'phonenumber')}
                                                        inputPinCode={checkPermission('customer', 'input_pin_code')}
                                                        customer_id={currentItem?.customer_id}
                                                        hiddenPhoneNumber={repeatPhoneNumber(currentItem?.customer_phone)}
                                                        fullPhoneNumber={currentItem?.customer_phone}
                                                    />
                                                ) : null}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className="no-margin" />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Trạng thái khách hàng">
                                                <Tag color={currentItem?.customer_status_color}>{currentItem?.customer_status}</Tag>
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className="no-margin" />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Nguồn khách hàng">
                                                {currentItem ? currentItem.customer_source : null}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className="no-margin" />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Cơ sở">
                                                {currentItem ? currentItem.branch_name : null}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                    <Col xs={24} md={24} className='mt-2 mb-2'>
                                        <Alert
                                            message={<span style={{ fontWeight: '600' }}>Ghi chú khách hàng</span>}
                                            description={currentItem ? ReactHtmlParser(currentItem.customer_note) : null}
                                            type="info"
                                            className='fix-alert-element'
                                        />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Deadline">
                                                {renderTelesaleDeadline(currentItem?.deadline, currentItem?.status, 'detail')}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className="no-margin" />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Trạng thái tư vấn">
                                                {currentItem?.status == 1 ? (
                                                    <Tag color='green'>Đã trả kết quả</Tag>
                                                ) : null}
                                                {currentItem?.status == 0 ? (
                                                    <Tag color='#f5b22d'>Chưa trả kết quả</Tag>
                                                ) : null}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className="no-margin" />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Thời điểm tư vấn">
                                                {renderTelesaleTime(currentItem?.deadline, currentItem?.time_telesale, 'detail')}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className="no-margin" />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Nhân viên tư vấn">
                                                {currentItem ? currentItem.user_telesale : null}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className="no-margin" />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Người trả kết quả">
                                                {currentItem.user_update_result}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                    <Col xs={24} md={24} className='mt-2 mb-2'>
                                        <Alert
                                            message={<span style={{ fontWeight: '600' }}>Kết quả tư vấn</span>}
                                            description={currentItem ? ReactHtmlParser(currentItem.result_content) : null}
                                            type="info"
                                            className='fix-alert-element'
                                        />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Ngày tạo">
                                                {isValidDate(currentItem.created_at)}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Divider className="no-margin" />
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Descriptions size="small">
                                            <Descriptions.Item label="Người tạo">
                                                {currentItem ? currentItem.created_user : null}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Col>
                                </Row>
                            </Spin>
                        </div>
                    ) : null}
                </Drawer>
                <TelesaleForm
                    visible={isOpenEditForm}
                    currentItem={currentItem}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.setState({ isOpenEditForm: false })}
                />
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'TelesaleDetail' })(TelesaleDetail));
