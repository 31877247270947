import api from '../../utils/api';
import {
    GET_LIST_RELATIONSHIP_TYPES,
    GET_SPECIFIC_RELATIONSHIP_TYPES,
    UPDATE_SPECIFIC_RELATIONSHIP_TYPES,
    CREATE_NEW_RELATIONSHIP_TYPES,
    REMOVE_RELATIONSHIP_TYPES,
    GET_RELATIONSHIP_TYPES_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListRelationshipType = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/relationship-types', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_RELATIONSHIP_TYPES, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificRelationshipType = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/relationship-types/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_RELATIONSHIP_TYPES, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getRelationshipTypeActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/relationship-types/by-active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_RELATIONSHIP_TYPES_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createRelationshipType = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/relationship-types', data).then(res => {
            dispatch({ type: CREATE_NEW_RELATIONSHIP_TYPES, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateRelationshipType = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/relationship-types/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_RELATIONSHIP_TYPES, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeRelationshipType = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/relationship-types`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_RELATIONSHIP_TYPES, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
