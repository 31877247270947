import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';
import { Button, Input, Space, Row, Col, DatePicker, Drawer } from 'antd';
import { UserOutlined } from "@ant-design/icons";
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../../components/Elements/BaseSelect';
import moment from 'moment';
import { _newLine, CustomerScheduleType, isValidDateForm } from '../../../utils/helpers';
import { createCustomerSchedule, updateCustomerSchedule } from '../../../redux/actions/CustomerScheduleActions';

class ScheduleForm extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            customerQuery: '',
            require_time: true,
            show_doctor: false,
            switch_status: false,
            show_time_to: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.currentItem != this.props.currentItem) {
            if (nextProps?.currentItem?.name) {
                if (nextProps?.currentItem?.name == 'CHUADATDUOCLICH' ||
                    nextProps?.currentItem?.name == 'CHUAXACDINHLICH' ||
                    nextProps?.currentItem?.name == 'KHAC') {
                    this.setState({ require_time: false })
                    this.props.form.setFieldsValue({ time: null })
                } else {
                    this.setState({ require_time: true })
                }
                if (nextProps?.currentItem?.name == 'LAMDICHVU' || nextProps?.currentItem?.name == 'TAIKHAM') {
                    this.setState({ show_doctor: true })
                } else {
                    this.setState({ show_doctor: false })
                }
                if (nextProps?.currentItem?.name == 'GOILAI') {
                    this.setState({ switch_status: true })
                } else {
                    this.setState({ switch_status: false })
                }
                if (nextProps?.currentItem?.status == 'dagoi' || nextProps?.currentItem?.status == 'daden') {
                    this.setState({ show_time_to: true })
                } else {
                    this.setState({ show_time_to: false })
                }
            }
        }
    }

    async submit(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true });
                try {
                    const { switch_status } = this.state;
                    const { currentCustomer } = this.props;
                    values.time = values.time ? values.time.format("YYYY-MM-DD HH:mm") : null;
                    values.time_to = values.time_to ? values.time_to.format("YYYY-MM-DD HH:mm") : null;

                    console.log(values)
                    if (this.props.currentItem) {
                        await this.props.updateCustomerSchedule(this.props.currentItem.id, values);
                        this.onClose();
                    } else {
                        values.status = switch_status ? 'chuagoi' : 'chuaden';
                        values.customer_id = currentCustomer.id;
                        await this.props.createCustomerSchedule(values);
                        this.onClose();
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
            }
        });
    }

    onClose() {
        this.props.form.resetFields();
        this.props.reloadData();
        this.props.onCancel();
        this.setState({
            isLoading: false,
            show_doctor: false,
            require_time: true,
            switch_status: false,
            show_time_to: false
        });
    }

    changeName(value) {
        this.setState({
            require_time: value !== 'CHUADATDUOCLICH' && value !== 'CHUAXACDINHLICH',
            show_doctor: value === 'LAMDICHVU' || value === 'TAIKHAM',
            switch_status: value === 'GOILAI'
        }, () => {
            const { switch_status } = this.state;
            this.setState({ show_time_to: false })
            this.props.form.setFieldsValue({ status: switch_status ? 'chuagoi' : 'chuaden' });
        });

        if (value === 'CHUADATDUOCLICH' || value === 'CHUAXACDINHLICH') {
            this.props.form.setFieldsValue({ time: null });
        }
    }

    onChangeStatus(value) {
        this.setState({
            show_time_to: value === 'dagoi' || value === 'daden'
        })
    }

    render() {
        var { isLoading, require_time, show_doctor } = this.state;
        var { visible, currentItem, doctors, currentCustomer } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <Drawer
                visible={visible}
                title={`${currentItem ? "Sửa lịch hẹn" : "Tạo lịch hẹn"}`}
                onClose={() => this.onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '50%'}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => this.onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                loading={isLoading}
                                key="submit"
                                type="primary"
                                onClick={(e) => this.submit(e)}
                            >
                                Lưu
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <h6><UserOutlined />
                                    <span className='color-primary'>
                                        {(currentCustomer?.full_name || currentItem?.customer_name) + ' - ' + (currentCustomer?.code || currentItem?.customer_code)}
                                    </span>
                                </h6>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={12}>
                                <Form.Item label='Kiểu lịch hẹn'>
                                    {getFieldDecorator("name", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem.name : null
                                    })(
                                        <BaseSelect
                                            options={CustomerScheduleType}
                                            showSearch
                                            placeholder="Chọn một bản ghi"
                                            style={{ width: '100%' }}
                                            onChange={(value) => this.changeName(value)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            {
                                require_time ? (
                                    <Col xs={24} md={12}>
                                        <Form.Item label="Thời gian">
                                            {getFieldDecorator('time', {
                                                rules: [
                                                    {
                                                        required: require_time,
                                                        message: 'Vui lòng điền!',
                                                    },
                                                ],
                                                initialValue: currentItem ? isValidDateForm(currentItem.time) : moment()
                                            })(
                                                <DatePicker
                                                    style={{ width: '100%' }}
                                                    showTime={{ format: 'HH:mm' }}
                                                    format="DD/MM/YYYY HH:mm"
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                ) : null
                            }
                            {
                                show_doctor ? (
                                    <Col xs={24} md={24}>
                                        <Form.Item label="Bác sĩ thực hiện">
                                            {getFieldDecorator('doctor_ids', {
                                                rules: [
                                                    {
                                                        required: false,
                                                        message: 'Vui lòng chọn!',
                                                    },
                                                ],
                                                initialValue: currentItem ? currentItem.doctor_ids : []
                                            })(
                                                <BaseSelect
                                                    options={doctors ?? []}
                                                    optionValue="user_id"
                                                    optionLabel="full_name"
                                                    placeholder="Chọn 1 hoặc nhiều bác sĩ"
                                                    defaultText="Chọn bác sĩ thực hiện"
                                                    showSearch={true}
                                                    mode="multiple"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                ) : null
                            }
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Ghi chú">
                                    {getFieldDecorator('note', {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Vui lòng điền!",
                                            },
                                        ],
                                        initialValue: currentItem ? _newLine(currentItem.note) : ""
                                    })(
                                        <Input.TextArea rows={4} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    : null}
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        doctors: state.config.doctors
    }
}

function mapDispatchToProps(dispatch) {
    return {
        createCustomerSchedule: (data) => dispatch(createCustomerSchedule(data)),
        updateCustomerSchedule: (id, data) => dispatch(updateCustomerSchedule(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'ScheduleForm' })(ScheduleForm));
