import {
    GET_LIST_MEDIA_CATEGORIES,
    REMOVE_MEDIA_CATEGORIES,
    GET_MEDIA_CATEGORIES_ACTIVE
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    media_categories: [],
    media_categories_active: [],
    pagination: {
        currentPage: 1,
        total: 0,
        perPage: 30
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_MEDIA_CATEGORIES: {
            return {
                ...state,
                media_categories: action.payload.data,
                pagination: {
                    currentPage: parseInt(action.payload.current_page),
                    total: parseInt(action.payload.total),
                    perPage: parseInt(action.payload.per_page),
                }
            }
        }
        case GET_MEDIA_CATEGORIES_ACTIVE: {
            return {
                ...state,
                media_categories_active: action.payload.data,
            }
        }

        case REMOVE_MEDIA_CATEGORIES: {
            let temp = state.media_categories.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });

            let temp2 = state.media_categories_active.filter(item => {
                return action.payload.indexOf(item.id) < 0;
            });
            return { ...state, media_categories: temp, media_categories_active: temp2 }
        }
        default: return { ...state };
    }
}
