import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Table, Modal, Tooltip, Divider } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _ from 'lodash';
import qs from 'qs';
import TextTruncate from 'react-text-truncate';
import MediaCategoriesForm from './MediaCategoriesForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, isValidDate, stripHtml } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import { getListMediaCategories, getSpecificMediaCategories, removeMediaCategories } from '../../redux/actions/MediaCategoriesActions';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/media-categories',
        breadcrumbName: 'Danh mục media',
    },
]

class MediaCategoriesList extends Component {
    state = {
        isLoading: false,
        selectedRowKeys: [],
        isOpenForm: false,
        currentItem: null
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.loadData(query);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.loadData(query);
        }
    }

    loadData = (query) => {
        this.setState({ isLoading: true });
        this.props.getListMediaCategories(query).then(() => {
            this.setState({ isLoading: false });
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificMediaCategories(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        if (this.props.authUser.id !== 2) {
            Modal.error({
                title: 'Thông báo',
                content: 'Không được xóa!'
            });
            return;
        }
        this.props.removeMediaCategories(this.state.selectedRowKeys).then(() => {
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        if (this.props.authUser.id !== 2) {
            Modal.error({
                title: 'Thông báo',
                content: 'Không được xóa!'
            });
            return;
        }
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeMediaCategories(id).then(() => {
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    render() {
        var { media_categories, pagination } = this.props;
        var { isLoading, selectedRowKeys, isOpenForm, currentItem } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;

        var query = qs.parse(this.props.location.search.slice(1));

        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                width: '50px',
                sorter: true
            },
            {
                title: 'Actions',
                width: '50px',
                align: 'center',
                render: (text, record) => (
                    <div>
                        {
                            checkPermission('media_categories', 'update') ? (
                                <>
                                    <EditOutlined className='color-primary' style={{ cursor: 'pointer' }} onClick={() => this.onEdit(record.id)} />
                                    {/* <Divider type='vertical' /> */}
                                </>
                            ) : null
                        }
                        {/* {
                            checkPermission('media_categories', 'remove') ? (
                                <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => this.openAlert([record.id])} />
                            ) : null
                        } */}
                    </div>
                )
            },
            {
                title: 'Tên',
                dataIndex: 'name',
                key: 'name',
                width: '200px'
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
                sorter: true,
                width: '200px',
                render: (text, record) => {
                    return (<>{text}</>)
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '150px',
                render: (text, record) => {
                    return (
                        <div>{isValidDate(text)}</div>
                    )
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                align: 'center',
                width: '100px',
                render: (text, record) => {
                    if (text == 1) return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                    if (text == 0) return <CloseCircleTwoTone twoToneColor="#e74c3c" />;
                }
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Danh mục media" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('media_categories', 'create')}
                        isShowDeleteButton={false}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={false}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                    >
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }
                    </TableActionBar>
                </div>
                <Table
                    rowKey="id"
                    size="small"
                    tableLayout='auto'
                    dataSource={media_categories || []}
                    columns={columns}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    pagination={{
                        pageSize: pagination.perPage,
                        current: pagination.currentPage,
                        total: pagination.total,
                        showTotal: total => `Tổng ${total} bản ghi`
                    }}
                    onChange={(pagination, filters, sorter) => this.onChangeTable(pagination, sorter)}
                    scroll={{
                        x: 'max-content'
                    }}
                />
                <MediaCategoriesForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    loadData={() => this.loadData(query)}
                    onCancel={() => this.toggleOpenForm(false)}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        media_categories: state.MediaCategoriesReducer.media_categories,
        pagination: state.MediaCategoriesReducer.pagination,
        authUser: state.auth.authUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListMediaCategories: (filter) => dispatch(getListMediaCategories(filter)),
        getSpecificMediaCategories: (id) => dispatch(getSpecificMediaCategories(id)),
        removeMediaCategories: (ids) => dispatch(removeMediaCategories(ids))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaCategoriesList);
