import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, EyeOutlined, FileOutlined, UserOutlined } from '@ant-design/icons';
import { Modal, Tooltip, Tag, Button, Typography, Row, Spin, Collapse, Col, Card, Divider, Descriptions } from 'antd';
import { checkPermission } from '../../../utils/permission';
import { repeatPhoneNumber, renderTelesaleTime, renderTelesaleDeadline } from '../../../utils/helpers';
import HtmlParser from 'react-html-parser';
import TelesaleDetail from './TelesaleDetail';
import TelesaleResultForm from './TelesaleResultForm';
import HandlePhoneNumber from '../../../components/HandlePhoneNumber';
import { getListCustomerTelesale, getSpecificCustomerTelesale, removeCustomerTelesale } from '../../../redux/actions/CustomerTelesaleActions';
import TelesaleForm from './TelesaleForm';

const { Panel } = Collapse;
const { Paragraph } = Typography;
const { confirm } = Modal;

class TelesaleList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            openUpdateResult: false,
            currentItem: null,
            loadingDetail: false,
            openDetail: false,
            current_id: null,
            telesale_list: [],
            pagination: {
                currentPage: 1,
                total: 0,
                perPage: 30
            }
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData() {
        this.setState({ isLoading: true });
        this.props.getListCustomerTelesale().then((res) => {
            this.setState({
                telesale_list: res.data.data,
                pagination: {
                    currentPage: parseInt(res.data.current_page),
                    total: parseInt(res.data.total),
                    perPage: parseInt(res.data.per_page),
                },
                isLoading: false
            });
        })
    }

    toggleOpenForm(isOpenEditForm, currentItem = null) {
        this.setState({
            isOpenEditForm: isOpenEditForm,
            currentItem: currentItem,
        });
    }

    onEdit(id) {
        this.props.getSpecificCustomerTelesale(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    toggleDetail(openDetail, data = null) {
        this.setState({ openDetail: openDetail, currentItem: data });
    }

    onDetail(id) {
        this.setState({ current_id: id, loadingDetail: true })
        this.props.getSpecificCustomerTelesale(id).then(res => {
            this.setState({ loadingDetail: false })
            this.toggleDetail(true, res);
        })
    }

    openUpdateResult(id) {
        this.props.getSpecificCustomerTelesale(id).then(res => {
            this.setState({ currentItem: res, openUpdateResult: true, require_field: res.status })
        })
    }

    onCancel() {
        this.loadData()
        this.setState({
            openUpdateResult: false,
            currentItem: null,
            isLoading: false
        })
    }

    onRemove = (ids) => {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeCustomerTelesale(ids).then(() => {
                this.loadData();
            }),
            onCancel() { },
        });
    }

    render() {
        var {
            telesale_list,
            isLoading,
            currentItem, current_id,
            loadingDetail,
            openUpdateResult,
            openDetail,
            isOpenEditForm
        } = this.state;

        return (
            <div>
                {
                    telesale_list && telesale_list?.length ? (
                        <Spin tip="Loading..." spinning={isLoading}>
                            <Row gutter={16}>
                                <Col xs={24} md={24} className='box-item-mobile box-item-mobile-header'>
                                    <Card size="small"
                                        title={<strong>Lịch telesale</strong>}
                                        bordered={false}
                                        headStyle={{ border: 0 }}
                                    ></Card>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                {
                                    telesale_list?.map((item, index) => {
                                        var bgcolor = "#f5b22d";
                                        var text2 = "Chưa trả kết quả"
                                        if (item.customer_telesale_status == 1) {
                                            bgcolor = "green";
                                            text2 = "Đã trả kết quả"
                                        }
                                        return (
                                            <Col key={index} xs={24} md={12} lg={8} className='box-item-mobile'>
                                                <Card size="small"
                                                    title={<>{<UserOutlined />} {item.full_name}</>}
                                                    extra={
                                                        <>
                                                            {checkPermission('customer_telesale', 'update') ? (
                                                                <a onClick={() => this.onEdit(item.customer_telesale_id)} type="link" key="list-vertical-edit">
                                                                    <EditOutlined /> Sửa
                                                                </a>
                                                            ) : null}

                                                            {checkPermission('customer_telesale', 'remove') ? (
                                                                <>
                                                                    <Divider type='vertical' />
                                                                    <span className='delete-icon' onClick={() => this.onRemove([item.customer_telesale_id])} type="link" key="list-vertical-delete">
                                                                        <DeleteOutlined /> Xóa
                                                                    </span>
                                                                </>
                                                            ) : null}
                                                            <Divider type='vertical' />
                                                            <span className='item-action-btn' onClick={() => this.onDetail(item.customer_telesale_id)}>
                                                                <EyeOutlined /> Xem
                                                            </span>
                                                        </>
                                                    }
                                                    // actions={[
                                                    //     <Button type="default" onClick={() => this.onDetail(item.customer_telesale_id)}><EyeOutlined /> Xem chi tiết</Button>
                                                    // ]}
                                                    bodyStyle={{ paddingBottom: 0 }}
                                                >
                                                    <Row gutter={16}>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Mã khách hàng">
                                                                    <Paragraph style={{ margin: 0 }} copyable={
                                                                        {
                                                                            text: item ? item.code : null,
                                                                            tooltips: false
                                                                        }
                                                                    }>
                                                                        {item.code}
                                                                    </Paragraph>
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Điện thoại">
                                                                    <HandlePhoneNumber
                                                                        permission={checkPermission('customer', 'phonenumber')}
                                                                        inputPinCode={checkPermission('customer', 'input_pin_code')}
                                                                        customer_id={item?.id}
                                                                        hiddenPhoneNumber={repeatPhoneNumber(item.phone)}
                                                                        fullPhoneNumber={item.phone}
                                                                    />
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Trạng thái khách hàng">
                                                                    {
                                                                        item.customer_status_color ? (
                                                                            <Tag color={item.customer_status_color}>{item.customer_status}</Tag>
                                                                        ) : (
                                                                            <Tag>{item.customer_status}</Tag>
                                                                        )
                                                                    }
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Trạng thái">
                                                                    {
                                                                        checkPermission('customer_telesale', 'update_result') ? (
                                                                            <Tooltip title="Click để cập nhật" placement="right">
                                                                                <Button icon={<EditOutlined />} style={{ background: bgcolor, color: '#fff', border: 'none' }} onClick={() => this.openUpdateResult(item.customer_telesale_id)} size='small'>
                                                                                    {text2}
                                                                                </Button>
                                                                            </Tooltip>
                                                                        ) : (
                                                                            <Button style={{ background: bgcolor, color: '#fff', border: 'none' }} size='small'>
                                                                                {text2}
                                                                            </Button>
                                                                        )
                                                                    }
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Deadline">
                                                                    {renderTelesaleDeadline(item.deadline, item.customer_telesale_status)}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Thời điểm tư vấn">
                                                                    {renderTelesaleTime(item.deadline, item.time_telesale)}
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                        </Col>
                                                        <Col xs={24} md={24} className='customer-telesale-box'>
                                                            <Collapse
                                                                expandIconPosition="end"
                                                                ghost
                                                                defaultActiveKey={[]}
                                                            >
                                                                <Panel
                                                                    header={<><span className="font-weight-500"><FileOutlined /> Kết quả tư vấn</span></>}
                                                                    key="1"
                                                                >
                                                                    {HtmlParser(item.result_content)}
                                                                </Panel>
                                                            </Collapse>
                                                        </Col>
                                                        <Col xs={24} md={24} className='customer-telesale-box no-border'>
                                                            <Collapse
                                                                expandIconPosition="end"
                                                                ghost
                                                                defaultActiveKey={[]}
                                                            >
                                                                <Panel
                                                                    header={<><span className="font-weight-500"><FileOutlined /> Ghi chú</span></>}
                                                                    key="1"
                                                                >
                                                                    {HtmlParser(item.note)}
                                                                </Panel>
                                                            </Collapse>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Spin>
                    ) : null
                }

                <TelesaleDetail
                    visible={openDetail}
                    currentItem={currentItem}
                    reloadData={() => this.onDetail(current_id)}
                    onCancel={() => this.toggleDetail(false)}
                    reloadList={() => this.loadData()}
                    loadingDetail={loadingDetail}
                />
                <TelesaleResultForm
                    visible={openUpdateResult}
                    currentItem={currentItem}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.onCancel()}
                />
                <TelesaleForm
                    visible={isOpenEditForm}
                    currentItem={currentItem}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.setState({ isOpenEditForm: false })}
                />
            </div >
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCustomerTelesale: (filter) => dispatch(getListCustomerTelesale(filter)),
        getSpecificCustomerTelesale: (id) => dispatch(getSpecificCustomerTelesale(id)),
        removeCustomerTelesale: (id) => dispatch(removeCustomerTelesale(id))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TelesaleList));

