import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Popconfirm } from "antd";
import { DeleteTwoTone, PlusOutlined } from '@ant-design/icons';
import BaseSelect from '../../components/Elements/BaseSelect';
import { getListCustomerActive } from '../../redux/actions/CustomerActions';
import { getRelationshipTypeActive } from '../../redux/actions/RelationshipTypeActions';

const FamilyRelationships = (props) => {

    const [items, setItems] = useState([{
        key: 0,
        related_id: "",
        relationship_type: "",
        relationship_type_inverse: "",
    }]);

    const relatedIds = items.map(item => item.related_id);

    useEffect(() => {
        props.getRelationshipTypeActive();
        props.getListCustomerActive({ is_selected: relatedIds });
    }, [items]);

    const { relationshipTypeActiveList, getItems, customerActiveList, currentData } = props;

    const [count, setCount] = useState(0);
    const [customerQuery, setCustomerQuery] = useState('');

    useEffect(() => {
        if (currentData && currentData.length > 0) {
            const initialItems = currentData.map((item, index) => ({
                key: index + 1,
                related_id: item.related_id,
                relationship_type: item.relationship_type,
                relationship_type_inverse: item.relationship_type_inverse,
            }));
            setItems(initialItems);
            setCount(initialItems.length);
        } else {
            setItems([{
                key: 0,
                related_id: "",
                relationship_type: "",
                relationship_type_inverse: "",
            }]);
            setCount(0);
        }
    }, [currentData]);

    useEffect(() => {
        getItems(items);
    }, [items]);

    const onAdd = () => {
        const newItem = {
            key: count + 1,
            related_id: "",
            relationship_type: "",
            relationship_type_inverse: "",
        };
        setItems([...items, newItem]);
        setCount(count + 1);
    };

    const onDelete = (key) => {
        const updatedItems = items.filter(item => item.key !== key);
        setItems(updatedItems);
    };

    const onChangeItem = (name, value, itemKey) => {
        const updatedItems = items.map(item =>
            item.key === itemKey ? { ...item, [name]: value } : item
        );
        setItems(updatedItems);
    };

    const onSearchCustomer = (val) => {
        setCustomerQuery(val);
        props.getListCustomerActive({ keyword: val });
    };

    const handleCustomerScroll = (e) => {
        props.getListCustomerActive({ keyword: customerQuery, page: props.pagination_active.currentPage + 1 });
    }

    var columns = [
        {
            title: "Họ và tên",
            dataIndex: "full_name",
            render: (text, record) => {
                return (
                    <BaseSelect
                        options={customerActiveList || []}
                        optionValue="id"
                        optionLabel="full_name"
                        additionalLabel="phone"
                        additionalLabel2="code"
                        isCustomerList={true}
                        showSearch={true}
                        defaultValue={record.related_id ? record.related_id : null}
                        placeholder="Nhập mã, tên, 3 số cuối sđt của khách hàng"
                        onSearch={(val) => onSearchCustomer(val)}
                        onScrollEnd={(e) => handleCustomerScroll(e)}
                        onChange={(e) => onChangeItem('related_id', e, record.key)}
                        allowClear={true}
                    />
                )
            }
        },
        {
            title: "Quan hệ",
            dataIndex: "relationship_type",
            width: "150px",
            render: (text, record) => {
                return (
                    <BaseSelect
                        options={relationshipTypeActiveList || []}
                        optionValue="id"
                        optionLabel="name"
                        placeholder="Chọn"
                        defaultValue={text ? text : null}
                        style={{ width: '100%' }}
                        showSearch={true}
                        onChange={(e) => onChangeItem('relationship_type', e, record.key)}
                    />
                )
            }
        },
        {
            title: "Quan hệ ngược",
            dataIndex: "relationship_type_inverse",
            width: "150px",
            render: (text, record) => {
                return (
                    <BaseSelect
                        options={relationshipTypeActiveList || []}
                        optionValue="id"
                        optionLabel="name"
                        placeholder="Chọn"
                        defaultValue={text ? text : null}
                        style={{ width: '100%' }}
                        showSearch={true}
                        onChange={(e) => onChangeItem('relationship_type_inverse', e, record.key)}
                    />
                )
            }
        },
        {
            title: "#",
            align: "center",
            width: "50px",
            render: (text, record) => {
                return (
                    <Popconfirm
                        title="Xóa dòng này?"
                        placement='topRight'
                        onConfirm={() => onDelete(record.key)}
                    >
                        <DeleteTwoTone twoToneColor="#fe0000" />
                    </Popconfirm>
                )
            }
        }
    ];

    return (
        <>
            <div className='chingo-normal-title'>Quan hệ gia đình</div>
            <Table
                className='mt-2 mb-2'
                size='small'
                rowKey='key'
                tableLayout="auto"
                columns={columns}
                dataSource={items}
                locale={{ emptyText: "Chưa có dữ liệu" }}
                pagination={false}
                scroll={{
                    x: 'max-content'
                }}
            />
            <Button type="default" size='small' onClick={() => onAdd()} icon={<PlusOutlined />}>Thêm thành viên trong gia đình</Button>
        </>
    )
}

function mapStateToProps(state) {
    return {
        relationshipTypeActiveList: state.RelationshipTypeReducer.relationshipTypeActiveList,
        customerActiveList: state.CustomerReducer.customerActiveList,
        pagination_active: state.CustomerReducer.pagination_active,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRelationshipTypeActive: (filter) => dispatch(getRelationshipTypeActive(filter)),
        getListCustomerActive: (filter) => dispatch(getListCustomerActive(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FamilyRelationships);