import api from '../../utils/api';
import {
    GET_LIST_BRANCH,
    GET_SPECIFIC_BRANCH,
    UPDATE_SPECIFIC_BRANCH,
    CREATE_NEW_BRANCH,
    REMOVE_BRANCH,
    GET_BRANCH_ACTIVE
} from './types';
import { NotificationManager } from 'react-notifications';

export const getListBranch = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/branch', { params: filter }).then(res => {
            dispatch({ type: GET_LIST_BRANCH, payload: res.data.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getSpecificBranch = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get(`/branch/${id}`).then(res => {
            dispatch({ type: GET_SPECIFIC_BRANCH, payload: res.data.data });
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const getBranchActive = (filter) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.get('/branch/by-active', { params: filter }).then(res => {
            //console.log(res)
            dispatch({ type: GET_BRANCH_ACTIVE, payload: res.data });
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const createBranch = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.post('/branch', data).then(res => {
            dispatch({ type: CREATE_NEW_BRANCH, payload: res.data.data });
            NotificationManager.success("Thêm mới bản ghi thành công!");
            resolve(res.data.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}



export const updateBranch = (id, data) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.put(`/branch/${id}`, data).then(res => {
            dispatch({ type: UPDATE_SPECIFIC_BRANCH, payload: res.data.data });
            NotificationManager.success("Cập nhật bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}

export const removeBranch = (ids) => dispatch => {
    return new Promise((resolve, reject) => {
        return api.delete(`/branch`, { data: { ids: ids } }).then(res => {
            dispatch({ type: REMOVE_BRANCH, payload: ids });
            NotificationManager.success("Xoá bản ghi thành công!");
            resolve(res.data);
        }).catch(err => {
            console.log(err)
            NotificationManager.error(err.response.data.msg);
            reject(err);
        })
    })
}
