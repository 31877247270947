import React, { Component } from 'react';
import { Divider, Tag, Row, Col, Descriptions, Table, Typography, Button } from 'antd'
import ReactHtmlParser from 'react-html-parser';
import HandlePhoneNumber from '../../components/HandlePhoneNumber';
import { checkPermission } from '../../utils/permission';
import { ConvertcustomerGender, repeatPhoneNumber } from '../../utils/helpers';
import { ArrowDownOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

class FamilyRelationshipsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showOldData: false
        };
    }

    toggleOldDataVisibility = () => {
        this.setState((prevState) => ({
            showOldData: !prevState.showOldData,
        }));
    };

    render() {
        const { showOldData } = this.state;
        var { currentItem } = this.props;

        var family_columns = [
            {
                title: "Họ và tên",
                dataIndex: "full_name",
                render: (text, record) => {
                    return (
                        <>{record?.related_customer?.full_name}</>
                    )
                }
            },
            {
                title: "Mã",
                dataIndex: "code",
                width: "140px",
                render: (text, record) => {
                    return (
                        <>
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record?.related_customer?.code,
                                    tooltips: false
                                }
                            }>
                                <Tag color='#1890ff'>{record?.related_customer?.code}</Tag>
                            </Paragraph>
                        </>
                    )
                }
            },
            {
                title: "Số điện thoại",
                dataIndex: "phone",
                width: "120px",
                render: (text, record) => {
                    return (
                        <>
                            {record?.related_customer?.phone ? (
                                <HandlePhoneNumber
                                    permission={checkPermission('customer', 'phonenumber')}
                                    inputPinCode={checkPermission('customer', 'input_pin_code')}
                                    customer_id={record?.related_customer?.id}
                                    hiddenPhoneNumber={repeatPhoneNumber(record?.related_customer?.phone)}
                                    fullPhoneNumber={record?.related_customer?.phone}
                                />
                            ) : null}
                        </>
                    )
                }
            },
            {
                title: "Năm sinh",
                dataIndex: "birthday",
                width: "120px",
                render: (text, record) => {
                    return (
                        <>{record?.related_customer?.birthday}</>
                    )
                }
            },
            {
                title: "Quan hệ",
                dataIndex: "relationship",
                width: "120px",
                render: (text, record) => {
                    return (
                        <>{record?.relationship_types?.name}</>
                    )
                }
            }
        ];

        var family_columns_old = [
            {
                title: "Họ và tên",
                dataIndex: "full_name",
            },
            {
                title: "Giới tính",
                dataIndex: "gender",
                width: "140px",
                render: (text, record) => {
                    return (
                        <>{ConvertcustomerGender(text)}</>
                    )
                }
            },
            {
                title: "Số điện thoại",
                dataIndex: "phone",
                width: "120px",
            },
            {
                title: "Năm sinh",
                dataIndex: "birthday",
                width: "120px",
            },
            {
                title: "Quan hệ",
                dataIndex: "relationship",
                width: "120px"
            }
        ];

        return (
            <>
                <Divider orientation="left" orientationMargin="0" className='mt-3'>
                    <Tag color='#0b2b33'>10</Tag> Thông tin nhân khẩu
                </Divider>
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Tỉnh/Thành phố">
                                {currentItem ? currentItem.province_name : null}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Quận/Huyện">
                                {currentItem ? currentItem.district_name : null}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Phường/Xã">
                                {currentItem ? currentItem.wards_name : null}
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider className="no-margin" />
                    </Col>
                    <Col xs={24} md={24}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Địa chỉ">
                                {currentItem ? ReactHtmlParser(currentItem.address) : null}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Table
                            bordered
                            title={() => <>
                                <strong>Thành viên trong gia đình</strong>
                                {currentItem?.customer_family_old?.length > 0 &&
                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={this.toggleOldDataVisibility} // Gọi hàm chuyển đổi trạng thái
                                    >
                                        {showOldData ? 'Ẩn dữ liệu cũ' : 'Xem dữ liệu cũ'}
                                        <ArrowDownOutlined />
                                    </Button>}
                            </>}
                            size='small'
                            rowKey='key'
                            tableLayout="auto"
                            columns={family_columns}
                            locale={{ emptyText: "Chưa có dữ liệu" }}
                            dataSource={currentItem ? currentItem.customer_family : []}
                            pagination={false}
                            scroll={{
                                x: 'max-content'
                            }}
                        />
                        {showOldData && (
                            <Table
                                bordered
                                className='mb-4'
                                size='small'
                                rowKey='key'
                                tableLayout="auto"
                                columns={family_columns_old}
                                locale={{ emptyText: "Chưa có dữ liệu" }}
                                dataSource={currentItem ? currentItem.customer_family_old : []}
                                pagination={false}
                                scroll={{
                                    x: 'max-content'
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </>
        )
    }
}

export default (FamilyRelationshipsList);
