import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Tag, Row, Col, Card, Typography, Button, Descriptions, Modal, Spin } from 'antd'
import { getScheduleByCustomer, getSpecificCustomerSchedule, removeCustomerSchedule } from '../../../redux/actions/CustomerScheduleActions';
import { ClockCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { checkPermission } from '../../../utils/permission';
import { ConvertCustomerScheduleStatus, ConvertCustomerScheduleType, isValidDate } from '../../../utils/helpers';
import HtmlParser from 'react-html-parser';
import moment from 'moment';
import ScheduleForm from './ScheduleForm';
import UpdateStatusForm from './UpdateStatusForm';
import NoData from '../NoData';
import ScheduleDetail from './ScheduleDetail';

const { Text } = Typography;
const { confirm } = Modal;

class AppointmentHistory extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            currentItem: [],
            isOpenForm: false,
            currentCustomer: null,
            currentData: null,
            isOpenStatusForm: false
        };
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.customer !== this.props.customer) {
            this.loadData();
        }
    }

    openForm() {
        this.setState({ currentCustomer: this.props.customer, isOpenForm: true })
    }

    onCancel() {
        this.setState({ isOpenForm: false, currentData: null, isOpenStatusForm: false })
    }

    loadData() {
        this.setState({ isLoading: true })
        this.props.getScheduleByCustomer(this.props.customer?.id).then(res => {
            this.setState({ currentItem: res, isLoading: false })
        }).catch(err => {
            this.setState({ isLoading: false })
        });
    }

    openRemove(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeCustomerSchedule(id).then(() => {
                setTimeout(() => {
                    this.loadData();
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    onEdit(id) {
        this.props.getSpecificCustomerSchedule(id).then(res => {
            this.setState({
                currentCustomer: this.props.customer,
                currentData: res,
                isOpenForm: true
            })
        })
    }

    toggleOpenUpdateStatusForm(id, isOpenStatusForm) {
        this.props.getSpecificCustomerSchedule(id).then(res => {
            this.setState({ isOpenStatusForm: isOpenStatusForm, currentData: res });
        })
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getSpecificCustomerSchedule(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentData: data });
    }

    render() {
        var { currentItem, currentData, isOpenForm, currentCustomer, isLoading, isOpenStatusForm, isOpenDetail } = this.state;

        return (
            <>
                <Row justify="end" gutter={16}>
                    <Col xs={24} md={24} className='box-item-mobile box-item-mobile-header'>
                        <Card size="small"
                            title={<><Tag color='#0b2b33'>2</Tag>  Lịch sử cuộc hẹn</>}
                            bordered={false}
                        >
                            <Button
                                disabled={!checkPermission('customer_schedule', 'create')}
                                type='primary'
                                onClick={() => this.openForm()}
                                className='w-100'
                                icon={<PlusOutlined />}>
                                Thêm cuộc hẹn mới
                            </Button>
                        </Card>
                    </Col>
                </Row>
                {
                    currentItem && currentItem?.length ? (
                        <Spin tip="Loading..." spinning={isLoading}>
                            <Row gutter={16}>
                                {
                                    currentItem?.map((item, index) => {
                                        var diffDate = item.time ? moment(item.time, "YYYY-MM-DD").diff(moment().startOf('day'), 'days') : 0;
                                        var bgcolor = "#f5b22d";
                                        if (item.status == 'daden' || item.status == 'dagoi') bgcolor = "green";
                                        return (
                                            <Col key={index} xs={24} md={12} lg={8} className='box-item-mobile'>
                                                <Card size="small"
                                                    title={<>{<ClockCircleOutlined />} {ConvertCustomerScheduleType(item.name)}</>}
                                                    extra={
                                                        <>
                                                            {checkPermission('customer_schedule', 'update') ? (
                                                                <a onClick={() => this.onEdit(item.id)} type="link" key="list-vertical-edit">
                                                                    <EditOutlined /> Sửa
                                                                </a>
                                                            ) : null}

                                                            {checkPermission('customer_schedule', 'remove') ? (
                                                                <>
                                                                    <Divider type='vertical' />
                                                                    <span className='delete-icon' onClick={() => this.openRemove([item.id])} type="link" key="list-vertical-delete">
                                                                        <DeleteOutlined /> Xóa
                                                                    </span>
                                                                </>
                                                            ) : null}
                                                            <Divider type='vertical' />
                                                            <span className='item-action-btn' onClick={() => this.onDetail(item.id)}>
                                                                <EyeOutlined /> Xem
                                                            </span>
                                                        </>
                                                    }
                                                >
                                                    <Row gutter={16}>
                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Thời gian">
                                                                    {isValidDate(item.time)} -&nbsp;
                                                                    {
                                                                        diffDate ? (
                                                                            <span>
                                                                                {
                                                                                    diffDate > 0 ? <Tag color="#f50">Còn lại {diffDate} ngày</Tag> : <Tag color="red">Cách đây {-1 * diffDate} ngày</Tag>
                                                                                }
                                                                            </span>
                                                                        ) : (
                                                                            <>
                                                                                {
                                                                                    item.time != null ? (
                                                                                        <Tag color="green">Hôm nay</Tag>
                                                                                    ) : null
                                                                                }
                                                                            </>
                                                                        )
                                                                    }
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                        </Col>
                                                        {item.name == 'TAIKHAM' || item.name == 'LAMDICHVU' ? (
                                                            <Col xs={24} md={24}>
                                                                <Descriptions size="small">
                                                                    <Descriptions.Item label="Bác sĩ thực hiện">
                                                                        {item.doctors}
                                                                    </Descriptions.Item>
                                                                </Descriptions>
                                                                <Divider className='no-margin' />
                                                            </Col>
                                                        ) : null}

                                                        <Col xs={24} md={24}>
                                                            <Descriptions size="small">
                                                                <Descriptions.Item label="Trạng thái">
                                                                    <Button icon={<EditOutlined />} style={{ background: bgcolor, color: '#fff', border: 'none' }} onClick={() => this.toggleOpenUpdateStatusForm(item.id, true)} size='small'>
                                                                        {ConvertCustomerScheduleStatus(item.status, true)}
                                                                    </Button>
                                                                </Descriptions.Item>
                                                            </Descriptions>
                                                            <Divider className='no-margin' />
                                                        </Col>
                                                        <Col xs={24} md={24}>
                                                            <Text strong>Ghi chú: </Text>
                                                            {HtmlParser(item.note)}
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Spin>
                    ) : (
                        <NoData />
                    )
                }
                <ScheduleForm
                    visible={isOpenForm}
                    currentCustomer={currentCustomer}
                    currentItem={currentData}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.onCancel()}
                />

                <UpdateStatusForm
                    visible={isOpenStatusForm}
                    currentItem={currentData}
                    reloadData={() => this.loadData()}
                    onCancel={() => this.setState({ isOpenStatusForm: false })}
                />

                <ScheduleDetail
                    visible={isOpenDetail}
                    currentItem={currentData}
                    onCancel={() => this.toggleOpenDetail(false)}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getScheduleByCustomer: (customer_id) => dispatch(getScheduleByCustomer(customer_id)),
        getSpecificCustomerSchedule: (id) => dispatch(getSpecificCustomerSchedule(id)),
        removeCustomerSchedule: (id) => dispatch(removeCustomerSchedule(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentHistory);
