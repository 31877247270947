import {
    SUMMARY_STATISTICS
} from '../actions/types';

/**
 * initial state
 */
const INIT_STATE = {
    statistic: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUMMARY_STATISTICS: {
            return {
                ...state,
                statistic: action.payload.data,
            }
        }
        default: return { ...state };
    }
}
