import React, { Component } from 'react';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar/index';
import { Modal, Image, Card, Row, Col, Pagination, Empty, Button, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import qs from 'qs';
import _ from 'lodash';
import { checkPermission } from '../../utils/permission';
import { cleanObject, downloadFromLink, stripHtml } from '../../utils/helpers';
import { NotificationManager } from 'react-notifications'
import MediaForm from './MediaForm'
import { getListAll, removeMedia, getOneMedia } from '../../redux/actions/MediaAction';
import { getMediaCategoriesActive } from '../../redux/actions/MediaCategoriesActions';
import HtmlParser from 'react-html-parser';
import TextTruncate from 'react-text-truncate';
import MediaDetail from './MediaDetail';
import BaseSelect from '../../components/Elements/BaseSelect';

const { confirm } = Modal;

const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/media',
        breadcrumbName: 'Quản lý media',
    }
]

class MediaList extends Component {

    constructor() {
        super();
        this.state = {
            isLoading: false,
            isOpenForm: false,
            currentItem: null,
            isOpenDetail: false,
        }
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.loadData(query);
        this.props.getMediaCategoriesActive();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.loadData(query);
        }
    }

    onChangeTable(page, pageSize) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            page: page,
            per_page: pageSize,
        }
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        })
    }

    onRemove(id) {
        if (checkPermission('media', 'remove')) {
            confirm({
                title: 'Cảnh báo',
                content: 'Bạn chắc chắn muốn xoá bản ghi này?',
                okText: 'OK',
                cancelText: 'Huỷ',
                onOk: () => this.props.removeMedia([id]).then(() => {
                    setTimeout(() => {
                        Modal.destroyAll();
                    }, 800);
                }),
                onCancel() { },
            })
        } else {
            NotificationManager.error("Bạn không được cấp quyền thực hiện thao tác này!");
        }
    }

    loadData(query) {
        this.setState({
            isLoading: true
        });
        this.props.getListAll(query).then(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }

        query = cleanObject(query);

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        })
    }

    onDownload(url) {
        try {
            setTimeout(() => {
                const response = {
                    file: url,
                };
                downloadFromLink(response.file);
            }, 100);
        } catch (e) {
            NotificationManager.error("Error!");
        }
    }

    openInNewTab(url) {
        window.open(url, '_blank').focus();
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentItem: data });
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getOneMedia(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getOneMedia(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    render() {
        var { isLoading, isOpenForm, isOpenDetail, currentItem } = this.state;
        const { media_list, pagination, media_categories_active } = this.props;
        var query = qs.parse(this.props.location.search.slice(1));

        return (
            <div>
                <PageTitle routes={routes} title="Quản lý media" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('media', 'create')}
                        isShowDeleteButton={false}
                        onAdd={() => this.toggleOpenForm(true)}
                        disabled={true}
                        onDelete={() => this.onRemove()}
                        showSearch={true}
                        showFilter={false}
                        activeFilter={query.status}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        placeholderSearch="Tìm theo tên, sđt, mã khách hàng"
                        filters={[]}
                    >
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.loadData()}>Refresh</Button>
                        <BaseSelect
                            options={media_categories_active || []}
                            optionValue="id"
                            optionLabel="name"
                            placeholder="Chọn một bản ghi"
                            defaultValue={query.categories_id}
                            style={{ width: '200px' }}
                            onChange={(value) => this.onChangeFilter('categories_id', value)}
                            className="table-button"
                        />
                    </TableActionBar>
                </div>
                <Card>
                    {
                        media_list.length ? (
                            <Row gutter={20} style={{ height: 'max-content' }} key={1}>
                                <Image.PreviewGroup>
                                    {
                                        media_list.map(item => {
                                            return (
                                                <Col lg={4} md={8} sm={12} xs={12} key={item.id} className="mb-4" >
                                                    <Card
                                                        loading={isLoading}
                                                        hoverable
                                                        style={{ width: '100%', overflow: 'hidden' }}
                                                        size="small"
                                                        className='file-media-card'
                                                        bodyStyle={{ padding: 0 }}
                                                        actions={[
                                                            <Button key="detail" type='link' size='small' onClick={() => this.onDetail(item.id)}>Xem</Button>,
                                                            <Button key="edit" disabled={!checkPermission('media', 'update')} icon={<EditOutlined />} type='link' size='small' onClick={() => this.onEdit(item.id)} />,
                                                            <Button key="delete" disabled={!checkPermission('media', 'remove')} icon={<DeleteOutlined />} type='link' size='small' onClick={() => this.onRemove(item.id)} />,
                                                        ]}
                                                    >
                                                        <Image
                                                            className="responsive-media-image"
                                                            width="100%"
                                                            src={item.thumbnail}
                                                        //preview={false}
                                                        />
                                                        <div className='mt-1 mb-1 pl-1' style={{ color: '#00000073' }}>
                                                            <TextTruncate line={1} truncateText="…" text={item.category?.name} />
                                                        </div>
                                                        {item.type === 'customer_profile' ? (
                                                            <div className='mt-1 mb-1 pl-1' style={{ color: '#00000073' }}>
                                                                {item.customer?.full_name} ({item.customer?.code})
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {item.type === 'branch' ? (
                                                                    <div className='mt-1 mb-1 pl-1' style={{ color: '#00000073' }}>
                                                                        {item.branch?.name}
                                                                    </div>
                                                                ) : (
                                                                    <Tooltip title={HtmlParser(item.note)} placement="topLeft">
                                                                        <div className='mt-1 mb-1 pl-1' style={{ color: '#00000073' }}>
                                                                            <TextTruncate line={1} truncateText="…" text={stripHtml(item.note)} />
                                                                        </div>
                                                                    </Tooltip>
                                                                )}
                                                            </>

                                                        )}

                                                    </Card>
                                                </Col>
                                            )
                                        })
                                    }
                                </Image.PreviewGroup>
                            </Row>
                        ) : (
                            <Row gutter={20} style={{ height: 'max-content', display: 'flex', justifyContent: 'center' }} key={1}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </Row>
                        )
                    }
                    <div style={{ textAlign: 'right', }}>
                        <Pagination
                            pageSize={pagination.perPage}
                            defaultPageSize={pagination.perPage}
                            defaultCurrent={pagination.currentPage}
                            total={pagination.total}
                            pageSizeOptions={['10', '20', '30', '40', '50', '100']}
                            showSizeChanger={true}
                            showTotal={total => `Tổng ${total} bản ghi`}
                            onChange={(page, pageSize) => this.onChangeTable(page, pageSize)}
                        />
                    </div>
                </Card>

                <MediaForm
                    visible={isOpenForm}
                    onCancel={() => this.toggleOpenForm(false)}
                    currentItem={currentItem}
                    reloadData={() => this.loadData(query)}
                />

                <MediaDetail
                    visible={isOpenDetail}
                    currentItem={currentItem}
                    onCancel={() => this.toggleOpenDetail(false)}
                    loadingDetail={false}
                />
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {
        media_list: state.MediaReducer.media_list,
        pagination: state.MediaReducer.pagination,
        media_categories_active: state.MediaCategoriesReducer.media_categories_active,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getListAll: filter => dispatch(getListAll(filter)),
        removeMedia: ids => dispatch(removeMedia(ids)),
        getOneMedia: id => dispatch(getOneMedia(id)),
        getMediaCategoriesActive: () => dispatch(getMediaCategoriesActive()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MediaList);
