import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Modal, Tooltip, Tag, DatePicker, Typography, Avatar, Button, Popover, Row, Card, Divider, Col, Alert } from 'antd';
import PageTitle from '../../components/PageTitle';
import TableActionBar from '../../components/TableActionBar';
import _, { } from 'lodash';
import qs from 'qs';
import { Form } from '@ant-design/compatible';
import TextTruncate from 'react-text-truncate';
import CashReceiptForm from './CashReceiptForm';
import CustomerDetail from '../customer/CustomerDetail';
import CashReceiptDetail from './CashReceiptDetail';
import CashReceiptUpdateStatusForm from './CashReceiptUpdateStatusForm';
import { checkPermission } from '../../utils/permission';
import { cleanObject, stripHtml, getFirstLetterOfWords, CashReceiptStatus, ConvertCashReceiptStatusInList, isValidDate, ConvertPaymentMethod, PaymentMethod, PaymentType, ConvertPaymentType, ConvertCashReceiptStatus } from '../../utils/helpers';
import HtmlParser from 'react-html-parser';
import NumberFormat from 'react-number-format';
import BaseSelect from '../../components/Elements/BaseSelect';

import moment from 'moment';
// actions
import { getListCashReceipt, getSpecificCashReceipt, removeCashReceipt, getStatistical } from '../../redux/actions/CashReceiptActions';
import { getSpecificCustomer } from '../../redux/actions/CustomerActions';
import { PrinterOutlined, ReloadOutlined } from '@ant-design/icons';
import DynamicTable from '../../components/DynamicTable';
import PrintTable from './PrintTable';
import ImportExcelForm from './ImportExcelForm';

const { confirm } = Modal;
const { RangePicker } = DatePicker;
const { Paragraph } = Typography;
const routes = [
    {
        path: '/',
        breadcrumbName: 'Trang chủ',
    },
    {
        path: '/cash-recipt',
        breadcrumbName: 'Phiếu thu',
    },
]

class CashReceiptList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedRowKeys: [],
            isOpenForm: false,
            currentItem: null,
            isOpenDetail: false,
            isOpenCustomerDetail: false,
            currentCustomerData: null,
            isOpenStatusForm: false,
            importResponseMessage: null,
            importResponseType: null,
            isOpenFormImport: false
        }

        this.printTableRef = React.createRef();
    }

    componentDidMount() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListCashReceipt(query).then(() => {
            this.setState({ isLoading: false });
        })
        this.props.getStatistical(query);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.location.search !== this.props.location.search.slice()) {
            var query = qs.parse(nextProps.location.search.slice(1));
            this.setState({ isLoading: true });
            this.props.getListCashReceipt(query).then(() => {
                this.setState({
                    isLoading: false
                })
            })
            this.props.getStatistical(query);
        }
    }

    onChangeFilter(name, value) {
        var query = qs.parse(this.props.location.search.slice(1));
        query = {
            ...query,
            [name]: value
        }
        if (name === 'created_at') {
            if (value && value.length > 0) {
                query.created_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.created_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.created_date_start;
                delete query.created_date_end;
            }
        }
        if (name === 'payment_at') {
            if (value && value.length > 0) {
                query.payment_date_start = moment(value[0]).format('YYYY-MM-DD');
                query.payment_date_end = moment(value[1]).format('YYYY-MM-DD');
            } else {
                delete query.payment_date_start;
                delete query.payment_date_end;
            }
        }
        delete query.created_at;
        delete query.payment_at;
        query = cleanObject(query);
        //console.log("query: ", query)
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query)
        });
    }

    onChangeTable(pagination, sorter) {
        var query = qs.parse(this.props.location.search.slice(1));
        if (!_.isEmpty(pagination)) {
            query = {
                ...query,
                page: pagination.current,
                per_page: pagination.pageSize,
            }
        }
        if (!_.isEmpty(sorter)) {
            if (sorter.order) {
                query = {
                    ...query,
                    sort_field: sorter.field,
                    sort_type: sorter.order === 'ascend' ? 'ASC' : 'DESC'
                }
            } else {
                delete query.sort_field;
                delete query.sort_type;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: qs.stringify(query),
        });
    }

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    toggleOpenForm(isOpenForm, currentItem = null) {
        this.setState({ isOpenForm: isOpenForm, currentItem: currentItem });
    }

    onEdit(id) {
        this.props.getSpecificCashReceipt(id).then(res => {
            this.toggleOpenForm(true, res);
        })
    }

    onRemove() {
        this.props.removeCashReceipt(this.state.selectedRowKeys).then(() => {
            this.reloadData();
            this.setState({
                selectedRowKeys: []
            });
            setTimeout(() => {
                Modal.destroyAll();
            }, 800);
        })
    }
    openAlert(id) {
        confirm({
            title: 'Cảnh báo',
            content: 'Bạn chắc chắn muốn xoá những bản ghi này?',
            okText: 'OK',
            cancelText: 'Huỷ',
            onOk: () => this.props.removeCashReceipt([id]).then(() => {
                this.reloadData();
                setTimeout(() => {
                    Modal.destroyAll();
                }, 800);
            }),
            onCancel() { },
        })
    }

    toggleOpenDetail(isOpenDetail, data = null) {
        this.setState({ isOpenDetail: isOpenDetail, currentItem: data });
    }

    onDetail(id) {
        this.setState({ current_id: id })
        this.props.getSpecificCashReceipt(id).then(res => {
            this.toggleOpenDetail(true, res);
        })
    }

    toggleOpenCustomerDetail(isOpenCustomerDetail, data = null) {
        this.setState({ isOpenCustomerDetail: isOpenCustomerDetail, currentCustomerData: data });
    }

    onCustomerDetail(customer_id) {
        this.setState({ currentCustomerId: customer_id })
        this.props.getSpecificCustomer(customer_id).then(res => {
            this.toggleOpenCustomerDetail(true, res);
        })
    }

    reloadData() {
        var query = qs.parse(this.props.location.search.slice(1));
        this.setState({ isLoading: true });
        this.props.getListCashReceipt(query).then(() => {
            this.setState({ isLoading: false });
        })
        this.props.getStatistical(query);
    }

    openUpdateStatusForm(id) {
        this.props.getSpecificCashReceipt(id).then(res => {
            this.setState({ isOpenStatusForm: true, currentItem: res });
        })
    }

    handlePrint = async (id) => {
        const res = await this.props.getSpecificCashReceipt(id);
        this.setState({ currentItem: res }, () => {
            this.printTableRef.current.calculateTotal().then(() => {
                const content = document.getElementById('custom-print-content').innerHTML;
                const printWindow = window.open('', '', 'width=900,height=800');
                printWindow.document.open();
                printWindow.document.write('<html><head><title>Print</title></head><body>');
                printWindow.document.write(content);
                printWindow.document.write('</body></html>');
                printWindow.document.close();
                printWindow.print();
            });
        });
    };

    onSetMessage = (message, type) => {
        this.setState({ importResponseMessage: message, importResponseType: type })
    }

    render() {
        var { cash_receipt_list, pagination,
            users, statistical
        } = this.props;
        var { isLoading, selectedRowKeys,
            isOpenForm,
            currentItem, isOpenDetail,
            current_id,
            currentCustomerData,
            isOpenCustomerDetail,
            currentCustomerId,
            isOpenStatusForm,
            importResponseMessage,
            importResponseType,
            isOpenFormImport
        } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const hasSelected = selectedRowKeys.length > 0;
        var query = qs.parse(this.props.location.search.slice(1));

        const customer = {
            customer_name: currentItem?.customer_name,
            customer_phone: currentItem?.customer_phone,
            customer_address: currentItem?.customer_address,
            province_name: currentItem?.province_name,
            district_name: currentItem?.district_name,
            wards_name: currentItem?.wards_name,
            invoice_no: currentItem?.code,
            special_discount: currentItem?.special_discount,
            amount_paid: currentItem?.amount_paid,
            remaining_debt: currentItem?.remaining_debt,
            total_amount_paid: currentItem?.total_amount_paid,
        }

        const columns = [
            {
                title: 'Mã phiếu',
                dataIndex: 'code',
                key: 'code',
                width: '80px',
                render: (text, record) => {
                    return (
                        <>
                            {checkPermission('cash_receipt', 'detail') ? (
                                <Tooltip title="Xem chi tiết phiếu" placement="right">
                                    <span className='color-primary item-action-btn' onClick={() => this.onDetail(record.id)}>
                                        {text}
                                    </span>
                                </Tooltip>
                            ) : (
                                <>{text}</>
                            )}

                        </>
                    )
                }
            },
            {
                title: '#',
                align: 'center',
                width: '50px',
                key: 'action',
                render: (text, record) => {
                    return (
                        <>
                            <Tooltip title="In phiếu" placement="right">
                                <Button size='small' type='dashed' onClick={() => this.handlePrint(record.id)} icon={<PrinterOutlined />}>
                                    <span id="custom-print-content" style={{ display: 'none' }}>
                                        <PrintTable ref={this.printTableRef} customer={customer} tableData={currentItem ? currentItem?.items : []} />
                                    </span>
                                </Button>
                            </Tooltip>
                        </>
                    )
                }
            },
            {
                title: 'Ngày thu',
                dataIndex: 'payment_at',
                key: 'payment_at',
                width: '100px',
                sorter: true,
                render: (text, record) => {
                    if (text && text !== '') {
                        return (
                            <>{isValidDate(text, false)}</>
                        )
                    } else {
                        return (<Tag></Tag>)
                    }
                }
            },
            {
                title: 'Khách hàng',
                dataIndex: 'customer_name',
                key: 'customer_name',
                render: (text, record) => {
                    if (record.customer_name) {
                        if (checkPermission('customer', 'detail')) {
                            return (
                                <Tooltip title="Xem hồ sơ khách hàng" placement="right">
                                    <div className='color-primary item-action-btn' onClick={() => this.onCustomerDetail(record.customer_id)}>
                                        <Avatar
                                            style={{
                                                backgroundColor: '#31cdf5',
                                                verticalAlign: 'middle'
                                            }}
                                            size="small">{record.customer_name ? getFirstLetterOfWords(record.customer_name) : ''}</Avatar>
                                        &nbsp;{record.customer_name}
                                    </div>
                                </Tooltip>
                            )
                        } else {
                            return (
                                <>
                                    <Avatar
                                        style={{
                                            backgroundColor: '#31cdf5',
                                            verticalAlign: 'middle'
                                        }}
                                        size="small">{record.customer_name ? getFirstLetterOfWords(record.customer_name) : ''}</Avatar>
                                    &nbsp;{record.customer_name}
                                </>
                            )
                        }
                    } else {
                        return <>N/A</>
                    }
                }
            },
            {
                title: 'Mã KH',
                dataIndex: 'customer_code',
                key: 'customer_code',
                width: '140px',
                sorter: true,
                render: (text, record) => {
                    if (text) {
                        return (
                            <Paragraph style={{ margin: 0 }} copyable={
                                {
                                    text: record ? record.customer_code : null,
                                    tooltips: false
                                }
                            }>
                                {text}
                            </Paragraph>
                        )
                    }
                }
            },
            {
                title: 'Tổng',
                dataIndex: 'total_amount',
                key: 'total_amount',
                render: (text, record) => (
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                )
            },
            {
                title: 'Ưu đãi',
                dataIndex: 'special_discount',
                key: 'special_discount',
                render: (text, record) => (
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                )
            },
            {
                title: 'Doanh số',
                dataIndex: 'adjusted_sales',
                key: 'adjusted_sales',
                render: (text, record) => (
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                )
            },
            {
                title: 'Số tiền thu',
                dataIndex: 'amount_paid',
                key: 'amount_paid',
                render: (text, record) => (
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                )
            },
            {
                title: 'Tổng đã thu',
                dataIndex: 'total_amount_paid',
                key: 'total_amount_paid',
                render: (text) => (
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                ),
            },
            {
                title: 'Công nợ',
                dataIndex: 'remaining_debt',
                key: 'remaining_debt',
                render: (text) => (
                    <NumberFormat value={text} displayType={'text'} thousandSeparator={true} />
                ),
            },
            {
                title: 'Nội dung thu',
                dataIndex: 'items',
                key: 'items',
                width: '200px',
                render: (text, record) => {
                    if (text && Array.isArray(text) && text.length) {
                        let content = text.map((item, index) => {
                            return (
                                <div style={{ padding: '5px 0' }}>
                                    {index + 1}. {item.name}
                                </div>
                            )
                        })
                        let first_item = text[0]?.name;
                        if (checkPermission('cash_receipt', 'detail')) {
                            return (
                                <Popover placement="right" content={content}>
                                    <div className='item-action-btn' onClick={() => this.onDetail(record.id)}>
                                        <TextTruncate line={1} truncateText="…" text={stripHtml(first_item)} />
                                    </div>
                                </Popover>
                            )
                        } else {
                            return (<TextTruncate line={1} truncateText="…" text={stripHtml(first_item)} />)
                        }

                    } else {
                        return (<></>)
                    }
                }
            },
            {
                title: 'Trạng thái',
                dataIndex: 'status',
                key: 'status',
                width: '120px',
                render: (text, record) => {
                    // if (checkPermission('cash_receipt', 'update_status')) {
                    //     return (
                    //         <Tooltip title="Click để cập nhật" placement="right">
                    //             <div onClick={() => this.openUpdateStatusForm(record.id)} className='item-action-btn'>
                    //                 {ConvertCashReceiptStatusInList(text)}
                    //             </div>
                    //         </Tooltip>
                    //     )
                    // } else {
                    //     return (
                    //         <div>
                    //             {ConvertCashReceiptStatusInList(text)}
                    //         </div>
                    //     )
                    // }
                    return (
                        <div>
                            {ConvertCashReceiptStatus(text)}
                        </div>
                    )
                }
            },
            {
                title: 'Ghi chú',
                dataIndex: 'note',
                key: 'note',
                width: '200px',
                render: (text, record) => {
                    return (
                        <div style={{ wordBreak: "break-all" }}>
                            <Tooltip title={HtmlParser(text)} placement="topLeft">
                                <TextTruncate line={1} truncateText="…" text={stripHtml(text)} />
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: 'Hình thức thu',
                dataIndex: 'payment_method',
                key: 'payment_method',
                width: '120px',
                render: (text, record) => {
                    return (
                        <>{ConvertPaymentMethod(text)}</>
                    )
                }
            },
            {
                title: 'Loại thanh toán',
                dataIndex: 'payment_type',
                key: 'payment_type',
                width: '120px',
                render: (text, record) => {
                    return (
                        <>{ConvertPaymentType(text)}</>
                    )
                }
            },
            {
                title: 'Ngày tạo',
                dataIndex: 'created_at',
                key: 'created_at',
                width: '150px',
                sorter: true,
                render: (text, record) => {
                    if (text && text !== '') {
                        return (
                            <>{isValidDate(text, true)}</>
                        )
                    } else {
                        return (<Tag></Tag>)
                    }
                }
            },
            {
                title: 'Người tạo',
                dataIndex: 'created_user',
                key: 'created_user',
                width: '200px',
                sorter: true
            }
        ];

        return (
            <div>
                <PageTitle routes={routes} title="Phiếu thu" />
                <div>
                    <TableActionBar
                        isShowAddButton={checkPermission('cash_receipt', 'create')}
                        isShowDeleteButton={checkPermission('cash_receipt', 'remove')}
                        onAdd={() => this.toggleOpenForm(true)}
                        onDelete={() => this.onRemove()}
                        disabled={!selectedRowKeys.length ? true : false}
                        showFilter={true}
                        onSearch={(value) => this.onChangeFilter('keyword', value)}
                        defaultKeyword={query.keyword}
                        placeholderSearch="Mã phiếu, Mã hoặc tên khách hàng"
                        activeFilter={
                            query.created_date_start ||
                            query.created_user_id ||
                            query.payment_method ||
                            query.status ||
                            query.mode
                        }
                        filters={
                            [
                                <BaseSelect
                                    options={PaymentMethod || []}
                                    onChange={(value) => this.onChangeFilter("payment_method", value)}
                                    defaultValue={query.payment_method || null}
                                    placeholder="Hình thức thu"
                                    className="w-100"
                                />,
                                <BaseSelect
                                    options={PaymentType || []}
                                    onChange={(value) => this.onChangeFilter("payment_type", value)}
                                    defaultValue={query.payment_type || null}
                                    placeholder="Loại thanh toán"
                                    className="w-100"
                                />,
                                <BaseSelect
                                    options={CashReceiptStatus || []}
                                    onChange={(value) => this.onChangeFilter("status", value)}
                                    defaultValue={query.status || null}
                                    placeholder="Trạng thái"
                                    className="w-100"
                                />,
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày thu', 'đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('payment_at', value)}
                                    defaultValue={[query.payment_date_start ? moment(query.payment_date_start, 'YYYY-MM-DD') : null, query.payment_date_end ? moment(query.payment_date_end, 'YYYY-MM-DD') : null]}
                                />,
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    placeholder={['Ngày tạo', 'đến ngày']}
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_at', value)}
                                    defaultValue={[query.created_date_start ? moment(query.created_date_start, 'YYYY-MM-DD') : null, query.created_date_end ? moment(query.created_date_end, 'YYYY-MM-DD') : null]}
                                />,
                                <BaseSelect
                                    options={users || []}
                                    optionValue="user_id"
                                    optionLabel="full_name"
                                    showSearch
                                    placeholder="Người tạo"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('created_user_id', value)}
                                    defaultValue={query.created_user_id ? parseInt(query.created_user_id) : null}
                                />,
                                <BaseSelect
                                    options={[
                                        { value: 'manual', label: 'Manual' },
                                        { value: 'import_receipt', label: 'Import receipt' }
                                    ]}
                                    showSearch
                                    placeholder="Nguồn tạo"
                                    className="w-100"
                                    onChange={(value) => this.onChangeFilter('mode', value)}
                                    defaultValue={query.mode ? query.mode : null}
                                />
                            ]
                        }
                    >
                        {
                            checkPermission('cash_receipt', 'import_excel') ? (
                                <Button className="table-button" type="dashed" onClick={() => this.setState({ isOpenFormImport: true })}>Import excel</Button>
                            ) : null
                        }
                        <Button className="table-button" icon={<ReloadOutlined />} loading={isLoading} onClick={() => this.reloadData()}>Refresh</Button>
                        {
                            hasSelected ? <span>Đang chọn {selectedRowKeys.length} bản ghi</span> : ''
                        }

                    </TableActionBar>
                    {
                        importResponseMessage && importResponseType ? (
                            <Alert
                                message={importResponseMessage}
                                type={importResponseType}
                                showIcon
                                className="mb-2 mt-2"
                                closable
                                onClose={() => this.onSetMessage(null, null)}
                            />
                        ) : null
                    }
                </div>
                <Row gutter={16}>
                    <Col xs={24} md={24} className='mb-3'>
                        <Alert
                            description={<><strong>Chú ý: </strong>Sử dụng chức năng in phiếu thu, đưa khách hàng ký, không được viết tay trước rồi mới nhập lên hệ thống</>}
                            type="warning"
                        />
                    </Col>
                </Row>
                <div className='has-custom-column-table-btn'>
                    <DynamicTable
                        rowKey="id"
                        dataSource={cash_receipt_list || []}
                        columns={columns}
                        isLoading={isLoading}
                        pagination={pagination}
                        rowSelection={rowSelection}
                        moduleKey="cash_receipt_list"
                        className='has-table-summary'
                        onChangeTable={(pagination, sorter) => this.onChangeTable(pagination, sorter)}
                        summary={columns => {
                            return (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell key={22222}></Table.Summary.Cell>
                                    {columns.map((col, index) => {
                                        if (col.dataIndex === 'customer_code') {
                                            return (
                                                <Table.Summary.Cell key={index}>Tổng cộng: </Table.Summary.Cell>
                                            );
                                        }
                                        if (col.dataIndex === 'special_discount') {
                                            return (
                                                <Table.Summary.Cell key={index}>
                                                    <NumberFormat value={statistical?.special_discount} displayType={'text'} thousandSeparator={true} />
                                                </Table.Summary.Cell>
                                            );
                                        }
                                        if (col.dataIndex === 'amount_paid') {
                                            return (
                                                <Table.Summary.Cell key={index}>
                                                    <NumberFormat value={statistical?.amount_paid} displayType={'text'} thousandSeparator={true} />
                                                </Table.Summary.Cell>
                                            );
                                        }
                                        return <Table.Summary.Cell key={index}></Table.Summary.Cell>;
                                    })}
                                </Table.Summary.Row>
                            );
                        }}
                    />
                </div>

                <CashReceiptForm
                    visible={isOpenForm}
                    currentItem={currentItem}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.toggleOpenForm(false)}
                />
                <CashReceiptDetail
                    visible={isOpenDetail}
                    currentItem={currentItem}
                    reloadData={() => { this.onDetail(current_id); this.reloadData() }}
                    onCancel={() => this.toggleOpenDetail(false)}
                    loadingDetail={false}
                />
                <CustomerDetail
                    visible={isOpenCustomerDetail}
                    currentItem={currentCustomerData}
                    reloadData={() => this.onCustomerDetail(currentCustomerId)}
                    onCancel={() => this.toggleOpenCustomerDetail(false)}
                    loadingDetail={false}
                />
                <CashReceiptUpdateStatusForm
                    visible={isOpenStatusForm}
                    currentItem={currentItem}
                    reloadData={() => this.reloadData()}
                    onCancel={() => this.setState({ isOpenStatusForm: false })}
                />
                <ImportExcelForm
                    visible={isOpenFormImport}
                    onCancel={() => this.setState({ isOpenFormImport: false })}
                    onSetMessage={this.onSetMessage}
                    reloadData={() => this.reloadData()}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        cash_receipt_list: state.CashReceiptReducer.cash_receipt_list,
        statistical: state.CashReceiptReducer.statistical,
        pagination: state.CashReceiptReducer.pagination,
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getListCashReceipt: (filter) => dispatch(getListCashReceipt(filter)),
        getSpecificCashReceipt: (id) => dispatch(getSpecificCashReceipt(id)),
        removeCashReceipt: (ids) => dispatch(removeCashReceipt(ids)),
        getSpecificCustomer: (id) => dispatch(getSpecificCustomer(id)),
        getStatistical: (filter) => dispatch(getStatistical(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'CashReceiptList' })(CashReceiptList));

