import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Input, Button, Popconfirm, DatePicker, Select, Space, Card, Row, Col, Form, Empty, Drawer } from "antd";
import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import { isMobile } from 'react-device-detect';

const { Option } = Select;

const Consultant = (props) => {

    const { config, currentConsultant, getConsultant, visible, onCancel, currentDate } = props;

    const consultant_date = currentDate ? moment(currentDate, 'YYYY-MM-DD HH:mm') : moment();

    const [items, setItems] = useState([{
        key: 0,
        user_id: '',
        consultant_date: consultant_date,
        note: ""
    }]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (currentConsultant && currentConsultant.length > 0) {
            const initialItems = currentConsultant.map((item, index) => ({
                key: index + 1,
                user_id: item.user_id,
                consultant_date: item.consultant_date ? moment(item.consultant_date, 'YYYY-MM-DD HH:mm') : null,
                note: item.note
            }));
            setItems(initialItems);
            setCount(initialItems.length);
        } else {
            setItems([{
                key: 0,
                user_id: '',
                consultant_date: consultant_date,
                note: ""
            }]);
            setCount(0);
        }
    }, [currentConsultant]);

    const onAdd = () => {
        const newIndex = count + 1;
        const newItem = {
            key: newIndex,
            user_id: '',
            consultant_date: consultant_date,
            note: ""
        };
        setItems([...items, newItem]);
        setCount(newIndex);
    };

    const onDelete = (key) => {
        const updatedItems = items.filter(item => item.key !== key);
        setItems(updatedItems);
    };

    const onChangeItem = (name, value, itemKey) => {
        const updatedItems = items.map(item =>
            item.key === itemKey ? { ...item, [name]: value } : item
        );
        setItems(updatedItems);
    };

    const handleSave = (e) => {
        e.preventDefault();
        const allHaveValidUserId = items.every(item => item.user_id != null && item.user_id !== "");
        if (allHaveValidUserId) {
            getConsultant(items);
            onCancel();
        } else {
            NotificationManager.error("Vui lòng chọn nhân viên cho tất cả các mục trước khi cập nhật.");
        }
    }

    const onClose = () => {
        onCancel();
    }

    return (
        <>
            <Drawer
                visible={visible}
                title="Cập nhật nhân viên tư vấn"
                onOk={(e) => handleSave(e)}
                onClose={() => onClose()}
                maskClosable={false}
                width={isMobile ? '100%' : '40%'}
                bodyStyle={{ padding: '10px 20px' }}
                footer={
                    <div style={{ textAlign: 'right', }}>
                        <Space>
                            <Button key="back" onClick={() => onClose()}>
                                Huỷ
                            </Button>
                            <Button
                                key="submit"
                                type="primary"
                                onClick={(e) => handleSave(e)}
                            >
                                Lưu
                            </Button>
                        </Space>
                    </div>
                }
            >
                {visible ?
                    <div>
                        {items.length > 0 ? (
                            <div>
                                {items.map((item, index) => (
                                    <Card key={item.key}
                                        size='small'
                                        className="mb-2 service-card-box"
                                        bordered={false}
                                        bodyStyle={{ padding: 0 }}
                                        headStyle={{ padding: 0 }}
                                        title={`Nhân viên: ${index + 1}`}
                                        extra={<>
                                            <Popconfirm
                                                title="Xóa nhân viên này?"
                                                onConfirm={() => onDelete(item.key)}
                                                placement="topLeft"
                                            >
                                                <DeleteOutlined className='delete-icon' />
                                            </Popconfirm>
                                        </>}
                                    >
                                        <Row gutter={16}>
                                            <Col xs={24} md={12}>
                                                <Form.Item>
                                                    <Select
                                                        style={{ width: '100%' }}
                                                        placeholder="Chọn nhân viên"
                                                        optionLabelProp="label"
                                                        allowClear={true}
                                                        showSearch={true}
                                                        value={item.user_id || null}
                                                        onChange={(value) => onChangeItem('user_id', value, item.key)}
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().includes(input.toLowerCase())
                                                        }
                                                    >
                                                        {config?.users?.map((item) => (
                                                            <Option key={item.user_id} value={item.user_id} label={item.full_name}>
                                                                {item.full_name}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item>
                                                    <DatePicker
                                                        value={item.consultant_date || moment()}
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => onChangeItem("consultant_date", value, item.key)}
                                                        showTime={{ format: 'HH:mm' }}
                                                        format="DD/MM/YYYY HH:mm"
                                                        placeholder='Chọn thời điểm tư vấn'
                                                        allowClear={false}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={24}>
                                                <Form.Item style={{ marginBottom: 0 }}>
                                                    <Input.TextArea
                                                        value={item.note}
                                                        style={{ minHeight: 30, marginBottom: 0 }}
                                                        onChange={(e) => onChangeItem("note", e.target.value, item.key)}
                                                        placeholder='Kết quả tư vấn'
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                ))}
                            </div>
                        ) : (
                            <div style={{ textAlign: 'center' }}>
                                <Empty imageStyle={{ height: 30 }} style={{ margin: '50px 0' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        )}
                        <Button type="primary" size='default' className='w-100' onClick={onAdd} icon={<UserAddOutlined />}>
                            Thêm nhân viên tư vấn
                        </Button>
                    </div>
                    : null}
            </Drawer>
        </>
    );
};

const mapStateToProps = (state) => ({
    config: state.config
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Consultant);
