import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import { Button, DatePicker, Row, Col, Drawer, Space } from 'antd';
import { Form } from '@ant-design/compatible';
import { isValidDateForm } from '../../../utils/helpers';
import { isMobile } from 'react-device-detect';
import BaseSelect from '../../../components/Elements/BaseSelect';

// actions
import { updateCustomerTelesale } from '../../../redux/actions/CustomerTelesaleActions';

class TelesaleForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    async handleUpdate(e) {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true })
                try {
                    const { currentItem } = this.props;
                    if (currentItem) {
                        values.deadline = values.deadline ? values.deadline.format("YYYY-MM-DD HH:mm") : null
                        await this.props.updateCustomerTelesale(currentItem.id, values);
                        this.onCancel()
                    }
                } catch (error) {
                    this.setState({ isLoading: false });
                }
                //console.log(values)
            }
        });
    }

    onCancel() {
        this.props.reloadData()
        this.props.form.resetFields();
        this.props.onCancel();
        this.setState({ isLoading: false })
    }

    render() {
        var { currentItem, visible, users } = this.props;
        var { isLoading } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <Drawer
                    title="Cập nhật lịch telesale"
                    visible={visible}
                    header={false}
                    width={isMobile ? '100%' : '40%'}
                    onClose={() => this.onCancel()}
                    maskClosable={false}
                    footer={
                        <div style={{ textAlign: 'right', }}>
                            <Space>
                                <Button key="back" onClick={() => this.onCancel()}>
                                    Huỷ
                                </Button>
                                <Button
                                    loading={isLoading}
                                    key="submit"
                                    type="primary"
                                    onClick={(e) => this.handleUpdate(e)}
                                >
                                    Cập nhật
                                </Button>
                            </Space>
                        </div>
                    }
                >
                    <Form layout='vertical'>
                        <Row gutter={16}>
                            <Col xs={24} md={24} className='mb-3'>
                                <h6><UserOutlined /> <span className='color-primary'>{`${currentItem?.customer_name} - ${currentItem?.customer_code}`}</span></h6>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col xs={24} md={24}>
                                <Form.Item label="Nhân viên telesale">
                                    {getFieldDecorator("user_id", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? currentItem?.user_id : null
                                    })(
                                        <BaseSelect
                                            options={users ?? []}
                                            optionValue="user_id"
                                            optionLabel="full_name"
                                            placeholder="Chọn nhân viên telesale"
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item label="Deadline">
                                    {getFieldDecorator("deadline", {
                                        rules: [
                                            {
                                                required: true,
                                                message: "Vui lòng chọn!",
                                            },
                                        ],
                                        initialValue: currentItem ? isValidDateForm(currentItem.deadline, true) : null
                                    })(
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            showTime={{ format: 'HH:mm' }}
                                            format="DD/MM/YYYY HH:mm"
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        users: state.config.users
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateCustomerTelesale: (id, data) => dispatch(updateCustomerTelesale(id, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create({ name: 'TelesaleForm' })(TelesaleForm));

